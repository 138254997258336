import React from "react";

import { TableFooter as MaterialTableFooter } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import useTranslations from "hooks/useTranslations";

interface ICustomTableFooterProps {
  count: number;
  rowsPerPage: number;
  currentPage: number;
  onChangePage: any;
  onChangeRowsPerPage: any;
}

const CustomTableFooter: React.FunctionComponent<ICustomTableFooterProps> = ({
  count,
  rowsPerPage,
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const lang = useTranslations();
  return (
    <MaterialTableFooter>
      <TableRow>
        <TablePagination
          labelRowsPerPage={lang.components.table.rowsPerPage}
          count={count}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </TableRow>
    </MaterialTableFooter>
  );
};

export default CustomTableFooter;
