import {
  errorColor,
  infoColor,
  lightgray,
  red,
  warningColor,
  yellow,
} from "../../assets/colors";

export default (theme: any) => ({
  MenuItemHelpAndIdButton: {
    marginTop: "0",
    marginBottom: "0",
    "&:hover": {
      backgroundColor: "#ffffff !important",
      color: "#124e5d",
    },
  },
  ErrorOutlineIcon: {
    fontSize: "small",
    margin: "5px",
  },
  BackButton: {
    float: "left",
  },
  ButtonContainer: {
    zIndex: "1000",
  },
  ActionFab: {
    margin: "5px",
    zIndex: "1000",
  },
  ButtonContainerNoPadding: {
    zIndex: "1000",
  },
  MoreButton: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
    marginLeft: "5px",
    marginRight: "5px",
    zIndex: "1000",
  },
  LoginButton: {
    float: "left",
    height: "36px",
    width: "122px",
    "box-shadow": "none",
    border: "0px solid !important",
    "&:hover": {
      background: theme.palette.secondary.main,
      opacity: ".5",
    },
    borderRadius: "4px",
    background: theme.palette.secondary.main,
  },
  CreateAndDownloadButton: {
    whiteSpace: "nowrap",
    float: "right",
    height: "40px",
    width: "auto",
    display: "flex",
    alignItems: "center",
    "box-shadow": "none",
    "&:hover": {
      background: "#DDD",
    },
    background: "transparent",
  },
  LoginButtonLabel: {
    width: "100%",
    height: "100%",
    fontsize: "16px",
    fontWeight: "500",
    lineHeight: "1.25",
    letterSpacing: "-0.25",
    color: "#fefefe",
  },
  TableActionButton: {
    float: "right",
    height: "40px",
    width: "40px",
    "box-shadow": "none",
    border: "0px solid !important",
    "&:hover": {
      border: "1px solid transparent",
      background: "transparent",
    },
    background: "transparent",
    transform: "scale(0.8)",
  },
  TableDetailActionButton: {
    margin: "4px 8px",
    maxHeight: 28,
  },
  TableStopActionButton: {
    margin: "4px 8px",
    maxHeight: 28,
    backgroundColor: errorColor,
    color: "white",
    "&:hover": {
      background: errorColor,
      opacity: 0.9,
    },
  },
  HeaderActionButton: {
    color: theme.palette.primary.main,
  },
  SUCCESSColor: {
    // color: successColor,
    color: theme.palette.primary.main,
  },
  LICENSEColor: {
    color: theme.palette.primary.main,
  },
  REPLICATEColor: {
    color: lightgray,
  },
  LOGINColor: {
    color: yellow,
  },
  WARNINGColor: {
    color: warningColor,
  },
  INFOColor: {
    color: infoColor,
  },
  ERRORColor: {
    color: errorColor,
  },
  SECONDARYColor: {
    color: theme.palette.secondary.main,
    background: "#0000",
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
  },
  DarkButton: {
    color: "white",
    background: "#0000",
    "&:hover": {
      background: "white",
      color: theme.palette.primary.main,
    },
  },
  NewLoginButton: {
    height: "36px",
    width: "100%",
    "box-shadow": "none",
    border: "0px solid !important",
    "&:hover": {
      background: theme.palette.secondary.main,
      opacity: ".5",
    },
    borderRadius: "4px",
    background: theme.palette.secondary.main,
    padding: "20px 184.8px 20px 184px",
  },
  ShowArchivedQuestionsAttributesButton: {
    float: "right",
    //color: "white",
    height: "40px",
    width: "auto",
    "box-shadow": "none",
    "&:hover": {
      background: "transparent",
      fontSize: "16px",
    },
    "&:focus": {
      outline: "none",
    },
    background: "transparent",
    transform: "scale(0.8)",
  },
  customErrorButton: {
    background: red,
    color: "white",
  },
  customWarningButton: {
    background: warningColor,
    color: "white",
  },
});
