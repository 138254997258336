import { Background, Black, Green, GreyDark } from "assets/colors";
import { IStyles } from "model/application/styles";

const styles: IStyles = {
  container: {
    display: "grid",
    minHeight: "176px",
    gridTemplateAreas: `
        "icon title"
        "line body"
        `,
    gridTemplateColumns: "auto 1fr",
    gridTemplateRows: "auto 1fr",
    columnGap: "10px",
    "&:first-of-type > $sideBarIconContainer": {
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      alignItems: "start",
      paddingTop: "8px",
    },
    "&:last-of-type > $sideBarIconContainer": {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      maxHeight: "60px",
      alignItems: "end",
      paddingBottom: "8px",
    },
  },
  cardbody: {
    boxShadow: "0px 4px 6px rgba(18, 78, 93, 0.15)",
    borderRadius: "4px",
    background: "white",
    padding: "12px",
    marginBottom: "16px",
  },
  titleSection: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    paddingBottom: "8px",
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: Black,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  launchIcon: {
    fontSize: "15px !important",
    transition: "transform 0.3s",
    cursor: "pointer",
    "&:hover": {
      transform: "translate(1px,-1px)",
    },
  },
  timeline: {
    padding: "0px",
    margin: "0px",
    "& $timelineItem:last-of-type": {
      maxHeight: "auto",
      minHeight: "unset",
    },
    gridArea: "body",
  },
  time: {
    fontSize: "12px",
    color: GreyDark,
    flex: "unset",
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "0px",
    width: "75px",
  },
  action: {
    fontSize: "16px",
    color: Black,
    flex: 1,
    fontWeight: "500",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  timelineItem: {
    minHeight: "44px",
  },

  separator: {
    maxWidth: "12px",
    color: GreyDark,
  },
  icon: {
    fontSize: "19px !important",
    color: GreyDark,
    position: "relative",
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    position: "relative",
  },
  sideBarIcon: {
    fontSize: "16px !important",
    color: Green,
  },
  sideBarIconContainer: {
    display: "flex",
    background: Background,
    justifyContent: "center",
    paddingTop: "16px",
  },
  line: {
    height: "100%",
    width: "20px",
    background: Background,
  },
};

export default styles;
