import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ProtectedComponent from "components/Route/ProtectedComponent";
import { CALENDAR_ROUTE } from "model/constants/routes";

import CalendarContainer from "./CalendarContainer";
import ListCalendar from "./components/CalendarEventsListView/ListCalendar";
import { getIsUserAManager } from "./redux/selectors";

function CalendarViewHandler() {
  const isManager = useSelector(getIsUserAManager);

  return (
    <Router>
      <Switch>
        <Route exact path="/calendar">
          <CalendarContainer />
        </Route>
        <Route path="/calendar/list">
          <ProtectedComponent
            redirectPath={CALENDAR_ROUTE}
            authenticationFunction={() => {
              return isManager;
            }}
          >
            <ListCalendar />
          </ProtectedComponent>
        </Route>
      </Switch>
    </Router>
  );
}

export default CalendarViewHandler;
