import { useContext, useState } from "react";

import { Box, FormGroup, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useDispatch } from "react-redux";

import { WarningModalContext } from "containers/app/AppProviders";
import { addItemsToListActionCreator } from "containers/lists/redux/actionCreators";
import { useTranslations } from "hooks";
import { formatString } from "lang/utils";
import { IOption } from "model/application/components";
import { IListItem } from "model/entities/ListItem";

import BulkImportMenu from "../Base/BulkImportMenu";
import EmptyOptionsView from "../Base/EmptyOptionsView";
import OptionsView from "../Base/OptionsView";
import InputBaseLayout, {
  IInputBaseLayout,
} from "../InputBaseLayout/InputBaseLayout";
import {
  IInputMultipleSelectPropsBase,
  TickBox,
} from "../InputMultipleSelect/InputMultipleSelect";
import styles from "../styles";
import InputMultipleSelectOnListBase from "./InputMultipleSelectOnListBase/InputMultipleSelectOnListBase";

const useStyles = makeStyles(styles as any);
export interface IInputMultipleSelectOnListProps
  extends IInputBaseLayout,
    Omit<IInputMultipleSelectPropsBase, keyof IInputBaseLayout | "lang"> {
  listId: string;
}

function InputMultipleSelectOnList({
  viewMode,
  disabled,
  options,
  langlabel,
  error,
  viewStacked,
  labelIcons,
  defaultValue,
  highlightContent,
  noOptionsText,
  multipleSelection,
  name,
  onChange,
  limit,
  onBulkImport,
  bulkModalConfirmBtnText,
  bulkModalDialogTitle,
  BulkModal,
  onDownload,
  showDownloadButton,
  csvHeaderLine,
  listId,
}: Readonly<IInputMultipleSelectOnListProps>) {
  const classes = useStyles();
  const { title, tooltip } = langlabel;
  const lang = useTranslations();
  const warningModal = useContext(WarningModalContext);
  const dispatch = useDispatch();

  const [tempSelectedOptions, setTempSelectedOptions] = useState<IOption[]>(
    defaultValue || []
  );
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>(
    defaultValue || []
  );

  function saveOptionsAsPartialItems(options: Array<IOption>) {
    //Add the option to the items store if missing
    //Some dialogs may rerender the component and the options are lost since they are not stored in the store
    const optionsAsPartialItems: Array<IListItem> = _.map(
      options,
      (opt): IListItem => {
        return {
          _id: opt.key,
          _active: true,
          _name: opt.label,
        };
      }
    );
    dispatch(
      addItemsToListActionCreator({
        items: optionsAsPartialItems,
        listId: listId,
        type: "_",
      })
    );
  }

  if (disabled || viewMode === "VIEW") {
    return (
      <OptionsView
        key={JSON.stringify(options)}
        label={title}
        tooltip={tooltip}
        viewMode={viewMode}
        error={error}
        viewStacked={viewStacked}
        labelIcons={labelIcons}
        disabled={disabled}
        options={defaultValue || []}
        highlightContent={highlightContent}
      />
    );
  }

  if (_.isEmpty(options)) {
    return (
      <EmptyOptionsView
        options={options}
        tooltip={tooltip}
        label={title}
        disabled={disabled}
        noOptionsText={noOptionsText}
      />
    );
  }

  function handleAddOptions(options: Array<IOption>) {
    let newOptions: Array<IOption>;
    if (!multipleSelection) {
      newOptions = _.compact([_.first(options)]);
    } else {
      newOptions = [...options, ...tempSelectedOptions];
    }
    if (_.isNumber(limit) && _.size(newOptions) > limit) {
      const limitModal = lang.components.inputMultipleSelect.limitWarningModal;
      warningModal.openWarningModal({
        title: limitModal?.title ?? "Warning",
        description: formatString(limitModal?.description, [limit]),
      });
      return;
    }
    const optionsToSet = _.uniqBy(newOptions, "key");
    setTempSelectedOptions(optionsToSet);
    return optionsToSet;
  }
  function handleRemoveOptions(options: Array<IOption>) {
    const newSelectedOptions = _.filter(
      tempSelectedOptions,
      (selectedOption) => {
        return !_.find(options, (option) => option.key === selectedOption.key);
      }
    );
    setTempSelectedOptions(newSelectedOptions);
    return newSelectedOptions;
  }

  return (
    <>
      {_.size(options) > 6 && (
        <Box>
          <InputMultipleSelectOnListBase
            listId={listId}
            multipleSelection={multipleSelection}
            options={options}
            langlabel={langlabel}
            selectedOptions={tempSelectedOptions}
            viewMode={viewMode}
            onAddOptions={handleAddOptions}
            onRemoveOptions={handleRemoveOptions}
            onSave={(options) => {
              const newOptions = options ?? tempSelectedOptions;
              setSelectedOptions(newOptions);
              saveOptionsAsPartialItems(newOptions);
              if (onChange) {
                onChange(newOptions, name);
              }
            }}
            onCancel={() => {
              setTempSelectedOptions(selectedOptions);
            }}
            onClose={() => {
              setTempSelectedOptions(selectedOptions);
            }}
            defaultValue={defaultValue}
          />
        </Box>
      )}
      {_.size(options) <= 6 && (
        <InputBaseLayout
          key={JSON.stringify(options)}
          label={langlabel?.title}
          tooltip={langlabel?.tooltip}
          viewMode={viewMode}
          error={error}
          disabled={disabled}
        >
          <div
            className={
              viewMode === "EDIT"
                ? classes.ChipContainerEditMode
                : classes.ChipContainer
            }
          >
            <Box className={classes.formControlContent}>
              <FormGroup>
                {options.map((option) => {
                  const checked =
                    _.find(tempSelectedOptions, (selectedOption) => {
                      return _.isEqual(selectedOption.key, option.key);
                    }) !== undefined;
                  return (
                    <TickBox
                      checked={checked}
                      dataTestId={`radio-${option.key}`}
                      tooltip={option.tooltip}
                      disabled={option.disabled}
                      key={option.key}
                      option={option}
                      multipleSelection={multipleSelection}
                      onClick={() => {
                        const result = checked
                          ? handleRemoveOptions([option])
                          : handleAddOptions([option]);
                        onChange && onChange(_.compact(result), name);
                      }}
                    />
                  );
                })}
              </FormGroup>
            </Box>
          </div>
        </InputBaseLayout>
      )}

      <BulkImportMenu
        langlabel={langlabel}
        BulkModal={BulkModal}
        bulkModalConfirmBtnText={bulkModalConfirmBtnText}
        bulkModalDialogTitle={bulkModalDialogTitle}
        csvHeaderLine={csvHeaderLine}
        onBulkImport={onBulkImport}
        onDownload={onDownload}
        showDownloadButton={showDownloadButton}
      />
    </>
  );
}

export default InputMultipleSelectOnList;
