import {
  CalendarEventStatus,
  EventChangeScope,
  IVisitEvent,
  TCalendarEvent,
} from "fieldpro-tools";
import _ from "lodash";

import { getLang } from "containers/authentication/redux/selector";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import TLang from "model/application/Lang";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import * as lang from "../../../lang";
import * as notifyLevels from "../../notifications/actionLevels";
import * as notifyTypes from "../../notifications/actionTypes";
import {
  createEventsBeginAction,
  createEventsFailureAction,
  createEventsSuccessAction,
  deleteEventsBeginAction,
  deleteEventsFailureAction,
  deleteEventsSuccessAction,
  fetchEventsBeginAction,
  fetchEventsFailureAction,
  fetchEventsSuccessAction,
  updateEventsBeginAction,
  updateEventsFailureAction,
  updateEventsSuccessAction,
} from "./actionCreators";
import {
  createEventsApiCall,
  deleteEventsApiCall,
  fetchEventsApiCall,
  TParamfetchEvents,
  updateEventsApiCall,
} from "./api";
import { TNewEventPayload } from "./types";

function getLangKey(lang: TLang) {
  return lang.containers.calendar.subCategories.calendar.createEditModal
    .inputEvent.customMessage;
}
export const deleteEventsActionFunction = (
  ids: string[],
  scope?: EventChangeScope
): IDispatchAndGetState<string[] | undefined> => {
  return (dispatch, getState) => {
    const plural = _.size(ids) > 1;
    const currLang = lang[getLang(getState())];
    const langKey = getLangKey(currLang);
    dispatch(deleteEventsBeginAction());
    return deleteEventsApiCall(ids, scope)
      .then((response) => {
        const data = extractDataAndCheckErrorStatus(response) as {
          deleted_ids: string[];
        };
        dispatch(ajaxSuccessAction());
        dispatch(deleteEventsSuccessAction(data["deleted_ids"]));

        dispatch(
          showNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            plural
              ? langKey.eventsSuccesfullyDeleted
              : langKey.eventSuccesfullyDeleted
          )
        );
        return ids;
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "deleteEventsError",
          error,
          deleteEventsFailureAction,
          currLang
        );
        return undefined;
      });
  };
};

export const updateEventsActionFunction = (
  visits: IVisitEvent[],
  scope?: EventChangeScope,
  notification?: boolean
): IDispatchAndGetState<TCalendarEvent[] | undefined> => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const plural = _.size(visits) > 1;
    const langKey = getLangKey(currLang);
    dispatch(updateEventsBeginAction());

    return updateEventsApiCall(visits, scope)
      .then((response) => {
        const data = extractDataAndCheckErrorStatus(response);
        dispatch(ajaxSuccessAction());
        if (data.success && notification)
          dispatch(
            showNotificationActionCreator(
              notifyTypes.NOTIFICATION_SUCCESS,
              notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
              plural
                ? langKey.eventsSuccesfullyUpdated
                : langKey.eventSuccesfullyUpdated
            )
          );
        dispatch(updateEventsSuccessAction(data.events, data.deleted_ids));
        return data.events;
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "updateEventsError",
          error,
          updateEventsFailureAction,
          currLang
        );
        return undefined;
      });
  };
};

interface ICreateEventsActionFunctionOptions {
  runInBackground?: boolean;
}
export const createEventsActionFunction = (
  newVisits: TNewEventPayload[],
  options?: ICreateEventsActionFunctionOptions
): IDispatchAndGetState<TCalendarEvent[] | undefined> => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const plural = _.size(newVisits) > 1;
    const langKey = getLangKey(currLang);
    dispatch(createEventsBeginAction());

    return createEventsApiCall(newVisits, options)
      .then((response) => {
        const data = extractDataAndCheckErrorStatus(response);
        dispatch(ajaxSuccessAction());
        dispatch(createEventsSuccessAction(data.events));
        if (data.success)
          dispatch(
            showNotificationActionCreator(
              notifyTypes.NOTIFICATION_SUCCESS,
              notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
              plural
                ? langKey.eventsSuccesfullyCreated
                : langKey.eventSuccesfullyCreated
            )
          );
        return data.events;
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "createEventsError",
          error,
          createEventsFailureAction,
          currLang
        );
        return undefined;
      });
  };
};

export const fetchEventsActionFunction = (
  params: TParamfetchEvents
): IDispatchAndGetState<TCalendarEvent[] | undefined> => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchEventsBeginAction());

    return fetchEventsApiCall(params)
      .then((response) => {
        const data = extractDataAndCheckErrorStatus(response);
        dispatch(ajaxSuccessAction());
        dispatch(fetchEventsSuccessAction(data.events));
        return data.events;
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "fetchEventsError",
          error,
          fetchEventsFailureAction,
          currLang
        );
        return undefined;
      });
  };
};

/**
 *
 * @param event the event to approve or decline
 * @param approve if true, the event will be approved, otherwise it will be declined, default is true
 * @returns
 */
export const approveOrDeclineEventsActionFunction = (
  event: TCalendarEvent,
  approve: boolean = true
): IDispatchAndGetState<TCalendarEvent[] | undefined> => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const langKey = getLangKey(currLang);
    dispatch(updateEventsBeginAction());

    event.status = approve
      ? CalendarEventStatus.PLANNED
      : CalendarEventStatus.DECLINED;

    return updateEventsApiCall([event])
      .then((response) => {
        const data = extractDataAndCheckErrorStatus(response);
        dispatch(ajaxSuccessAction());
        if (data.success)
          dispatch(
            showNotificationActionCreator(
              notifyTypes.NOTIFICATION_SUCCESS,
              notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
              approve
                ? langKey.eventsSuccesfullyApproved
                : langKey.eventsSuccesfullyDeclined
            )
          );
        dispatch(updateEventsSuccessAction(data.events, data.deleted_ids));
        return data.events;
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "approveOrDeclineEventsActionFunction",
          error,
          updateEventsFailureAction,
          currLang
        );
        return undefined;
      });
  };
};
