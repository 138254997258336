import { IStream as IBEStream } from "fieldpro-tools";

import { IStream } from "model/entities/Job";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

// STREAMS

export interface ICreateStreamSuccessAction extends IAction {
  stream: IStream;
}
export interface IUpdateStreamSuccessAction extends IAction {
  stream: IStream;
}
export interface IRunStreamJobSuccessAction extends IAction {
  success: boolean;
}
export interface IDeleteStreamSuccessAction extends IAction {
  id: string;
}
export interface IFetchStreamsSuccessAction extends IAction {
  streams: IBEStream[];
  availableActions: any[]; // FIXME replace "any" with a more precise type
}
export interface IUseStreamSuccessAction extends IAction {
  id: string;
}

/**
 * Create Stream begin action creator
 * This creates an action informing redux store that the creation process for a stream has just began
 * @returns {Object}
 */
export function createStreamBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_STREAM_BEGIN,
  };
}

/**
 * Failure Action for creating a stream instructing that the creation process for a Stream has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function createStreamFailureActionCreator(error: any): IActionError {
  return {
    type: types.CREATE_STREAM_FAILURE,
    error,
  };
}

/**
 * Success action for creating a stream
 * @param {Object} stream stream object,
 * @returns {Object}
 */
export const createStreamSuccessActionCreator = (
  stream: IStream
): ICreateStreamSuccessAction => {
  return {
    type: types.CREATE_STREAM_SUCCESS,
    stream,
  };
};

/**
 * Edit stream begin action creator
 * This creates an action informing redux store that the creation process for a stream has just began
 * @returns {Object}
 */
export function updateStreamBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_STREAM_BEGIN,
  };
}

/**
 * Failure Action for editing a stream instructing that the edition process for a stream has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateStreamFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_STREAM_FAILURE,
    error,
  };
}

/**
 * Success action for editing a stream
 * @param {Object} stream stream object,
 * @returns {Object}
 */
export function updateStreamSuccessActionCreator(
  stream: IStream
): IUpdateStreamSuccessAction {
  return {
    type: types.UPDATE_STREAM_SUCCESS,
    stream,
  };
}

/**
 * Delete stream begin action creator
 * This creates an action informing redux store that the creation process for a stream has just began
 * @returns {Object}
 */
export function deleteStreamBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_STREAM_BEGIN,
  };
}

/**
 * Failure Action for creating a stream instructing that the creation process for a stream has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteStreamFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_STREAM_FAILURE,
    error,
  };
}

/**
 * Success action for creating a stream
 * @param {Object} streamId stream object,
 * @returns {Object}
 */
export function deleteStreamSuccessActionCreator(
  streamId: string
): IDeleteStreamSuccessAction {
  return {
    type: types.DELETE_STREAM_SUCCESS,
    id: streamId,
  };
}

/**
 * Use stream begin action creator
 * This creates an action informing redux store that the usage process for a stream has just began
 * @returns {Object}
 */
export function runStreamBeginActionCreator(): IBeginAction {
  return {
    type: types.USE_STREAM_BEGIN,
  };
}

/**
 * Failure Action for creating a stream instructing that the usage process for a stream has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function runStreamFailureActionCreator(error: any): IActionError {
  return {
    type: types.USE_STREAM_FAILURE,
    error,
  };
}

/**
 * Success action for usage a stream
 * @param {Object} streamId stream object,
 * @returns {Object}
 */
export function runStreamSuccessActionCreator(
  streamId: string
): IUseStreamSuccessAction {
  return {
    type: types.USE_STREAM_SUCCESS,
    id: streamId,
  };
}

/**
 * begin action when there is a request to fetch all streams
 * @returns {Object}
 * */
export const fetchAllStreamsBeginActionCreator = (): IBeginAction => ({
  type: types.FETCH_ALL_STREAMS_BEGIN,
});

/**
 * Failure action for fetching all streams. This action is dispatched when there is an error fetching all
 * streams
 * @param {Object} error
 * @returns {Object}
 * */
export const fetchAllStreamsFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.FETCH_ALL_STREAMS_FAILURE,
  error,
});

/**
 * Fetch all Streams action creator
 * @param {Array} streams an array of stream objects
 * @returns {Object}
 * */
export const fetchAllStreamsSuccessActionCreator = (
  streams: IBEStream[],
  availableActions: any[]
): IFetchStreamsSuccessAction => ({
  type: types.FETCH_ALL_STREAMS_SUCCESS,
  streams,
  availableActions,
});

/**
 * Run stream job begin action creator
 * This creates an action informing redux store that the running process for a stream job has just began
 * @returns {Object}
 */
export function runStreamJobBeginActionCreator(): IBeginAction {
  return {
    type: types.RUN_STREAM_JOB_BEGIN,
  };
}

/**
 * Failure Action for running a stream job instructing that the running process for a stream job has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function runStreamJobFailureActionCreator(error: any): IActionError {
  return {
    type: types.RUN_STREAM_JOB_FAILURE,
    error,
  };
}

/**
 * Success action for running a stream job
 * @param {Object} stream Notification object,
 * @returns {Object}
 */
export const runStreamJobSuccessActionCreator = (
  success: boolean
): IRunStreamJobSuccessAction => {
  return {
    type: types.RUN_STREAM_JOB_SUCCESS,
    success,
  };
};
