import {
  CUSTOM_FIELD_TYPE,
  IActivity,
  IActivityReportInTable,
  IQuestion,
} from "model/entities/Workflow";

export interface IQuestionAnswerMap {
  question: IQuestion;
  answer: any;
}
export interface IQuestionPictureGroup {
  tag: string;
  url: string;
  id?: string;
}
export const mapQuestionsToReportAnswers = (
  activity: IActivity,
  report: IActivityReportInTable
) => {
  const reportAnswerMap: IQuestionAnswerMap[] = [];

  activity.questionnaire.questions.forEach((question) => {
    if (report[question.tag]) {
      const answer = report[question.tag];
      reportAnswerMap.push({
        question: question,
        answer: answer,
      });
    }
  });
  return reportAnswerMap;
};

export const groupQuestionsWithPictures = (
  questionAnswerMap: IQuestionAnswerMap[]
) => {
  const questionPictureGroup: IQuestionPictureGroup[] = [];
  questionAnswerMap.forEach((qa) => {
    switch (qa.question.type) {
      case CUSTOM_FIELD_TYPE.PICTURE:
        questionPictureGroup.push({
          tag: qa.question.question_text,
          url: qa.answer.url,
          id: qa.answer.upload_id,
        });
        return;
      case CUSTOM_FIELD_TYPE.MATRIX_ON_LIST:
        qa.answer.rows?.forEach((row: any) => {
          if (row && row.matrix_picture) {
            questionPictureGroup.push({
              tag: qa.question.question_text,
              url: row.matrix_picture.url,
              id: row.matrix_picture.upload_id,
            });
          }
        });

        return;
      default:
        return;
    }
  });
  return questionPictureGroup;
};
