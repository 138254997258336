import { Box, makeStyles, Tooltip } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import _ from "lodash";
import moment from "moment";

import MenuButton from "components/Buttons/MenuButton";
import PrimaryfiltersDropdownManager, {
  IPrimaryfiltersDropdownManagerProps,
} from "components/Filter/PrimaryFiltersDropdownManager";
import { useCalendarRange, useTranslations } from "hooks";

import CalendarDateRange from "./components/Calendar/CalendarDateRange";
import { styles } from "./styles";
import CalendarViewToggleButton from "./utils/CalendarViewToggleButton";
import { getAddVisitToolTip } from "./utils/getAddVisitToolTip";
const useStyles = makeStyles(styles as any);
export interface ICalendarContainerHeaderProps
  extends Pick<
    IPrimaryfiltersDropdownManagerProps,
    "onChangeFilters" | "filters"
  > {
  isManager?: boolean;
  currentFieldUser?: any;
  onChangeDateRange: (dateRange: any) => void;
  onClickAddSingleVisit: () => void;
  onClickAddMultipleVisits: () => void;
}
export const CalendarContainerHeader: React.FC<
  ICalendarContainerHeaderProps
> = ({
  currentFieldUser,
  isManager,
  onChangeDateRange,
  onClickAddMultipleVisits,
  onClickAddSingleVisit,
  filters,
  onChangeFilters,
}) => {
  const classes = useStyles();
  const lang = useTranslations();
  const langKey = lang.containers.calendar.subCategories.calendar;
  const inputVisitLang = langKey.createEditModal.inputVisit;
  const {
    calendarRange: { date, mode },
  } = useCalendarRange();
  const formattedDate = moment.utc(date);
  const menuButtonOptions = [
    {
      label: inputVisitLang.customMessage.singleVisits,
      onClick: onClickAddSingleVisit,
      icon: <Add className={classes.icon} />,
    },
    {
      label: inputVisitLang.customMessage.multipleVisits,
      onClick: onClickAddMultipleVisits,
      icon: <AddToPhotosIcon className={classes.icon} />,
    },
  ];

  return (
    <Box className={classes.header}>
      <Box className={classes.title}>
        <Box>{inputVisitLang.customMessage.schedule}</Box>
        <Box>-</Box>
        <Box fontWeight={"600"}>
          {mode === "day"
            ? formattedDate.format("MMMM D YYYY")
            : formattedDate.format("MMMM")}{" "}
        </Box>
      </Box>
      <Box display={"flex"} alignContent={"center"} gridColumnGap={"8px"}>
        <PrimaryfiltersDropdownManager
          filters={filters}
          onChangeFilters={onChangeFilters}
        />
        <CalendarDateRange onDateChange={onChangeDateRange} />
        <Box
          display={"flex"}
          alignItems={"center"}
          gridColumnGap={"16px"}
          justifyContent={"end"}
          flexGrow={3}
        >
          {isManager && (
            <Box>
              <CalendarViewToggleButton />
            </Box>
          )}

          <Tooltip
            title={getAddVisitToolTip({
              isManager: Boolean(isManager),
              isUserSelected: !_.isUndefined(currentFieldUser),
              lang,
            })}
          >
            <span style={{ flexShrink: 0 }}>
              <MenuButton
                color="secondary"
                label={inputVisitLang.title}
                disabled={_.isUndefined(currentFieldUser)}
                options={menuButtonOptions}
              >
                {inputVisitLang.title}
              </MenuButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default CalendarContainerHeader;
