export const isFullWidth = (route?: string) => {
  if (!route) {
    return true;
  }
  if (route.match(/^\/teams\/(create|.*\/edit)/)) {
    return false;
  }
  if (route.match(/^\/teams\/level_*./)) {
    return true;
  }
  if (route.match(/^\/(clients|teams)\//)) {
    return false;
  }
  // exception for the Edit Activity screen : need some vertical space for the Questions field
  if (route.match(/^\/workflows\/activities\/.*\/edit/)) {
    return true;
  }
  if (route.match(/^\/workflows\/(create|.*\/edit)/)) {
    return true;
  }
  if (route.match(/^\/workflows\/(activities|jobs)\/(create|.*\/edit)/)) {
    return true;
  }
  // list_id/edit must be full screen because of attributes
  if (route.match(/^\/lists\/create/)) {
    return true;
  }
  if (route.match(/^\/dashboards\/.*\/edit/)) {
    return false;
  }
  if (route.match(/^\/dashboards\/create/)) {
    return false;
  }
  if (route.match(/^\/documents\/.*\/(create|edit)/)) {
    return false;
  }
  if (route.match(/^\/users\/(mobileuser|webuser)\//)) {
    return false;
  }
  return true;
};
