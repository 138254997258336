import { getOverlappingEvent, IVisitEvent } from "fieldpro-tools";
import _ from "lodash";
import Moment from "moment";

import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import { isValidDate } from "utils/dateUtils";

import { IAddVisitDialogProps } from "../components/AddVisitDialog";
import { TNewEventPayload } from "../redux/types";
export const getErrorMessagesForVisitDialog: TValidateFunction<
  TNewEventPayload,
  Pick<IAddVisitDialogProps, "events"> & {
    maxEventEndTime: Moment.Moment;
    minEventStartTime: Moment.Moment;
    moment: typeof Moment;
  }
> = ({ attributes, lang, additionnalProps: { events } }) => {
  const langKey = lang.containers.calendar.subCategories.calendar;
  const inputVisitLang = langKey.createEditModal.inputVisit;
  const errors: Record<string, string> = {};
  const requiredKeys: Array<keyof IVisitEvent> = [
    "start_time",
    "end_time",
    "customer_id",
    "assigned_to",
  ];

  requiredKeys.forEach((key) => {
    if (isEmpty(attributes[key])) {
      errors[key] = lang.components.inputErrors.empty;
    }
  });

  const { start_time, end_time } = attributes;
  if (
    start_time &&
    end_time &&
    getOverlappingEvent(
      events.filter(
        (e) =>
          e.id !== attributes.id && e.assigned_to === attributes.assigned_to
      ),
      attributes
    )
  ) {
    errors["select_time"] = inputVisitLang.customErrorMessages.usedSlot;
  }

  return errors;
};

function isEmpty(value: any) {
  if (value instanceof Date) {
    return !isValidDate(value);
  }

  return _.isUndefined(value) || _.isEmpty(value) || _.isNull(value);
}
