export default (theme: any) => ({
  PageNotFoundTitle: {
    position: "absolute",
    top: "32.6%",
    left: "7.8%",
    height: "48px",
    margin: "0 79px 16px 0",
    fontSize: "32px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    color: theme.palette.primary.main,
  },
  PageNotFoundSubTitle: {
    position: "absolute",
    top: "40.4%",
    left: "7.8%",
    height: "40px",
    margin: "16px 0 0",
    width: "321px",
    backgroundColor: "var(--brownish-grey)",
    fontFamily: "BasierCircle",
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "-0.25px",
    color: "var(--brownish-grey)",
  },
  Wrapper: {
    position: "relative",
    height: "100%",
  },
  LittleOval: {
    position: "absolute",
    top: "43.7%",
    left: "45.5%",
    //padding: "358px 552px 0 0",
    width: "70px",
    height: "70px",
    //backgroundColor: "#e7edef",
    backgroundColor: theme.palette.primary.light,
    opacity: 0.2,
    borderRadius: "100px",
    zIndex: 1,
    //margin: "43.7% 45.5%",
  },
  BigOval: {
    width: "120px",
    height: "120px",
    backgroundColor: theme.palette.primary.light,
    opacity: 0.2,
    borderRadius: "100px",
    position: "absolute",
    top: "14.5%",
    left: "65.1%",
    zIndex: 1,
  },
  Code: {
    textStroke: `2px ${theme.palette.primary.main}`,
    position: "absolute",
    top: "20%",
    left: "50.1%",
    fontSize: "150px",
    fontWeight: "bold",
    color: "transparent",
    zIndex: 1000,
  },
});
