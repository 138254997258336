import { useState } from "react";

import {
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import { Redirect, useLocation } from "react-router-dom";

import { Black } from "assets/colors";

export interface IMenuButtonRedirectOption {
  label: string;
  redirect: true;
  icon?: React.ReactNode;
  key?: string;
}

export interface IMenuButtonOnClickOption {
  label: React.ReactNode;
  onClick?: (...args: any) => void;
  icon?: React.ReactNode;
  key?: string;
}

export type TMenuButtonOption =
  | IMenuButtonRedirectOption
  | IMenuButtonOnClickOption;

interface IBuildMenuItemFunctionPayload {
  handleCloseHandler?: () => void;
}
export interface IMenuButton {
  className?: string;
  label: React.ReactNode;
  color?: string;
  fontColor?: string;
  icon?: JSX.Element;
  options: TMenuButtonOption[];
  disabled?: boolean;
  buttonProps?: ButtonProps;
  menuProps?: MenuProps;
  buildMenuItemFunction?: (
    option: TMenuButtonOption,
    payload: IBuildMenuItemFunctionPayload
  ) => JSX.Element;
}

export const MenuButton: React.FunctionComponent<IMenuButton> = ({
  className,
  label,
  options,
  color,
  fontColor,
  icon,
  disabled = false,
  buttonProps,
  menuProps,
  buildMenuItemFunction,
}: IMenuButton) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [redirectTo, setRedirectTo] = useState<string | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getOnClick = (option: TMenuButtonOption) => {
    if ("redirect" in option) {
      return () => setRedirectTo(`${location.pathname}/create`);
    }

    const onOptionClick = option.onClick;
    if (!onOptionClick) {
      return;
    }

    return (...args: any) => {
      handleClose();
      onOptionClick(args);
    };
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  if (options.length === 1) {
    const option = options[0];
    const onClick = getOnClick(option);
    return (
      <Button
        className={className}
        aria-controls="create-menu"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={onClick}
        color="secondary"
        variant="contained"
        disableElevation
        disabled={disabled || !onClick}
        style={{
          position: "relative",
          background: color,
          color: fontColor,
          ...(buttonProps?.style || {}),
        }}
        {...(_.omit(buttonProps, "style") || {})}
      >
        {option.icon}
        {label}
      </Button>
    );
  }

  const endIcon = anchorEl ? (
    <span className="material-icons-outlined">expand_less</span>
  ) : (
    <span className="material-icons-outlined">expand_more</span>
  );

  return (
    <div>
      <Button
        className={className}
        aria-controls="create-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={handleClick}
        color="secondary"
        variant="contained"
        disableElevation
        style={{
          position: "relative",
          background: color,
          color: fontColor,
          ...(buttonProps?.style || {}),
        }}
        startIcon={icon}
        disabled={disabled}
        endIcon={disabled ? undefined : endIcon}
        {...(_.omit(buttonProps, ["style", "onClick"]) || {})}
      >
        {label}
      </Button>

      <Menu
        id="create-user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        // https://github.com/mui/material-ui/issues/10804#issuecomment-376266662
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        style={{ marginTop: "8px" }}
        {...menuProps}
      >
        {_.map(options, (option, index) => {
          if (buildMenuItemFunction) {
            return buildMenuItemFunction(option, {
              handleCloseHandler: handleClose,
            });
          } else {
            const onClick = getOnClick(option);
            return (
              <MenuItem
                style={{ margin: "5px" }}
                key={index}
                onClick={onClick}
                disabled={!onClick}
              >
                {option.icon}
                <Typography
                  component="div"
                  style={{ fontSize: 16, fontWeight: 500, color: Black }}
                >
                  {option.label}
                </Typography>
              </MenuItem>
            );
          }
        })}
      </Menu>
    </div>
  );
};

export const AddIcon = ({ style = {} }: any) => {
  return (
    <span
      className="material-icons-outlined"
      style={{ marginLeft: "-4px", marginRight: "4px", ...style }}
    >
      add
    </span>
  );
};

export const DrawAddIcon = () => (
  <span
    className="material-icons-outlined"
    style={{ marginLeft: "-4px", marginRight: "4px" }}
  >
    draw
  </span>
);

export const ImportIcon = () => (
  <span
    className="material-icons-outlined"
    style={{ marginLeft: "-4px", marginRight: "4px" }}
  >
    file_upload
  </span>
);

export default MenuButton;
