import React, {
  ComponentProps,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Box,
  Button,
  FormGroup,
  makeStyles,
  TextField,
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
// eslint-disble-next-line
import { Checkbox, Radio } from "@mui/material"; // WARNING: this is MUI V5 (not the same theme...)
import _ from "lodash";

import { GreyDark, GreyLight, White } from "assets/colors";
import SmallIconButton from "components/Buttons/SmallIconButton";
import DefaultChip from "components/Chip/DefaultChip";
import { DeleteIcon } from "components/Icon";
import InputPlaceholder from "components/Typography/InputPlaceholder";
import { WarningModalContext } from "containers/app/AppProviders";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";
import { IOption } from "model/application/components";
import { TInputAttributeLang, TLang } from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";

import BaseOptionsContainer, { IInputOption } from "../BaseOptionsContainer";
import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import CustomInputTooltip from "../InputLabel/CustomInputTooltip";
import styles from "../styles";
import InputMultipleSelectMenu from "./InputMultipleSelectMenu";

const useStyles = makeStyles(styles as any);
export interface IInputMultipleSelectPropsBase {
  defaultValue?: IOption[];
  name: string;
  options: IOption[];
  onChange?: (
    optSelected: IOption[],
    name: string
    // uncheckOptions?: IOption[],
    // undeterminedOptions?: IOption[]
  ) => void;
  error?: string;
  isUndetermined?: boolean;
  lang: TLang;
  langlabel: Pick<TInputAttributeLang, "title" | "tooltip" | "placeholder">;
  searchLabel?: string;
  viewMode?: TViewMode;
  noOptionsText?: string;
  multipleSelection?: boolean;
  viewStacked?: boolean;
  useDropDownMenu?: boolean;
  labelIcons?: ReactNode[];
  disabled?: boolean;
  limit?: number;
  onBulkImport?: (e: any) => void;
  BulkModal?: any;
  csvHeaderLine?: string;
  bulkModalConfirmBtnText?: string;
  bulkModalDialogTitle?: string;
  onDownload?: (e: any) => void;
  showDownloadButton?: boolean;
  InputMultipleSelectMenuProps?: Partial<
    ComponentProps<typeof InputMultipleSelectMenu>
  >;
}

export interface IInputMultipleSelectProps
  extends IInputMultipleSelectPropsBase,
    Omit<IInputBaseLayout, keyof IInputMultipleSelectPropsBase> {}

export const InputMultipleSelect: React.FunctionComponent<
  IInputMultipleSelectProps
> = ({
  viewMode: vMode = "CREATE",
  defaultValue = [],
  name,
  options,
  onChange,
  lang,
  langlabel,
  noOptionsText,
  multipleSelection = true,
  error,
  viewStacked: vStacked = false,
  useDropDownMenu,
  labelIcons,
  highlightContent,
  disabled = false,
  limit,
  onBulkImport,
  onDownload,
  csvHeaderLine,
  bulkModalConfirmBtnText,
  bulkModalDialogTitle,
  BulkModal,
  showDownloadButton = false,
  InputMultipleSelectMenuProps,
  ...rest
}) => {
  const rootLang = useTranslations();
  const warningModal = useContext(WarningModalContext);
  const classes = useStyles();
  const timer = useRef(-1);
  const catMenu = useRef<any>(null);

  const viewMode = vMode;
  const viewStacked = disabled ? vMode === "CREATE" : vStacked;

  const [optionSelected, setOptionSelected] = useState(defaultValue);
  const [tmpOptionSelected, setTmpOptionSelected] = useState(optionSelected);
  const [open, setOpen] = useState(false);
  const [isBulkOpen, setIsBulkOpen] = useState(false);

  useEffect(() => {
    setOptionSelected(defaultValue || []);
    // arrays are not equal if they are not the same reference
    // so we need to stringify them,otherwise the useEffect will cause a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultValue)]);

  const closeOpenMenus = (e: any) => {
    if (catMenu.current && open && !catMenu.current.contains(e.target)) {
      // setOpen(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const onSave = () => {
    setTmpOptionSelected(optionSelected);
    setOpen(false);
  };

  const onCancel = () => {
    setOptionSelected(tmpOptionSelected);
    setOpen(false);
  };

  const onSelectAll = () => {
    handleChange(options);
    clearTimeout(timer.current);
  };

  const onDeselectAll = () => {
    handleChange([]);
    clearTimeout(timer.current);
  };

  const arrowButton = () => {
    setOpen(!open);
  };

  const handleChange = (optSelected: IOption[]) => {
    setOptionSelected(optSelected);
    if (!multipleSelection) {
      setOpen(false);
    }
    // const uncheckOptions = options.filter(
    //   (o) => !optSelected.map((optS) => optS.key).includes(o.key)
    // );
    // const undeterminedOptions = optSelected.filter((o) => o.undetermined);
    if (onChange) {
      // useless check, just to pass the TS validation
      onChange(optSelected, name /*uncheckOptions, undeterminedOptions*/);
    }
  };

  const handleRemoveOption = (_keyClicked: any, value: any) => {
    const newOptionSelected = optionSelected.filter(
      (opt) => opt.key !== (_keyClicked as string) && opt.value !== value
    );
    handleChange(newOptionSelected);
  };

  const handleAddOption = (
    _keyClicked: any,
    value: any,
    highlight: boolean
  ) => {
    const element = { key: _keyClicked, label: value, highlight };
    setOptionSelected((optionSelected) => {
      let newOptionSelected;
      if (multipleSelection) {
        newOptionSelected = [...optionSelected, element];
      } else {
        newOptionSelected = [element];
      }

      if (limit && newOptionSelected.length > limit) {
        const limitModal =
          lang.components.inputMultipleSelect.limitWarningModal;
        warningModal.openWarningModal({
          title: limitModal?.title ?? "Warning",
          description: formatString(limitModal?.description, [limit]),
        });
        return optionSelected;
      }

      handleChange(newOptionSelected);
      return newOptionSelected;
    });
  };

  const keyArrSelected = _.map(
    _.filter(optionSelected, (o) => !o.undetermined),
    (op) => op.key
  );

  const keyArrUndefined = _.map(
    _.filter(optionSelected, (o) => o.undetermined),
    (op) => op["key"]
  );

  const onChangeOption = (opt: any) =>
    keyArrSelected.includes(opt.key) || keyArrUndefined.includes(opt.key)
      ? handleRemoveOption(opt.key, opt.label)
      : handleAddOption(opt.key, opt.label, opt.highlight);

  // VIEW
  if (viewMode === "VIEW" || disabled) {
    const filteredOptions = _.compact(
      _.map(optionSelected, (optSelect) => {
        return _.find(options, (opt) => opt.key === optSelect.key);
      })
    );

    return (
      <InputBaseLayout
        {...rest}
        key={JSON.stringify(options)}
        label={langlabel?.title}
        tooltip={langlabel?.tooltip}
        viewMode={viewMode}
        error={error}
        viewStacked={viewStacked}
        labelIcons={labelIcons}
        disabled={disabled}
      >
        <BaseOptionsContainer
          options={filteredOptions as IInputOption<string | undefined>[]}
          placeholder="No selection"
          highlightContent={highlightContent}
          disabled={viewMode !== "VIEW" ? disabled : false}
        />
      </InputBaseLayout>
    );
  }

  // CREATE or EDIT
  if (_.isEmpty(options)) {
    return (
      <InputBaseLayout
        {...rest}
        label={langlabel?.title}
        tooltip={langlabel?.tooltip}
        viewMode={viewMode}
        error={error}
        disabled={disabled}
      >
        <Box marginTop="8px">
          <InputPlaceholder
            placeholder={
              noOptionsText || rootLang.genericTerms.noOptionsAvailable
            }
          />
        </Box>
      </InputBaseLayout>
    );
  }

  const orderedOptions = _.orderBy(
    optionSelected,
    (opt) => opt.highlight,
    "desc"
  );

  // CREATE or EDIT
  return (
    <InputBaseLayout
      {...rest}
      key={JSON.stringify(options)}
      label={langlabel?.title}
      tooltip={langlabel?.tooltip}
      // required={required}
      viewMode={viewMode}
      error={error}
      disabled={disabled}
    >
      {
        //When there are less than 6 options
        !useDropDownMenu && options.length < 7 ? (
          <div
            className={
              viewMode === "EDIT"
                ? classes.ChipContainerEditMode
                : classes.ChipContainer
            }
          >
            <div className={classes.formControlContent}>
              <FormGroup>
                {options.map((option, index) => {
                  return (
                    <TickBox
                      dataTestId={`radio-${option.key}`}
                      tooltip={option.tooltip}
                      disabled={option.disabled}
                      key={index}
                      option={option}
                      multipleSelection={multipleSelection}
                      onClick={() => onChangeOption(option)}
                      checked={
                        keyArrSelected.includes(option.key) ||
                        keyArrUndefined.includes(option.key)
                      }
                    />
                  );
                })}
              </FormGroup>
            </div>
          </div>
        ) : (
          //When there are more than 6 options
          <div ref={catMenu}>
            <TextField
              fullWidth
              onClick={() => setOpen(!open)}
              variant="outlined"
              className={
                !_.isEmpty(optionSelected)
                  ? classes.chipTextField
                  : classes.chipTextFieldEmpty
              }
              InputProps={{
                startAdornment: (
                  <div className={classes.chipContainer}>
                    {langlabel &&
                    langlabel.placeholder &&
                    optionSelected.length === 0 ? (
                      <span style={{ paddingLeft: "8px" }}>
                        {langlabel.placeholder}
                      </span>
                    ) : null}
                    {_.map(orderedOptions, (opt) => {
                      return (
                        <DefaultChip
                          style={{
                            maxWidth: "100%",
                          }}
                          title={opt.label}
                          highlight={opt.highlight}
                          disabled={opt.disabled}
                          key={opt.key}
                          label={opt.label}
                          tabIndex={-2}
                          onDelete={() =>
                            handleRemoveOption(opt.key, opt.label)
                          }
                          deleteIcon={
                            <DeleteIcon
                              onClick={(e: any) => e?.stopPropagation()}
                              data-testid={`delete-${opt.key}`}
                              style={{
                                marginLeft: "-6px",
                                marginRight: "2px",
                                color: opt.highlight ? White : undefined,
                              }}
                            />
                          }
                        />
                      );
                    })}
                  </div>
                ),
                inputComponent: () => <div />,
                endAdornment: (
                  <SmallIconButton
                    aria-label="arrow-icon"
                    onClick={arrowButton}
                  >
                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </SmallIconButton>
                ),
              }}
            />

            {open && (
              <InputMultipleSelectMenu
                lang={lang}
                options={options}
                onSave={onSave}
                onCancel={onCancel}
                onSelectAll={multipleSelection ? onSelectAll : undefined}
                onDeselectAll={multipleSelection ? onDeselectAll : undefined}
                langlabel={langlabel}
                isOpen={open}
                multipleSelection={multipleSelection}
                keyArrSelected={keyArrSelected}
                onChangeOption={onChangeOption}
                disabled={disabled}
                // TODO: If we want a "hovering" popover, this position is not correct on some screens (see FP-6624)
                // (PS: To make the hovering work work you also need add to { position: relative } to InputBaseLayout)
                // style={{
                //   position: "absolute",
                //   zIndex: 1,
                // }}
                {...InputMultipleSelectMenuProps}
              />
            )}
          </div>
        )
      }
      {onBulkImport && (
        <BulkModal
          isOpen={isBulkOpen}
          onCloseModal={() => setIsBulkOpen(false)}
          onConfirmModal={(e: any) => {
            onBulkImport(e);
            setIsBulkOpen(false);
          }}
          headerLine={csvHeaderLine}
          confirmBtnText={bulkModalConfirmBtnText}
          dialogTitle={bulkModalDialogTitle}
          lang={lang}
          langlabel={langlabel}
          labelInput={langlabel}
        />
      )}
      {(onBulkImport || onDownload) && (
        <Box marginTop="12px" alignItems="start">
          {onBulkImport && BulkModal && (
            <Button
              startIcon={<PersonAddAltIcon />}
              onClick={() => setIsBulkOpen(true)}
              style={{ minWidth: 0 }}
            >
              {
                lang.containers.workflows.subCategories.workflows
                  .createEditModal.bulkAssignTeamsDialog.title
              }
            </Button>
          )}

          {showDownloadButton && onDownload && (
            <Button
              startIcon={<DownloadIcon />}
              onClick={onDownload}
              style={{ minWidth: 0 }}
            >
              {lang.actions.download}
            </Button>
          )}
        </Box>
      )}
    </InputBaseLayout>
  );
};

interface ITickBox {
  option: IOption;
  checked?: boolean;
  multipleSelection?: boolean;
  onClick?: (args: any) => void;
  disabled?: boolean;
  tooltip?: string;
  dataTestId?: string;
}

export const TickBox = ({
  option,
  checked,
  onClick,
  multipleSelection,
  disabled,
  tooltip,
  dataTestId,
}: ITickBox) => {
  const TickBoxBase = multipleSelection ? Checkbox : Radio;

  function handleClick(...args: any) {
    if (!disabled && onClick) {
      onClick(args);
    }
  }

  return (
    <div
      role="button"
      key={option.key}
      onClick={handleClick}
      style={{
        cursor: "pointer",
        fontFamily: "BasierCircle",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "20px",
        userSelect: "none",
        display: "flex",
        width: "100%",
      }}
    >
      <TickBoxBase
        name={option.key}
        checked={checked}
        data-testid={dataTestId}
        sx={{
          "&&:hover": {
            color: disabled ? GreyLight : "#0dcfda",
          },
          "&.Mui-checked": {
            color: disabled ? GreyLight : "#124e5d",
          },
        }}
      />
      <div
        title={option.label}
        style={{
          fontFamily: "BasierCircle",
          fontWeight: 100,
          marginTop: 12,
          overflow: "hidden",
          width: "100%",
          textOverflow: "ellipsis",
        }}
      >
        {option.label}
      </div>
      {tooltip ? (
        <span
          style={{ alignSelf: "center", paddingLeft: "8px", color: GreyDark }}
        >
          <CustomInputTooltip text={tooltip} placement="right" />
        </span>
      ) : null}
    </div>
  );
};

export const getOptionValue = (option: IOption) => {
  return option?.value || option?.key;
};

export default InputMultipleSelect;
