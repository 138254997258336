import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

import useTranslations from "hooks/useTranslations";
import TLang from "model/application/Lang";

const styles = (theme: any) => ({
  toggleSwitchClass: {
    position: "relative",
    display: "inline-block",
    textAlign: "left",
    flexShrink: 0,

    width: "70px",
    "&.small": {
      width: "48px",
    },

    "& label": {
      margin: 0,
    },
  },

  checkboxClass: {
    display: "none",

    "&:checked + $labelClass $innerClass": {
      marginLeft: 0,
    },

    "&:checked + $labelClass $switchClass": {
      right: "0px",
    },
  },

  labelClass: {
    display: "block",
    overflow: "hidden",
    border: "0 solid #bbb",
    borderRadius: "20px",
    "&.small": {
      borderRadius: "10px",
    },

    "&:not(.disabled)": {
      cursor: "pointer",
    },

    "&.disabled": {
      cursor: "not-allowed",
    },
  },

  innerClass: (lang: TLang) => ({
    display: "block",
    width: "200%",
    marginLeft: "-100%",
    transition: "margin 0.3s ease-in 0s",

    "&::before, &::after": {
      float: "left",
      width: "50%",
      padding: 0,
      color: "#fff",
      fontWeight: "bold",
      boxSizing: "border-box",
      // .large styles
      height: "28px",
      lineHeight: "26px",
      fontSize: "16px",
    },

    "&.small::before, &.small::after": {
      height: "20px",
      lineHeight: "18.5px",
      fontSize: "12px",
    },

    "&::before": {
      content: `'${lang.genericTerms.yes}'`,
      paddingRight: "27px",
      textAlign: "right",
      backgroundColor: theme.palette.primary.main,
      fontWeight: 500,
      color: "#fff",
    },

    "&.small::before": {
      paddingRight: "18px",
      textAlign: "right",
    },

    "&::after": {
      content: `'${lang.genericTerms.no}'`,
      paddingLeft: "27px",
      backgroundColor: "#c6c6c6",
      color: "grey",
      fontWeight: 500,
    },

    "&.small::after": {
      paddingLeft: "18px",
    },
  }),

  switchClass: {
    display: "block",
    background: "#fff",
    position: "absolute",
    top: 0,
    bottom: 0,
    border: "0 solid #bbb",
    transition: "all 0.3s ease-in 0s",
    // .large styles
    right: "42px",
    width: "18px",
    height: "18px",
    margin: "5px",
    borderRadius: "36px",
    "&.small": {
      right: "28px",
      margin: "5px",
      width: "10px",
      height: "10px",
      borderRadius: "5px",
    },
  },
});

const useStyles = makeStyles(styles as any);

export interface IToggleSwitch {
  name?: string;
  onChange?: (e: any) => void;
  disabled?: boolean;
  defaultChecked?: boolean;
  size?: "small" | "large";
}

const ToggleSwitch = ({
  name,
  defaultChecked,
  disabled = false,
  onChange,
  size = "large",
}: IToggleSwitch) => {
  const lang = useTranslations();
  const classes = useStyles(lang);

  return (
    <div className={classNames(classes.toggleSwitchClass, size)}>
      <input
        id={name}
        name={name}
        type="checkbox"
        className={classes.checkboxClass}
        checked={defaultChecked}
        disabled={disabled}
        onChange={onChange}
      />
      <label
        className={classNames(classes.labelClass, size, disabled && "disabled")}
        htmlFor={name}
      >
        <span className={classNames(classes.innerClass, size)} />
        <span className={classNames(classes.switchClass, size)} />
      </label>
    </div>
  );
};

export default ToggleSwitch;
