import { Component } from "react";

import { Box, withStyles } from "@material-ui/core";
import { TIMEZONE } from "fieldpro-tools";
import _ from "lodash";
import { Redirect } from "react-router";

import { Background } from "assets/colors";
import FormSection from "components/Forms/Form/Section/FormSection";
import FormWrapper from "components/Forms/Form/Wrapper/FormWrapper";
import Tab from "components/Tab/Tab";
import Tabs from "components/Tab/Tabs";
import { WarningModalContext } from "containers/app/AppProviders";
import { userCanViewClientTab } from "containers/clients/utils";
import { getRandomFileNameWithExtension } from "hooks/useFormState";
import { IOption } from "model/application/components";
import {
  ICreateEditModalProps,
  TViewMode,
} from "model/application/modal/CreateEditModal";
import { CLIENTS_ROUTE } from "model/constants/routes";
import { IAccessRightProfile, IClient } from "model/entities/Client";
import { STEP_TYPE } from "model/entities/Job";
import ITeam from "model/entities/Team";
import { IWebUser } from "model/entities/User";
import { isEmptyValue } from "utils/isEmptyValue";

import { IMetaLevel } from "./CreateEditMetaLevelModal";
import AddonsTab from "./Tabs/AddonsTab";
import ClientContact from "./Tabs/ClientContact";
import ClientLocation from "./Tabs/ClientLocation";
import ClientManagementTab from "./Tabs/ClientManagementTab";
import CustomBillingTab from "./Tabs/CustomBillingTab";
import DashboardTab from "./Tabs/DashboardTab";
import DeveloperTab from "./Tabs/DeveloperTab";
import EnvironmentVariablesTab from "./Tabs/EnvironmentVariablesTab";
import MainTab from "./Tabs/MainTab";
import TeamsTab from "./Tabs/TeamsTab";
import { convertMetaLevelsFromFormFormat } from "./Tabs/TeamsTab.utils";
import UsageTab from "./Tabs/UsageTab";
import UsersTab from "./Tabs/UsersTab";
import { TOption } from "./Tabs/UsersTab/InputMobileUserRoles";

export enum ClientSettingsTabs {
  MAIN = "main",
  DASHBOARD = "dashboard",
  TEAMS = "teams",
  USERS = "users",
  WORKFLOWS = "workflows",
  MANAGEMENT = "management",
  // PLANS = "plans", // Replaced by Stripe portal
  USAGE = "usage",
  DEVELOPER = "developer",
  ADDON = "addon",
  BILLING = "billing",
  ENVIRONMENT_VARIABLES = "environment_variables",
}

const styles = {
  FormTabsContainer: {
    background: Background,
  },
} as const;

export interface IClientFormAttributes extends Omit<IClient, "logo"> {
  logo: any;
}

export interface IClientFormAdditionnalProps {
  // teams: ITeam[]; // TODO: ?
  user_role: string;
  selectedClientWebUsers: IWebUser[];
  onOpenEditClient?: (callback: any) => any;
  handleSetClientLive: (args: any) => void;
  handleForceMaintenance: (clientId: string, runAll?: boolean) => void;
  shouldDisplayNewPassword?: boolean;
  displayNewPassword?: () => void;
  hideNewPassword?: () => void;
  actionsAvailable?: string[];
}

export interface IClientFormStates
  extends Omit<
    IClient,
    "active" | "downloads_count" | "downloads_quota_period"
  > {
  old_sensible_data_password?: string;
  isOpen: boolean;
  errorMessage: string;
  warningMessage: string;
  isWarning: boolean;
  clientTeams: ITeam[];
  isLoadingClientTeams: boolean;
  should_create_stripe_id: boolean;
  redirectTo?: string;
}

export interface IClientFormProps
  extends ICreateEditModalProps<IClientFormAdditionnalProps, IClient> {
  onCreateClient: (args: any) => void;
  onEditClient: (args: any) => void;
  onDiscard: () => void;
  viewOnly?: boolean;
}

interface ITabProps {
  viewMode: TViewMode;
  viewOnly?: boolean;
}

/**
 * ClientForm component allows to create/edit a  client in a modal component
 * The name, description, image and license number of the client can be edited.
 */
class ClientForm extends Component<IClientFormProps, IClientFormStates> {
  constructor(props: IClientFormProps) {
    super(props);

    this.state = {
      ...props.attributes,
      isOpen: true,
      errorMessage: "",
      warningMessage: "",
      isWarning: false,
      clientTeams: [],
      isLoadingClientTeams: false,
      mobile_user_business_roles:
        props.attributes.mobile_user_business_roles || [],
      web_user_business_roles: props.attributes.web_user_business_roles || [],
      redirectTo: undefined,
      should_create_stripe_id: true,
    };

    if (this.props.additionnalProps.onOpenEditClient) {
      this.props.additionnalProps.onOpenEditClient((teams: any) => {
        this.setState({
          clientTeams: teams,
          isLoadingClientTeams: false,
        });
      });
    }
  }

  //Update the state if there is a subsequent change in the props.attributes
  static getDerivedStateFromProps(
    props: IClientFormProps,
    state: IClientFormStates
  ) {
    return {
      ...state,
      ...props.attributes,
      mobile_user_business_roles:
        props.attributes.mobile_user_business_roles || [],
      web_user_business_roles: props.attributes.web_user_business_roles || [],
    };
  }

  handleMetaHierarchyDependencies = (metaHierarchy: IMetaLevel[]) => {
    const { onChangeAttributeValues } = this.props;
    const { meta_hierarchy_dependencies: previousMetaHierarchy } = this.state;
    const fullHierarchyDependencies = {
      ...previousMetaHierarchy,
      ...convertMetaLevelsFromFormFormat(metaHierarchy),
    };
    for (const e of metaHierarchy.filter((ee) => !ee.active)) {
      delete fullHierarchyDependencies[`level_${e.index}`];
    }
    onChangeAttributeValues(
      "meta_hierarchy_dependencies",
      fullHierarchyDependencies
    );
    this.setState({ meta_hierarchy_dependencies: fullHierarchyDependencies });
  };

  handleChangeGpsTrackingDays = (values: IOption[]) => {
    const { onChangeAttributeValues } = this.props;
    const formattedValues = values.map((m) => m.key);
    onChangeAttributeValues("gps_tracking_days", formattedValues);
    this.setState({ gps_tracking_days: formattedValues });
  };

  handleChangeAvailableJobs = (values: IOption<STEP_TYPE>[]) => {
    const { onChangeAttributeValues } = this.props;
    const formattedValues = values.map((m) => m.key);
    onChangeAttributeValues("available_jobs", formattedValues);
    this.setState({ available_jobs: formattedValues });
  };

  /**
   * Handles input changes in input fields
   * @param {SyntheticEvent} e Event Object
   */
  handleInputChange = (value: any, name: string) => {
    const {
      onChangeAttributeValues,
      additionnalProps: {
        shouldDisplayNewPassword,
        displayNewPassword,
        hideNewPassword,
      },
    } = this.props;

    const { meta_hierarchy_dependencies } = this.state;

    if (name === "old_sensible_data_password") {
      if (value !== "" && !shouldDisplayNewPassword) {
        displayNewPassword && displayNewPassword();
      } else if (!value && shouldDisplayNewPassword) {
        hideNewPassword && hideNewPassword();
        onChangeAttributeValues("new_sensible_data_password", undefined);
      }
      onChangeAttributeValues(name, value);
    } else if (name.startsWith("level_")) {
      if (value === "") {
        delete meta_hierarchy_dependencies[name];
      } else {
        meta_hierarchy_dependencies[name] = {
          level_type_name: value,
          level_type_number: -1,
        };
      }
      onChangeAttributeValues(
        "meta_hierarchy_dependencies",
        meta_hierarchy_dependencies
      );
    } else if (name === "is_route_management_enabled") {
      onChangeAttributeValues("is_route_management_enabled", value);
      onChangeAttributeValues("route_management_access_roles", undefined);
    } else {
      onChangeAttributeValues(name, value);
    }

    const newState = {
      ...this.state,
      [name]: value,
    };
    this.setState(newState);
  };

  handleChangeGoBackDays = (value: any, name: string) => {
    const { onChangeAttributeValues } = this.props;
    if (name == "go_back_days_filter") {
      onChangeAttributeValues(name, value);
      this.setState({
        go_back_days_filter: value,
      });
      onChangeAttributeValues(name, value);
    }
  };

  handleChangeDailyMaintenance = (value: any, name: string) => {
    const { onChangeAttributeValues } = this.props;
    if (name == "time_daily_maintenance") {
      const hourInUTCTime = !isEmptyValue(value)
        ? (value as Date).getUTCHours()
        : (null as unknown as number);
      const minuteInUTCTime = !isEmptyValue(value)
        ? (value as Date).getUTCMinutes()
        : (null as unknown as number);
      this.setState({
        hour_daily_maintenance: hourInUTCTime,
        minute_daily_maintenance: minuteInUTCTime,
      });
      onChangeAttributeValues("hour_daily_maintenance", hourInUTCTime);
      onChangeAttributeValues("minute_daily_maintenance", minuteInUTCTime);
    }
  };

  handleSelectChange = (value: any, name: string) => {
    const { onChangeAttributeValues } = this.props;
    onChangeAttributeValues(name, value);

    this.setState((prevState) => ({ ...prevState, [name]: value }));
  };

  handleMultipleSelectChange = (value: any, name: string) => {
    const { onChangeAttributeValues } = this.props;
    if (value[0]) value = value[0].key;
    else value = undefined;
    onChangeAttributeValues(name, value);
  };

  handlePictureChange = (file: any, variableName: string) => {
    const { onChangeAttributeValues } = this.props;

    const fileName = file ? getRandomFileNameWithExtension(file?.name) : null;

    const fileValue = file ? { file, fileName } : null;
    onChangeAttributeValues(variableName, fileValue);
  };

  handleChangeProfiles = (profiles: IAccessRightProfile[]) => {
    const { onChangeAttributeValues } = this.props;
    onChangeAttributeValues("access_right_profiles", profiles);
  };

  handleChangeMobileRoles = (options: TOption[]) => {
    const { onChangeAttributeValues } = this.props;
    const { route_management_access_roles, is_route_management_enabled } =
      this.state;

    const newRouteAccessRoles =
      is_route_management_enabled &&
      mapRouteAccessRoles(options, route_management_access_roles);

    if (newRouteAccessRoles && is_route_management_enabled) {
      onChangeAttributeValues(
        "route_management_access_roles",
        newRouteAccessRoles
      );
    }

    if (_.isEmpty(newRouteAccessRoles)) {
      onChangeAttributeValues("is_route_management_enabled", false);
    }

    const roles = _.compact(_.map(options, "value"));
    onChangeAttributeValues("mobile_user_business_roles", roles);
  };

  handleChangeWebRoles = (options: TOption[]) => {
    const { onChangeAttributeValues } = this.props;
    const roles = _.compact(_.map(options, "value"));
    onChangeAttributeValues("web_user_business_roles", roles);
  };

  getFieldError = (fieldTag: string) => {
    const { errors } = this.props;
    const hasError = errors?.[fieldTag];
    return hasError;
  };

  buildMainTab = ({ viewMode, viewOnly }: ITabProps) => {
    const {
      name,
      description,
      timezone,
      id,
      logo,
      gps_tracking_days,
      time_slots_start,
      time_slots_stop,
      stripe_customer_id,
      workspace_type,
      language,
      is_gps_tracking_enabled,
      geo_checkin_distance,
      geo_checkout_distance,
    } = this.state;

    return (
      <MainTab
        viewMode={viewMode}
        name={name}
        description={description}
        id={id}
        logo={logo}
        language={language}
        gps_tracking_days={
          gps_tracking_days ?? ["mo", "tu", "we", "th", "fr", "sa", "su"]
        }
        time_slots_start={
          time_slots_start ?? new Date(new Date().setHours(9, 0, 0))
        }
        time_slots_stop={
          time_slots_stop ?? new Date(new Date().setHours(17, 0, 0))
        }
        timezone={timezone ?? TIMEZONE.UTC}
        stripe_customer_id={stripe_customer_id}
        is_gps_tracking_enabled={is_gps_tracking_enabled}
        geo_checkin_distance={geo_checkin_distance}
        geo_checkout_distance={geo_checkout_distance}
        workspace_type={workspace_type}
        onSelectChange={this.handleSelectChange}
        onInputChange={this.handleInputChange}
        onChangePictures={this.handlePictureChange}
        onChangeGpsTrackingDays={this.handleChangeGpsTrackingDays}
        getFieldError={this.getFieldError}
        onSave={this.onSave}
        onDiscard={this.onDiscard}
        onCreate={this.onCreate}
        viewOnly={viewOnly}
      />
    );
  };
  buildClientManagementTab = ({ viewMode, viewOnly }: ITabProps) => {
    const {
      category,
      max_downloads,
      hour_daily_maintenance,
      minute_daily_maintenance,
      available_jobs,
      go_back_days_filter,
      invoiced_by,
      language,
      id,
      dbname,
      sales_owner,
      operations_owner,
      data_vis_owner,
      status,
      is_enterprise,
      payments_overdue,
    } = this.state;

    const {
      additionnalProps: { user_role, handleSetClientLive },
    } = this.props;

    return (
      <ClientManagementTab
        id={id}
        client={this.props.attributes}
        isEnterprise={is_enterprise}
        payments_overdue={payments_overdue}
        dbname={dbname}
        viewMode={viewMode}
        max_downloads={max_downloads}
        hour_daily_maintenance={hour_daily_maintenance}
        minute_daily_maintenance={minute_daily_maintenance}
        onChangeDailyMaintenance={this.handleChangeDailyMaintenance}
        onChangeGoBackDays={this.handleChangeGoBackDays}
        onMultipleSelectChange={this.handleMultipleSelectChange}
        category={category}
        sales_owner={sales_owner}
        operations_owner={operations_owner}
        data_vis_owner={data_vis_owner}
        invoiced_by={invoiced_by}
        go_back_days_filter={go_back_days_filter}
        userRole={user_role}
        language={language}
        available_jobs={available_jobs}
        onSelectChange={this.handleSelectChange}
        onInputChange={this.handleInputChange}
        onChangeAvailableJobs={this.handleChangeAvailableJobs}
        shouldMarkError={this.getFieldError}
        onSave={this.onSave}
        onDiscard={this.onDiscard}
        onCreate={this.onCreate}
        onForceMaintenance={this.props.additionnalProps.handleForceMaintenance}
        viewOnly={viewOnly}
        onSetClientLive={handleSetClientLive}
        status={status}
      />
    );
  };

  buildDashboardTab = ({ viewMode }: ITabProps) => {
    const {
      default_date_filter,
      is_bigquery_enabled,
      dataset_name,
      private_key,
      client_email,
      msql_connection,
      main_dashboards,
    } = this.state;
    const {
      additionnalProps: { shouldDisplayNewPassword },
    } = this.props;

    return (
      <DashboardTab
        viewMode={viewMode}
        default_date_filter={default_date_filter}
        main_dashboards={main_dashboards}
        is_bigquery_enabled={is_bigquery_enabled}
        client_email={client_email}
        dataset_name={dataset_name}
        private_key={private_key}
        msql_connection={msql_connection}
        shouldDisplayNewPassword={shouldDisplayNewPassword}
        onInputChange={this.handleInputChange}
        onSelectChange={this.handleSelectChange}
      />
    );
  };

  buildTeamTab = ({ viewMode }: ITabProps) => {
    const { id, isLoadingClientTeams, meta_hierarchy_dependencies } =
      this.state;

    return (
      <TeamsTab
        viewMode={viewMode}
        id={id}
        meta_hierarchy_dependencies={meta_hierarchy_dependencies}
        isLoadingClientTeams={isLoadingClientTeams}
        onChangeMetaHierarchyDependencies={this.handleMetaHierarchyDependencies}
      />
    );
  };

  buildEnvironmentVariablesTab = ({ viewMode }: ITabProps) => {
    const { secrets } = this.state;

    return (
      <EnvironmentVariablesTab
        viewMode={viewMode}
        secrets={secrets}
        onInputChange={this.handleInputChange}
      />
    );
  };

  buildUsersTab = ({ viewMode }: ITabProps) => {
    const {
      id,
      /**
       * // read these from props directly
       * mobile_user_business_roles,
       * access_right_profiles,
       * web_user_business_roles,
       */
      unique_log_in,
      status,
      phone_regex,
      daily_status_query,
      sales_owner,
      operations_owner,
      magic_number,
    } = this.state;

    const {
      additionnalProps: { handleSetClientLive, selectedClientWebUsers },
      attributes,
    } = this.props;

    return (
      <UsersTab
        viewMode={viewMode}
        id={id}
        access_right_profiles={attributes.access_right_profiles}
        mobile_user_business_roles={attributes.mobile_user_business_roles}
        web_user_business_roles={attributes.web_user_business_roles}
        unique_log_in={unique_log_in}
        status={status}
        phone_regex={phone_regex}
        daily_status_query={daily_status_query}
        sales_owner={sales_owner}
        operations_owner={operations_owner}
        magic_number={magic_number}
        shouldMarkError={this.getFieldError}
        onChangeMobileRoles={this.handleChangeMobileRoles}
        onChangeProfiles={this.handleChangeProfiles}
        onChangeWebRoles={this.handleChangeWebRoles}
        onInputChange={this.handleInputChange}
        onSelectChange={this.handleSelectChange}
        onSetClientLive={handleSetClientLive}
        selectedClientWebUsers={selectedClientWebUsers || []}
      />
    );
  };

  buildBillingTab = ({ viewMode }: ITabProps) => {
    const {
      id,
      status,
      app_user_licenses,
      billing_owner_mail,
      billing_type,
      language,
      category,
      stripe_customer_id,
      should_create_stripe_id,
    } = this.state;

    return (
      <CustomBillingTab
        viewMode={viewMode}
        id={id}
        status={status}
        app_user_licenses={app_user_licenses}
        should_create_stripe_id={should_create_stripe_id}
        billing_owner_mail={billing_owner_mail}
        billing_type={billing_type}
        stripe_customer_id={stripe_customer_id}
        language={language}
        category={category}
        onInputChange={this.handleInputChange}
        onSelectChange={this.handleSelectChange}
        shouldMarkError={this.getFieldError}
      />
    );
  };

  buildClientLocation = ({ viewMode }: ITabProps) => {
    const { region, country, sector } = this.state;
    return (
      <ClientLocation
        viewMode={viewMode}
        region={region}
        country={country}
        sector={sector}
        onSelectChange={this.handleSelectChange}
        onMultipleSelectChange={this.handleMultipleSelectChange}
      />
    );
  };

  buildClientContact = ({ viewMode }: ITabProps) => {
    const { main_contact } = this.state;
    return (
      <ClientContact
        main_contact={main_contact}
        viewMode={viewMode}
        onSelectChange={this.handleMultipleSelectChange}
      />
    );
  };

  buildUsageTab = () => {
    const { capacity_quota, capacity_used } = this.props.attributes;
    return <UsageTab maxValue={capacity_quota} currentValue={capacity_used} />;
  };

  buildDeveloperTab = ({ viewMode }: ITabProps) => {
    const { id, dbname } = this.state;
    return <DeveloperTab id={id} dbname={dbname} viewMode={viewMode} />;
  };
  buildAddonsTab = () => {
    const {
      available_jobs,
      route_management_access_roles,
      is_route_management_enabled,
    } = this.state;

    const { attributes, onDiscard, onChangeAttributeValues } = this.props;

    return (
      <AddonsTab
        handleInputChange={this.handleInputChange}
        onChangeAttributeValues={onChangeAttributeValues}
        attributes={attributes}
        onSave={this.onSave}
        is_route_management_enabled={is_route_management_enabled}
        route_management_access_roles={route_management_access_roles}
        onDiscard={onDiscard}
        available_jobs={available_jobs}
        handleChangeAvailableJobs={this.handleChangeAvailableJobs}
        handleSelectChange={this.handleSelectChange}
      />
    );
  };

  onCreate = () => {
    const { onCreateClient, attributes } = this.props;
    onCreateClient(attributes);
    this.setState({ redirectTo: CLIENTS_ROUTE });
  };

  onSave = () => {
    const { onEditClient, attributes } = this.props;
    if (attributes.gps_tracking_days?.length > 0) {
      const date = new Date();

      if (!attributes.time_slots_start) {
        date.setHours(9, 0, 0);
        attributes.time_slots_start = new Date(date.setHours(9, 0, 0));
      }
      if (!attributes.time_slots_stop) {
        date.setHours(17, 0, 0);
        attributes.time_slots_stop = new Date(date.setHours(17, 0, 0));
      }
      this.setState({
        time_slots_start: attributes.time_slots_start,
        time_slots_stop: attributes.time_slots_stop,
      });
    } else {
      attributes.time_slots_start = null as unknown as Date;
      attributes.time_slots_stop = null as unknown as Date;
    }
    //the backend will take undefined as unchanged (keep old value)
    if (!attributes.sales_owner) attributes.sales_owner = "";
    if (!attributes.operations_owner) attributes.operations_owner = "";
    if (!attributes.data_vis_owner) attributes.data_vis_owner = "";

    onEditClient(attributes);
    // TODO: disableEdit?
  };

  onDiscard = () => {
    if (this.props.onDiscard) {
      this.props.onDiscard();
    }
    if (this.props.isCreation) {
      this.setState({ redirectTo: CLIENTS_ROUTE });
    }
  };

  /**
   * Renders the EditClient Modal to the DOM
   */
  render() {
    const {
      classes,
      attributes,
      title,
      lang,
      isCreation,
      viewOnly,
      errors,
      additionnalProps: { user_role },
    } = this.props;
    const { redirectTo } = this.state;

    const label = lang.containers.clients.subCategories.clients.createEditModal;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    if (isCreation) {
      return (
        <FormWrapper
          title={title}
          onDiscard={this.onDiscard}
          onSave={this.onCreate}
          disableSave={!_.isEmpty(errors)}
          saveText={
            lang.containers.clients.subCategories.clients.createEditModal
              .inputClientManagement.title
          }
        >
          <Tabs className={classes.FormTabsContainer}>
            <Tab
              id={ClientSettingsTabs.MAIN}
              label={label.section?.mainTab ?? ""}
              hidden={
                !userCanViewClientTab({
                  tabId: ClientSettingsTabs.MAIN,
                  client: attributes,
                  role: user_role,
                })
              }
            >
              <Box paddingBottom="24px">
                {this.buildMainTab({ viewMode: "CREATE" })}
              </Box>
            </Tab>

            <Tab
              id={ClientSettingsTabs.DASHBOARD}
              label={label.section?.dashboardTab ?? ""}
              hidden={
                !userCanViewClientTab({
                  tabId: ClientSettingsTabs.DASHBOARD,
                  client: attributes,
                  role: user_role,
                })
              }
            >
              <FormSection
                saveText={
                  lang.containers.clients.subCategories.clients.createEditModal
                    .inputClientManagement.title
                }
                onSave={this.onCreate}
                onDiscard={this.onDiscard}
                isCreation
                viewOnly={viewOnly}
              >
                {this.buildDashboardTab({ viewMode: "CREATE" })}
              </FormSection>
            </Tab>

            <Tab
              id={ClientSettingsTabs.TEAMS}
              label={label.section?.teamsTab ?? ""}
              hidden={
                !userCanViewClientTab({
                  tabId: ClientSettingsTabs.TEAMS,
                  client: attributes,
                  role: user_role,
                })
              }
            >
              <FormSection
                saveText={
                  lang.containers.clients.subCategories.clients.createEditModal
                    .inputClientManagement.title
                }
                onSave={this.onCreate}
                onDiscard={this.onDiscard}
                isCreation
              >
                {this.buildTeamTab({ viewMode: "CREATE" })}
              </FormSection>
            </Tab>

            <Tab
              id={ClientSettingsTabs.USERS}
              label={label.section?.usersTab ?? ""}
              hidden={
                !userCanViewClientTab({
                  tabId: ClientSettingsTabs.USERS,
                  client: attributes,
                  role: user_role,
                })
              }
            >
              <FormSection
                saveText={label.inputClientManagement.title}
                onSave={this.onCreate}
                onDiscard={this.onDiscard}
                isCreation
              >
                {this.buildUsersTab({ viewMode: "CREATE" })}
              </FormSection>
            </Tab>

            <Tab
              id={ClientSettingsTabs.MANAGEMENT}
              label={label.section?.clientManagementTab ?? ""}
              badgeContent={errors?.category ? "!" : undefined}
              hidden={
                !userCanViewClientTab({
                  tabId: ClientSettingsTabs.MANAGEMENT,
                  client: attributes,
                  role: user_role,
                })
              }
            >
              <FormSection
                saveText={
                  lang.containers.clients.subCategories.clients.createEditModal
                    .inputClientManagement.title
                }
                onSave={this.onCreate}
                onDiscard={this.onDiscard}
                isCreation
              >
                {userCanViewClientTab({
                  tabId: ClientSettingsTabs.BILLING,
                  client: attributes,
                  role: user_role,
                }) && this.buildBillingTab({ viewMode: "CREATE" })}
                {this.buildClientLocation({ viewMode: "CREATE" })}
                {this.buildClientManagementTab({ viewMode: "CREATE" })}
              </FormSection>
            </Tab>
            <Tab
              id={ClientSettingsTabs.ENVIRONMENT_VARIABLES}
              label={label.section?.environmentVariablesTab ?? ""}
              hidden={
                !userCanViewClientTab({
                  tabId: ClientSettingsTabs.ENVIRONMENT_VARIABLES,
                  client: attributes,
                  role: user_role,
                })
              }
            >
              <FormSection
                saveText={
                  lang.containers.clients.subCategories.clients.createEditModal
                    .inputClientManagement.title
                }
                onSave={this.onCreate}
                onDiscard={this.onDiscard}
                isCreation
              >
                {this.buildEnvironmentVariablesTab({ viewMode: "CREATE" })}
              </FormSection>
            </Tab>
          </Tabs>
        </FormWrapper>
      );
    }

    return (
      <FormWrapper title={title}>
        <Tabs className={classes.FormTabsContainer}>
          <Tab
            id={ClientSettingsTabs.MAIN}
            label={label.section?.mainTab ?? ""}
            hidden={
              !userCanViewClientTab({
                tabId: ClientSettingsTabs.MAIN,
                client: attributes,
                role: user_role,
              })
            }
          >
            {/* <FormSection onSave={this.onSave} onDiscard={this.onDiscard}> */}
            <Box paddingBottom="24px">
              {this.buildMainTab({ viewMode: "VIEW", viewOnly })}
            </Box>
            {/* </FormSection> */}
          </Tab>

          <Tab
            id={ClientSettingsTabs.DASHBOARD}
            label={label.section?.dashboardTab ?? ""}
            hidden={
              !userCanViewClientTab({
                tabId: ClientSettingsTabs.DASHBOARD,
                client: attributes,
                role: user_role,
              })
            }
          >
            <FormSection
              onSave={this.onSave}
              onDiscard={this.onDiscard}
              viewOnly={viewOnly}
            >
              {({ viewMode }) => this.buildDashboardTab({ viewMode })}
            </FormSection>
          </Tab>

          <Tab
            id={ClientSettingsTabs.TEAMS}
            label={label.section?.teamsTab ?? ""}
            hidden={
              !userCanViewClientTab({
                tabId: ClientSettingsTabs.TEAMS,
                client: attributes,
                role: user_role,
              })
            }
          >
            <FormSection
              onSave={this.onSave}
              onDiscard={this.onDiscard}
              viewOnly={viewOnly}
            >
              {({ viewMode }) => this.buildTeamTab({ viewMode })}
            </FormSection>
          </Tab>

          <Tab
            id={ClientSettingsTabs.USERS}
            label={label.section?.usersTab ?? ""}
            hidden={
              !userCanViewClientTab({
                tabId: ClientSettingsTabs.USERS,
                client: attributes,
                role: user_role,
              })
            }
          >
            <FormSection
              onSave={this.onSave}
              onDiscard={this.onDiscard}
              viewOnly={viewOnly}
            >
              {({ viewMode }) => this.buildUsersTab({ viewMode })}
            </FormSection>
          </Tab>

          <Tab
            id={ClientSettingsTabs.MANAGEMENT}
            label={label.section?.clientManagementTab ?? ""}
            hidden={
              !userCanViewClientTab({
                tabId: ClientSettingsTabs.MANAGEMENT,
                client: attributes,
                role: user_role,
              })
            }
          >
            <FormSection
              onSave={this.onSave}
              onDiscard={this.onDiscard}
              viewOnly={viewOnly}
            >
              {({ viewMode }: any) => (
                <>
                  {userCanViewClientTab({
                    tabId: ClientSettingsTabs.BILLING,
                    client: attributes,
                    role: user_role,
                  }) && this.buildBillingTab({ viewMode })}

                  {this.buildClientLocation({ viewMode })}
                  {this.buildClientContact({ viewMode })}
                  {this.buildClientManagementTab({ viewMode })}
                </>
              )}
            </FormSection>
          </Tab>

          {/* <Tab
            id={ClientSettingsTabs.PLANS}
            label={label.section!.automatedBillingTab}
            hidden={
              !userCanViewClientTab({
                tabId: ClientSettingsTabs.PLANS,
                client: attributes,
                role: user_role,
              })
            }
          >
            <AutomatedBillingTab clientId={attributes.id} />
          </Tab> */}

          <Tab
            id={ClientSettingsTabs.USAGE}
            label={label.section?.usageTab ?? ""}
            hidden={
              !userCanViewClientTab({
                tabId: ClientSettingsTabs.USAGE,
                client: attributes,
                role: user_role,
              })
            }
          >
            <FormSection
              onSave={this.onCreate}
              onDiscard={this.onDiscard}
              isCreation
            >
              {this.buildUsageTab()}
            </FormSection>
          </Tab>

          <Tab
            id={ClientSettingsTabs.DEVELOPER}
            label={label.section?.developerTab ?? ""}
            hidden={
              !userCanViewClientTab({
                tabId: ClientSettingsTabs.DEVELOPER,
                client: attributes,
                role: user_role,
              })
            }
          >
            <FormSection
              onSave={this.onCreate}
              onDiscard={this.onDiscard}
              isCreation
            >
              {this.buildDeveloperTab({ viewMode: "VIEW" })}
            </FormSection>
          </Tab>

          <Tab
            id={ClientSettingsTabs.ADDON}
            label={label.addonTab.title}
            hidden={
              !userCanViewClientTab({
                tabId: ClientSettingsTabs.ADDON,
                client: attributes,
                role: user_role,
              })
            }
          >
            {this.buildAddonsTab()}
          </Tab>
          <Tab
            id={ClientSettingsTabs.ENVIRONMENT_VARIABLES}
            label={label.section?.environmentVariablesTab ?? ""}
            hidden={
              !userCanViewClientTab({
                tabId: ClientSettingsTabs.ENVIRONMENT_VARIABLES,
                client: attributes,
                role: user_role,
              })
            }
          >
            <FormSection
              onSave={this.onSave}
              onDiscard={this.onDiscard}
              viewOnly={viewOnly}
            >
              {({ viewMode }) => {
                return this.buildEnvironmentVariablesTab({ viewMode });
              }}
            </FormSection>
          </Tab>
        </Tabs>
      </FormWrapper>
    );
  }
}

// TODO: do it in the BE ?
export const mapRouteAccessRoles = (
  options: TOption[],
  routeAccessRoles?: string[]
) => {
  const newRouteAccessRoles = _.map(routeAccessRoles, (role) => {
    const roleOption = _.find(options, { key: role });
    if (!roleOption) {
      return;
    }
    // the new role string
    return roleOption.value;
  });
  return _.compact(newRouteAccessRoles);
};

ClientForm.contextType = WarningModalContext;

export default withStyles(styles)(ClientForm);
