import { useEffect, useState } from "react";

import { TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import { getSelectedClient } from "containers/clients/redux/selectors";
import { getUserName } from "containers/users/components/forms/ViewEditWebUser";
import { allMobileUsersComposedSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { useTranslations } from "hooks";
import { useMomentTimeZone } from "hooks/useMomentTimeZone";

import Board, { IBoardProps } from "./Board";
import { ICalendarProps } from "./Calendar";
import StickyTable, {
  StickyTableRowItem,
  TStickyTableColumnItem,
} from "./StickyTable";
import { computeNewDraggedEvent } from "./utils/computeNewDraggedEvent";
import { getTimesOfDay } from "./utils/getTimesOfDay";

export type TCalendarViewProps = {
  selectedUsers: Array<string>;
} & Pick<IBoardProps, "eventPlaceHolders"> &
  Pick<
    ICalendarProps,
    | "onClickDeleteEvent"
    | "onClickDeclineEvent"
    | "onClickApproveEvent"
    | "onEditEvent"
    | "defaultEvents"
    | "onChange"
    | "startDate"
    | "onClickTimeSlot"
    | "activeBoardItems"
  >;
const CalendarDayView: React.FC<TCalendarViewProps> = ({
  selectedUsers,
  eventPlaceHolders,
  defaultEvents,
  onClickDeleteEvent,
  onEditEvent,
  activeBoardItems,
  onClickApproveEvent,
  onClickDeclineEvent,
  onClickTimeSlot,
  onChange,
  startDate,
}) => {
  const [events, setEvents] = useState<TCalendarEvent[]>(defaultEvents);
  const client = useSelector(getSelectedClient);
  const { moment } = useMomentTimeZone();
  const allMobileUsers = useSelector(allMobileUsersComposedSelector);
  const timesOfDay = getTimesOfDay(client, moment);

  const date = moment(startDate).format("YYYY-MM-DD");
  const users = _.filter(allMobileUsers, (u) => selectedUsers.includes(u.id));

  const handleDragEnd: OnDragEndResponder = (result) => {
    const newEvents = computeNewDraggedEvent({
      dropResult: result,
      events,
      moment,
    });
    if (!_.isEqual(events, newEvents)) {
      setEvents(newEvents);
      onChange && onChange(newEvents);
    }
  };

  useEffect(() => {
    setEvents(defaultEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultEvents)]);

  const lang = useTranslations();
  const langKey = lang.containers.calendar.subCategories.calendar;

  const [currentTime, setCurrentTime] = useState(moment().format("hA"));

  useEffect(() => {
    const interval = setInterval(() => {
      if (moment().format("hA") !== currentTime) {
        setCurrentTime(moment().format("hA"));
      }
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <StickyTable
        columnWidth={160}
        rowHeight={70}
        columns={_.map(timesOfDay, (time): TStickyTableColumnItem => {
          return {
            key: time,
            label: time,
            highlight: time === currentTime,
          };
        })}
        rows={_.map(users, (user) => ({
          key: user.id,
          title: getUserName({ attributes: user }),
          description: user.phone,
        }))}
        title={langKey.createEditModal.inputFieldUser.options.titlePlural}
      >
        {_.map(users, (user) => {
          const userEvents = _.filter(events, (e) => e.assigned_to === user.id);
          const eventsOfSameDay = _.filter(userEvents, (e) =>
            moment(e.start_time).isSame(date, "day")
          );
          const userPlaceHolders = _.filter(
            eventPlaceHolders,
            (e) => e.assigned_to === user.id
          );
          const placeHolderOfSameDay = _.filter(userPlaceHolders, (e) =>
            moment(e.start_time).isSame(date, "day")
          );
          return (
            <StickyTableRowItem key={user.id + "_sticky_table_row_item"}>
              <Board
                boardId={date + "_" + user.id}
                events={eventsOfSameDay}
                activeBoardItems={activeBoardItems}
                eventPlaceHolders={placeHolderOfSameDay || []}
                onClickDeleteEvent={onClickDeleteEvent}
                onClickApproveEvent={onClickApproveEvent}
                onClickDeclineEvent={onClickDeclineEvent}
                onEditEvent={onEditEvent}
                onClickTimeSlot={(date) => {
                  if (onClickTimeSlot) {
                    onClickTimeSlot(date, user.id);
                  }
                }}
                orientation="horizontal"
              />
            </StickyTableRowItem>
          );
        })}
      </StickyTable>
    </DragDropContext>
  );
};

export default CalendarDayView;
