import _ from "lodash";

import {
  buildDateFilter,
  buildMoreFilter,
  buildMultipleChoiceFilter,
  buildTeamsAndHierarchyFilter,
} from "components/Filter/prepareFiltersUtils";
import FILTER_TYPE, { FILTER_TAG } from "components/Filter/TypeFilter";
import { applyFilterDependenciesOnUsers } from "components/Filter/utils/applyDependencies";
import { isDisplayed } from "containers/lists/subcategories/prepareFilters";
import { IOption } from "model/application/components";
import { IFilter } from "model/application/Filter";
import TLang from "model/application/Lang";
import { DEFAULT_DATE_FILTER, IClient } from "model/entities/Client";
import { IList, LIST_TYPE } from "model/entities/List";
import { ITeamSelector } from "model/entities/Team";
import IUser from "model/entities/User";
import {
  CUSTOM_FIELD_TYPE,
  IActivity,
  IWorkflow,
} from "model/entities/Workflow";
import { isEmptyValue } from "utils/isEmptyValue";

interface IPreparePicturesFilters {
  client: IClient;
  teams: ITeamSelector[];
  activities: IActivity[];
  workflows: IWorkflow[];
  lists: IList[];
  users: IUser[];
  params: any;
  lang: TLang;
  defaultCustomerOptions: IOption[];
}
export const preparePicturesFilters = ({
  activities,
  workflows,
  client,
  lang,
  lists,
  params,
  teams,
  users,
  defaultCustomerOptions,
}: IPreparePicturesFilters): IFilter[] => {
  let filters: IFilter[] = [];

  let teamsFilter = teams;

  if (!_.isEmpty(params[FILTER_TAG.TEAMS])) {
    teamsFilter = _.filter(teams, (team) =>
      _.includes(params[FILTER_TAG.TEAMS], team.id)
    );
  }

  filters.push(
    buildDateFilter("Date", FILTER_TAG.DATE, DEFAULT_DATE_FILTER.LAST_30_DAYS)
  );

  const teamHierarchy = buildTeamsAndHierarchyFilter(
    teams,
    client,
    params[FILTER_TAG.TEAMS],
    params[FILTER_TAG.HIERARCHY]
  );

  if (!isEmptyValue(teamHierarchy)) {
    filters = _.concat(filters, teamHierarchy);
  }

  if (!_.isEmpty(params[FILTER_TAG.HIERARCHY])) {
    teamsFilter = _.filter(teamsFilter, (team) => {
      const part = _.pickBy(team, (value, key) => {
        return key.startsWith("level_") && value !== null;
      });
      const value = _.flatMap(_.values(part));
      return _.intersection(value, params[FILTER_TAG.HIERARCHY]).length > 0;
    });
  }

  filters.push(buildUsersFilter(users, lang, teamsFilter));

  //Activity && workflows filters
  filters.push(buildWorkflowsFilter(workflows, lang));
  if (params[FILTER_TAG.WORKFLOWS]) {
    let workflowsActivities: IActivity[] = [];
    workflows
      .filter((w) => params[FILTER_TAG.WORKFLOWS].includes(w.id))
      .forEach((w) => {
        const workflowActivities = activities.filter((a) =>
          w.steps.map((s) => s.schema_id).includes(a.id)
        );
        workflowsActivities = _.concat(workflowsActivities, workflowActivities);
      });

    filters.push(buildActivitiesFilter(workflowsActivities, lang));

    if (params[FILTER_TAG.ACTIVITIES]) {
      const questionTagFilter = buildQuestionTagFilter(
        workflowsActivities,
        params[FILTER_TAG.ACTIVITIES]
      );
      if (!_.isEmpty(questionTagFilter?.options)) {
        filters.push(questionTagFilter);
      }
    }
  }
  //List filters
  filters.push(buildListsFilter(lists, lang));
  if (params[FILTER_TAG.LISTS]) {
    const attrFilterTag = buildAttributesTagFilter(
      lists,
      params[FILTER_TAG.LISTS]
    );
    if (!_.isEmpty(attrFilterTag?.options)) {
      filters.push(attrFilterTag);
    }
  }

  filters.push(
    buildDetectionsFilter({
      client,
      lists,
    })
  );
  const customerList = lists.find((l) => l.list_type === LIST_TYPE.CUSTOMER);
  if (customerList) {
    filters.push({
      label: "Customer",
      tag: "item_ids",
      type: FILTER_TYPE.MULTIPLE_CHOICE_ON_LIST,
      listId: customerList.id,
      value: params.item_ids,
      options: defaultCustomerOptions,
      isSecondaryFilter: false,
    });
  }
  filters.push({
    label: "Activity report Id",
    tag: "activity_report_id",
    type: FILTER_TYPE.TEXT,
    value: params.activity_report_id,
    isSecondaryFilter: true,
  });
  filters.push({
    label: "Workflow report Id",
    tag: "workflow_report_id",
    type: FILTER_TYPE.TEXT,
    value: params.workflow_report_id,
    isSecondaryFilter: true,
  });
  filters.push({
    label: "Id",
    tag: "picture_id",
    type: FILTER_TYPE.TEXT,
    value: params.picture_id,
    isSecondaryFilter: true,
  });
  // picture_url filter
  filters.push({
    label: "Url",
    tag: "picture_url",
    type: FILTER_TYPE.TEXT,
    value: params.picture_url,
    isSecondaryFilter: true,
  });
  filters.push(
    buildMoreFilter(
      _.uniqBy(
        filters.map((filter) => ({
          key: filter.tag,
          label: filter.label,
        })),
        "key"
      )
    )
  );

  return filters.map((f) => {
    if (params[f.tag]) {
      f.value = params[f.tag];
    }
    if (f.tag === FILTER_TAG.MORE_FILTER) {
      f.options = _.filter(f.options, (option) => !isDisplayed(option.key, f));
    }
    return f;
  });
};

export interface IBuildDetectionsFilter {
  client: IClient;
  lists: IList[];
}
export const buildDetectionsFilter = ({
  client,
  lists,
}: IBuildDetectionsFilter) => {
  const detectableLists = lists.filter((list) =>
    client.detectable_lists?.includes(list.id)
  );
  const codes = _.flatten(
    _.map(detectableLists, (list) => {
      const codeTag = list.id === "sku" ? "_code" : "code";
      return _.compact(
        _.map(list.items, (item) => {
          if (item[codeTag]) {
            return {
              key: item[codeTag],
              label: item["_name"] || item["name"] || item[codeTag],
            };
          }
        })
      );
    })
  );
  return buildMultipleChoiceFilter(
    codes,
    "Items detected",
    FILTER_TAG.ITEMS_DETECTED,
    [],
    true
  );
};

const buildQuestionTagFilter = (
  activities: IActivity[],
  activityIds: string[]
) => {
  return buildMultipleChoiceFilter(
    activityIds.reduce((acc: IOption[], wid) => {
      const activity = activities.find((w) => w.id === wid);
      if (activity) {
        const pictureQuestions = activity.questionnaire.questions.filter(
          (q) => q.type === CUSTOM_FIELD_TYPE.PICTURE && !q.is_hidden
        );
        activity.questionnaire.questions
          .filter((q) => q.type === CUSTOM_FIELD_TYPE.MATRIX_ON_LIST)
          .forEach((q) => {
            q.matrix?.typed_questions.forEach((mq) => {
              if (mq.type === CUSTOM_FIELD_TYPE.PICTURE && !mq.is_hidden) {
                pictureQuestions.push(mq);
              }
            });
          });
        acc = acc.concat(
          pictureQuestions.map((q) => ({
            key: q.tag,
            label: q.question_text,
          }))
        );
      }
      return acc;
    }, []),
    "Picture questions",
    "picture_tags"
  );
};

const buildAttributesTagFilter = (lists: IList[], listIds: string[]) => {
  return buildMultipleChoiceFilter(
    listIds.reduce((acc: IOption[], lid) => {
      const list = _.find(lists, (l) => l.id === lid);
      if (list) {
        const pictureAttributes = list.schema.filter(
          (q) => q.type === CUSTOM_FIELD_TYPE.PICTURE
        );
        acc = acc.concat(
          pictureAttributes.map((q) => ({
            key: q.column_tag,
            label: q.column_name,
          }))
        );
      }
      return acc;
    }, []),
    "Picture attributes",
    FILTER_TAG.ATTRIBUTE_TAG
  );
};

const buildUsersFilter = (
  users: IUser[],
  lang: TLang,
  teamsFilter?: ITeamSelector[]
): IFilter => {
  let userOptions = users.map((u) => ({
    label: `${u.first_name} ${u.last_name}`,
    key: u.id,
  }));

  if (teamsFilter) {
    userOptions = applyFilterDependenciesOnUsers(userOptions, teamsFilter);
  }

  return buildMultipleChoiceFilter(
    userOptions,
    lang.mainLayout.sidebar.users,
    FILTER_TAG.USERS,
    []
  );
};

const buildActivitiesFilter = (
  activities: { id: string; name: string }[],
  lang: TLang
): IFilter => {
  const activitiesOptions = activities.map((w) => ({
    label: w.name,
    key: w.id,
  }));
  return buildMultipleChoiceFilter(
    activitiesOptions,
    lang.mainLayout.sidebar.activities,
    FILTER_TAG.ACTIVITIES
  );
};
const buildWorkflowsFilter = (
  workflows: { id: string; name: string }[],
  lang: TLang
): IFilter => {
  const workflowOptions = workflows.map((w) => ({
    label: w.name,
    key: w.id,
  }));
  return buildMultipleChoiceFilter(
    workflowOptions,
    lang.mainLayout.sidebar.workflows,
    FILTER_TAG.WORKFLOWS,
    []
  );
};

export const buildListsFilter = (lists: IList[], lang: TLang): IFilter => {
  const listsOptions = lists.map((l) => ({
    label: l.name,
    key: l.id,
  }));
  return buildMultipleChoiceFilter(
    listsOptions,
    lang.mainLayout.sidebar.lists,
    FILTER_TAG.LISTS,
    [],
    true
  );
};
