export default (theme: any) => ({
  root: {
    color: "inherit",
    "&$checked": {
      color: "inherit",
    },
    padding: 0,
    width: "24px",
    height: "24px",
    fontSize: "24px",
  },
  checked: {},
  colorSwitchBase: {
    "&$colorChecked": {
      color: theme.palette.primary.main,
      "& + $colorBar": {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  colorBar: {
    color: "#333",
  },
  colorChecked: {},
});
