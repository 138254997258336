import React from "react";

import _ from "lodash";

import { FILTER_TAG } from "components/Filter/TypeFilter";
import { ITeamSelector } from "model/entities/Team";

import {
  IHierarchyDependency,
  IMetaHierarchyDependency,
  IMetaLevelType,
} from "../../../../model/entities/HierarchyDependency";

export interface Node {
  label: React.ReactNode;
  value: string;
  children?: Array<Node>;
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  showCheckbox?: boolean;
  title?: string;
}

/**
 * This function is responsible for converting the actual IHierarchyDependency type to the type
 * of the CheckboxTree component
 * @param level: number The level of the root, it will be always  zero
 * @param hierarchy: IHierarchyDependency[] A IHierarchyDependency
 * @param nodeFather: string | undefined Start with
 * undefined because the root do not have parent and will be actualized on each recursion
 */
// @ts-ignore
export const convertHierarchyTypes = (
  hierarchy: IHierarchyDependency[],
  metaHierarchy: IMetaHierarchyDependency,
  level?: number,
  father?: string
): Node[] => {
  const hierarchyLevel = level ? level : 0;
  const mappedHierarchy: Node[] = [];

  const levelTagRelatedToTheLevel: string | undefined = Object.keys(
    metaHierarchy
  ).find((tag) => {
    return metaHierarchy[tag]?.level_type_number === hierarchyLevel;
  });

  const levelTagRelatedToTheLevelPlus1: string | undefined = Object.keys(
    metaHierarchy
  ).find((tag) => {
    return metaHierarchy[tag]?.level_type_number === hierarchyLevel + 1;
  });

  const filteredHierarchy = hierarchy
    .filter((h) => {
      return (
        h.level_type_number ===
        metaHierarchy[levelTagRelatedToTheLevel ?? ""].level_type_number
      );
    })
    .filter((h) => {
      return !father || h.level_father === father;
    });

  for (const h of filteredHierarchy) {
    if (levelTagRelatedToTheLevelPlus1) {
      // if the level has some kids
      const newHierarchy: Node = {
        value: h.level_name.toLowerCase().replace(/\s/g, ""),
        label: h.level_name,
        children: convertHierarchyTypes(
          hierarchy,
          metaHierarchy,
          hierarchyLevel + 1,
          h.level_name
        ),
      };

      if (newHierarchy?.children?.length === 0) {
        delete newHierarchy.children;
      }

      mappedHierarchy.push(newHierarchy);
    } else {
      // if the level does not have any kids
      mappedHierarchy.push({
        value: h.level_name,
        label: h.level_name,
      });
    }
  }
  return mappedHierarchy;
};

/**
 * This function will return all the hierarchy that have to be expanded to show
 * the nodes that match a given keyword
 * @param nodes: any[] Is a node list. On this case any is the object
 * IHierarchyDependency converted to CheckboxTree format. You can see the format on the utils.test.ts file on this directory
 * @param keyword: string Is a string that will have the keyword searched
 */
export const findExpandedHierarchy = (nodes: any[], keyword: string) => {
  const searchedNodes = [];
  for (const n of nodes) {
    if (n.children) {
      const nextSearchedNodes = findExpandedHierarchy(n.children, keyword);
      if (nextSearchedNodes.length > 0) {
        n.children = nextSearchedNodes;
      } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
        if (nextSearchedNodes.length > 0) {
          n.children = nextSearchedNodes;
        }
      }
      if (
        nextSearchedNodes.length > 0 ||
        n.label.toLowerCase().includes(keyword.toLowerCase())
      ) {
        searchedNodes.push(n);
      }
    } else {
      if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
        searchedNodes.push(n);
      }
    }
  }
  return searchedNodes;
};
/**
 * This function get all labels of a converted IHierarchyDependency to the format demanded by the CheckboxTree
 * @param nodes: any[] Is a node list. On this case any is the object
 * IHierarchyDependency converted to CheckboxTree format. You can see the format on the utils.test.ts file on this directory
 * @param values: string[] : Start empty and in the end will have all the values
 */
export const getAllValues = (nodes: any[], values: string[]) => {
  for (const n of nodes) {
    if (n.children) {
      values.push(n.value);
      getAllValues(n.children, values);
    } else values.push(n.value);
  }
  return _.compact(values);
};

/**
 * This function is responsible to make the link between hierarchy and level filters when a hierarchy is selected
 * @param hierarchyFilterSelectedValues string[] Is the array of checked hierarchies on the hierarchy filter
 * @param allTeamsObject ITeamSelector[] Is the object that have all teams of the selected client
 * @param metaHierarchy
 */
export const getTeamsFromLabels = (
  hierarchyFilterSelectedValues: string[],
  allTeamsObject: ITeamSelector[],
  metaHierarchy: IMetaHierarchyDependency
) => {
  return _(allTeamsObject)
    .filter((t) => {
      let result = false;
      Object.keys(metaHierarchy).forEach((key) => {
        if (metaHierarchy[key].level_type_number === -1) {
          if (hierarchyFilterSelectedValues.includes(t[key])) {
            result = true;
          }
        }
      });
      return result;
    })
    .map((t) => t.id)
    .compact()
    .value();
};

/**
 * This function is responsible to make the link between hierarchy and level filters when a hierarchy is selected
 * @param hierarchyFilterSelectedValues string[] Is the array of checked hierarchies on the hierarchy filter
 * @param allTeamsObject ITeamSelector[] Is the object that have all teams of the selected client
 * @param metaHierarchy
 */
export const getTeamsFromLabel = (
  hierarchyFilterSelectedValues: string[],
  allTeamsObject: ITeamSelector[],
  metaHierarchy: IMetaHierarchyDependency
) => {
  return _(allTeamsObject)
    .filter((t) => {
      let result = false;
      Object.keys(metaHierarchy).forEach((key) => {
        if (hierarchyFilterSelectedValues?.includes(t[key])) {
          result = true;
        }
      });
      return result;
    })
    .map((t) => t.id)
    .compact()
    .value();
};

/**
 * This function is responsible to make the link between hierarchy and level filters when a hierarchy is selected
 * @param hierarchyFilterSelectedValues string[] Is the array of checked hierarchies on the hierarchy filter
 * @param allTeamsObject ITeamSelector[] Is the object that have all teams of the selected client
 * @param metaHierarchy
 */
export const getTeamsFromHierarchy = (
  hierarchyFilterSelectedValues: string[],
  allTeamsObject: ITeamSelector[],
  metaHierarchy: IMetaHierarchyDependency
) => {
  const cleanhierarchyFilterSelectedValues = _.compact(
    hierarchyFilterSelectedValues
  );
  const teamsFiltered = _.filter(allTeamsObject, (t) => {
    let result = false;
    const metaHierarchyKeys = _.keys(metaHierarchy);
    for (const key of metaHierarchyKeys) {
      if (
        metaHierarchy[key].level_type_number >= 0 &&
        _.includes(cleanhierarchyFilterSelectedValues, t[key])
      ) {
        result = true;
      }
    }
    return result;
  });

  const teamsIds = _.compact(_.map(teamsFiltered, "id"));
  return _.values(teamsIds);
};

/**
 * This function is responsible for linking hierarchy and teams filter when team filter is selected
 * @param teamFilterSelectedValues string[] Is the array os team id's that were selected on the filter
 * @param allTeamsObject ITeamSelector[] Is the object that have all teams of the selected client
 * @param metaHierarchy
 */
export const getLabelsFromTeams = (
  teamFilterSelectedValues: string[] | undefined,
  allTeamsObject: ITeamSelector[],
  metaHierarchy: IMetaHierarchyDependency
) => {
  const hierarchyToCheck = {};
  const selectedTeams: ITeamSelector[] = allTeamsObject.filter(
    (team) =>
      !teamFilterSelectedValues || teamFilterSelectedValues.includes(team.id)
  );
  for (const t of selectedTeams) {
    Object.keys(metaHierarchy).forEach((tag) => {
      if (
        metaHierarchy[tag].level_type_number === -1 &&
        t[tag] &&
        t[tag].length > 0
      ) {
        hierarchyToCheck[t[tag]] = true;
      }
    });
  }
  return _.compact(_.keys(hierarchyToCheck));
};
export const getLabelsFromHierarchy = (
  teamFilterSelectedValues: string[] | undefined,
  allTeamsObject: ITeamSelector[],
  metaHierarchy: IMetaHierarchyDependency,
  metaLevelType: IMetaLevelType
) => {
  const hierarchyToCheck = {};
  const selectedTeams: ITeamSelector[] = allTeamsObject.filter(
    (team) =>
      !teamFilterSelectedValues || teamFilterSelectedValues.includes(team.id)
  );
  for (const t of selectedTeams) {
    Object.keys(metaHierarchy).forEach((tag) => {
      if (
        metaHierarchy[tag].level_type_number === -1 &&
        metaHierarchy[tag].level_type_name === metaLevelType.level_type_name &&
        t[tag] &&
        t[tag].length > 0
      ) {
        hierarchyToCheck[t[tag]] = true;
      }
    });
  }
  return _.compact(_.keys(hierarchyToCheck));
};

/**
 * This function is responsible for linking hierarchy and teams filter when team filter is selected
 * @param teamFilterSelectedValues string[] Is the array os team id's that were selected on the filter
 * @param allTeamsObject ITeamSelector[] Is the object that have all teams of the selected client
 * @param metaHierarchy
 */
export const getHierarchyFromTeams = (
  teamFilterSelectedValues: string[] | undefined,
  allTeamsObject: ITeamSelector[],
  metaHierarchy: IMetaHierarchyDependency
) => {
  const hierarchyToCheck = {};
  const selectedTeams: ITeamSelector[] = allTeamsObject.filter(
    (team) =>
      !teamFilterSelectedValues || teamFilterSelectedValues.includes(team.id)
  );

  for (const t of selectedTeams) {
    _.keys(metaHierarchy).forEach((tag) => {
      if (metaHierarchy[tag].level_type_number >= 0 && t[tag]) {
        hierarchyToCheck[t[tag]] = true;
      }
    });
  }

  return _.compact(_.keys(hierarchyToCheck));
};

/**
 * This function is responsible to make the link between hierarchy and level filters when a hierarchy is selected
 * @param hierarchyFilterSelectedValues string[] Is the array of checked hierarchies on the hierarchy filter
 * @param allTeamsObject ITeamSelector[] Is the object that have all teams of the selected client
 * @param metaHierarchy
 * @param labelPerTeamTag
 */
export const getTeamsFromLabelPerTeam = (
  hierarchyFilterSelectedValues: string[],
  allTeamsObject: ITeamSelector[],
  metaHierarchy: IMetaHierarchyDependency,
  labelPerTeamTag: string
) => {
  return _(allTeamsObject)
    .filter((t) => {
      let result = false;
      Object.keys(metaHierarchy).forEach((key) => {
        if (
          labelPerTeamTag ===
            FILTER_TAG.LABELS.concat(
              "_",
              metaHierarchy[key].level_type_name
                .toLowerCase()
                .replace(/ /g, "_")
            ) &&
          metaHierarchy[key].level_type_number === -1
        ) {
          if (
            hierarchyFilterSelectedValues &&
            hierarchyFilterSelectedValues.includes(t[key])
          ) {
            result = true;
          }
        }
      });
      return result;
    })
    .map((t) => t.id)
    .compact()
    .value();
};
/**
 * This function is responsible for linking hierarchy and teams filter when team filter is selected
 * @param teamFilterSelectedValues string[] Is the array os team id's that were selected on the filter
 * @param allTeamsObject ITeamSelector[] Is the object that have all teams of the selected client
 * @param metaHierarchy
 * @param labelPerTeamTag
 */
export const getLabelPerTeamFromTeams = (
  teamFilterSelectedValues: string[] | undefined,
  allTeamsObject: ITeamSelector[],
  metaHierarchy: IMetaHierarchyDependency,
  labelPerTeamTag: string
) => {
  const hierarchyToCheck = {};
  const selectedTeams: ITeamSelector[] = allTeamsObject.filter(
    (team) =>
      !teamFilterSelectedValues || teamFilterSelectedValues.includes(team.id)
  );
  for (const t of selectedTeams) {
    Object.keys(metaHierarchy).forEach((tag) => {
      if (
        labelPerTeamTag ===
          FILTER_TAG.LABELS.concat(
            "_",
            metaHierarchy[tag].level_type_name.toLowerCase().replace(/ /g, "_")
          ) &&
        metaHierarchy[tag].level_type_number === -1 &&
        t[tag] &&
        t[tag].length > 0
      ) {
        hierarchyToCheck[t[tag]] = true;
      }
    });
  }
  return _.compact(_.keys(hierarchyToCheck));
};

export const checkIfHierarchyIsOk = (node: Node[]) => {
  // FIXME: make this function recursive. Not the time to do it right now
  const level1 = {};
  const level2 = {};
  const level3 = {};
  const level4 = {};
  let result = true;
  node.forEach((n) => {
    if (level1[n.value]) result = false;
    level1[n.value] = true;
    if (n.children) {
      n.children.forEach((n2) => {
        if (level2[n2.value]) result = false;
        level2[n2.value] = true;
        if (n2.children) {
          n2.children.forEach((n3) => {
            if (level3[n3.value]) result = false;
            level3[n3.value] = true;
            if (n3.children) {
              n3.children.forEach((n4) => {
                if (level4[n4.value]) result = false;
                level4[n4.value] = true;
              });
            }
          });
        }
      });
    }
  });
  const all = {};
  for (const i of [
    ...Object.keys(level1),
    ...Object.keys(level2),
    ...Object.keys(level3),
    ...Object.keys(level4),
  ] as string[]) {
    if (all[i]) result = false;
    all[i] = true;
  }

  return result;
};

/**
 * This function is responsible for linking hierarchy, labels and teams filter when owners filter is selected
 * @param teamsIds string[] Is the array of teams' ids that were deducted from the filters
 * @param allTeamsObject ITeamSelector[] Is the object that have all teams of the selected client
 */
export const getMobileUsersFromTeamsIds = (
  teamsIds: string[],
  allTeamsObject: ITeamSelector[]
) => {
  return allTeamsObject
    .filter((t) => teamsIds && teamsIds.includes(t.id))
    .reduce((acc, curr) => {
      return acc.concat(curr.mobile_users.map((u) => u.key));
    }, [] as string[]);
};
