import { Box, InputLabel } from "@material-ui/core";
import _ from "lodash";

import { GreyDark } from "assets/colors";

interface IMetadata {
  key: string;
  label: string;
  value: string;
}
export interface IMetadataDataSection {
  icon?: React.ReactNode;
  metadata: IMetadata[];

  titleProps?: React.ComponentProps<typeof Box>;
  descriptionProps?: React.ComponentProps<typeof Box>;
  containerProps?: React.ComponentProps<typeof Box>;
  iconContainerProps?: React.ComponentProps<typeof Box>;
}

export function MetaDataSection({ icon, metadata }: IMetadataDataSection) {
  return (
    <Box
      display={"grid"}
      gridTemplateColumns={"auto 1fr"}
      gridColumnGap={"8px"}
      color={GreyDark}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        {icon && (
          <Box display={"flex"} alignItems={"center"}>
            {icon}
          </Box>
        )}
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {_.map(metadata, (data) => {
          const { key, label, value } = data;
          return (
            <Box
              display={"grid"}
              gridTemplateRows={"auto 1fr"}
              alignItems={"start"}
              key={key}
            >
              <Box>
                <InputLabel
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {label}:
                </InputLabel>
              </Box>

              <Box>
                <Box
                  fontSize={"16px"}
                  width={"250px"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                >
                  {value}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
