import { Box, MenuItem } from "@material-ui/core";
import { CalendarEventStatus, TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";

import MenuButton from "components/Buttons/MenuButton";
import { IMenuButton } from "components/Buttons/MenuButton/MenuButton";
import { useTranslations } from "hooks";

import { getEventFillColor, getEventStatusColor } from "../Calendar/BoardItem";

interface IEventStatusMenuButtonProps {
  event: TCalendarEvent;
  onChangeStatus?: (
    events: TCalendarEvent[],
    status: CalendarEventStatus
  ) => void;
}
const EventStatusMenuButton: React.FC<IEventStatusMenuButtonProps> = ({
  event,
  onChangeStatus,
}) => {
  const lang = useTranslations();
  const langKey =
    lang.containers.calendar.subCategories.calendar.createEditModal.inputStatus
      .options;

  const getOptions = (currentStatus?: CalendarEventStatus) => {
    const viableStatuses = [
      CalendarEventStatus.PENDING,
      CalendarEventStatus.PLANNED,
      CalendarEventStatus.DECLINED,
      CalendarEventStatus.OUTDATED,
    ].filter((status) => status !== currentStatus);

    return _.map(viableStatuses, (status) => ({
      label: _.upperFirst(_.lowerCase(langKey[status])),
      onClick: () => {
        onChangeStatus?.([event], status);
      },
      key: status,
    }));
  };

  const buildMenuItemFunction: IMenuButton["buildMenuItemFunction"] = (
    option,
    { handleCloseHandler }
  ) => {
    function onClick(e: any) {
      if ("onClick" in option && option.onClick !== undefined) {
        option.onClick(e);
      }
      handleCloseHandler?.();
    }
    return (
      <MenuItem onClick={onClick} key={`${event.id}-${option.key}`}>
        <Box
          fontSize={"12px"}
          style={{
            backgroundColor: getEventFillColor(
              option.key as CalendarEventStatus
            ),
            color: getEventStatusColor(option.key as CalendarEventStatus),
          }}
          padding={"0px 8px"}
          borderRadius={"4px"}
        >
          {option.label}
        </Box>
      </MenuItem>
    );
  };

  return (
    <MenuButton
      key={event.id}
      options={getOptions(event.status)}
      disabled={event.status === CalendarEventStatus.COMPLETED}
      label={_.upperFirst(
        _.lowerCase(langKey[event.status ?? CalendarEventStatus.PENDING])
      )}
      buttonProps={{
        style: {
          height: "30px",
          color: getEventStatusColor(event.status),
          backgroundColor: getEventFillColor(event.status),
          fontSize: "12px",
        },
      }}
      buildMenuItemFunction={buildMenuItemFunction}
    />
  );
};

export default EventStatusMenuButton;
