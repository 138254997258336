import React, { useEffect, useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import { BasicDialog, BasicDialogContent } from "components/Dialog/BasicDialog";
import { allListsSelector } from "containers/lists/redux/selectors";
import IPictureInformation from "containers/pictures/model/IPictureInformation";
import IPicture from "model/entities/Picture";

import PictureDetectionView from "./PictureDetectionView";
import PictureDetectionViewLayout from "./PictureDetectionViewLayout";
import PictureSideBar from "./PictureSideBar";
import PictureSidebarHeader from "./PictureSideBar/PictureSidebarHeader";
import { IImageDetectionDataFE } from "./types";
import { fetchPictureDetections, getIdsFromDetections } from "./utils";

export interface IPicturesCarouselModal {
  showGallery: boolean;
  handleClose: () => void;
  initialSlide: number;
  pictures: IPicture[];
  getPictureInformations: (picture: IPicture) => IPictureInformation;
  setPictureByListId?: (
    value: React.SetStateAction<IPicture | undefined>
  ) => void;
}

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    padding: "16px 24px",
    width: "100%",
    height: "100%",
  },
  leftContainer: {
    width: "65%",
    position: "relative",
    borderRadius: "8px",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  rightContainer: {
    width: "35%",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    overflow: "auto",
  },
});

const PicturesCarouselModal: React.FunctionComponent<
  IPicturesCarouselModal
> = ({
  showGallery = true,
  handleClose,
  initialSlide = 0,
  pictures,
  getPictureInformations,
  setPictureByListId,
}) => {
  const classes = useStyle();
  const allLists = useSelector(allListsSelector);
  const [currentIndex, setCurrentIndex] = useState(initialSlide);
  const [slideLists, setSlideLists] = useState(pictures);
  const [currentPicture, setCurrentPicture] = useState(
    getPictureInformations(pictures[initialSlide])
  );

  const [displayDetections, setDisplayDetections] = useState(false);
  const [loadingDetections, setLoadingDetections] = useState(true);
  const [allDetections, setDetections] = useState<IImageDetectionDataFE[]>([]);
  const [activeDetectionBoxIds, setActiveDetectionBoxIds] = useState<
    string[] | undefined
  >(undefined);
  const [hoveredDetectionBoxIds, setHoveredDetectionBoxIds] = useState<
    string[] | undefined
  >(undefined);

  function handleChangeDisplayDetections(value: boolean) {
    setDisplayDetections(value);
  }

  function handleClickArrowRight() {
    if (currentIndex < slideLists.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
    if (currentIndex === slideLists.length - 1) {
      setCurrentIndex(0);
    }
  }

  function handleClickArrowLeft() {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
    if (currentIndex === 0) {
      setCurrentIndex(slideLists.length - 1);
    }
  }

  useEffect(() => {
    if (slideLists[currentIndex]["list_id"] && setPictureByListId) {
      setPictureByListId(slideLists[currentIndex]);
    }
    setCurrentPicture(getPictureInformations(slideLists[currentIndex]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  useEffect(() => {
    const newSlideLists = pictures
      .slice(initialSlide)
      .concat(pictures.slice(0, initialSlide));
    setSlideLists(newSlideLists);
  }, [initialSlide, pictures]);

  //load the detections for the current picture
  useEffect(() => {
    (async () => {
      setLoadingDetections(true);
      const detectionData = await fetchPictureDetections({
        id: currentPicture.id || "_",
      });
      setLoadingDetections(false);
      setDetections(detectionData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLists, currentPicture]);

  const allBoxesHighlighted = getIdsFromDetections(allDetections);
  const boxesToHighlight =
    activeDetectionBoxIds === undefined
      ? allBoxesHighlighted
      : _.uniq(_.concat(activeDetectionBoxIds, hoveredDetectionBoxIds ?? []));

  return (
    <BasicDialog open={showGallery} minWidth={"1178px"} borderRadius={"8px"}>
      <BasicDialogContent className={classes.root}>
        <Box className={classes.leftContainer}>
          <PictureDetectionViewLayout
            onNextLoad={handleClickArrowRight}
            onPrevious={handleClickArrowLeft}
          >
            <PictureDetectionView
              key={allDetections.length}
              activeDetectionBoxIds={boxesToHighlight}
              displayDetections={displayDetections}
              detections={allDetections}
              picture={currentPicture}
              imgProps={{
                height: "auto",
                width: "100%",
                style: {
                  objectFit: "contain",
                  maxWidth: "100%",
                  maxHeight: "calc(82vh - 32px)",
                  borderRadius: "8px",
                },
              }}
              onClick={(boxId) => {
                if (_.isEqual([boxId], activeDetectionBoxIds)) {
                  setActiveDetectionBoxIds(undefined);
                } else {
                  setActiveDetectionBoxIds([boxId]);
                }
              }}
            />
          </PictureDetectionViewLayout>
        </Box>
        <Box className={classes.rightContainer}>
          <PictureSidebarHeader handleClose={handleClose} />
          <PictureSideBar
            isLoadingScanResults={loadingDetections}
            toggleDisplayDetections={handleChangeDisplayDetections}
            picture={pictures[currentIndex]}
            detections={allDetections}
            activeBoxIds={activeDetectionBoxIds}
            onClickScanResult={(boxIds) => {
              if (_.isEqual(boxIds, activeDetectionBoxIds)) {
                setActiveDetectionBoxIds(undefined);
              } else {
                setActiveDetectionBoxIds(boxIds);
              }
            }}
            onHoverScanResult={(boxIds) => {
              setHoveredDetectionBoxIds(boxIds);
            }}
          />
        </Box>
      </BasicDialogContent>
    </BasicDialog>
  );
};

export default PicturesCarouselModal;
