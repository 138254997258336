import { Dispatch, ReactNode } from "react";

import { Box } from "@material-ui/core";

import CustomDialogWarning from "components/Dialog/CustomDialogWarning";
import CustomSnackbar, {
  TSnackbarType,
} from "components/Snackbars/CustomSnackbar";
import SnackbarMessage from "components/Snackbars/SnackbarMessage";
import { useTranslations } from "hooks";
import { formatString } from "lang/utils";
import IPicture from "model/entities/Picture";

import PicturesCarouselModal from "./components/modals/PicturesCarouselModal";
import IPictureInformation from "./model/IPictureInformation";
import { IPictureWithIndex } from "./utils";

interface IPrimaryTableProps {
  children: ReactNode | ReactNode[];

  //SNACKBAR
  isLoadingSnackMessage?: boolean;
  snackBarInfo?: {
    message: string;
    type: TSnackbarType;
  } | null;
  setSnackbarInfo: Dispatch<
    React.SetStateAction<{
      message: string;
      type: TSnackbarType;
    } | null>
  >;

  // PICTURE DOWNLOAD
  totalPictures: number;
  isDownloading?: boolean;
  handleCloseDownload: () => void;
  handleConfirmDownload: () => void;

  // READ PICTURE
  openImageModal?: boolean;
  handleCloseImageModal: () => void;
  currentImageIndex: number;
  picturesWithUniqueId: IPictureWithIndex[];
  setCurrentPictureByListId: Dispatch<
    React.SetStateAction<IPicture | undefined>
  >;
  handlePictureInformations: (picture: IPicture) => IPictureInformation;
}
const PrimaryTableLayout = ({
  children,
  isLoadingSnackMessage,
  snackBarInfo: snackbar,
  setSnackbarInfo: setSnackbar,

  isDownloading,
  totalPictures,
  handleCloseDownload,
  handleConfirmDownload,

  openImageModal,
  handleCloseImageModal,
  currentImageIndex,
  picturesWithUniqueId,
  setCurrentPictureByListId,
  handlePictureInformations,
}: IPrimaryTableProps) => {
  const lang = useTranslations();

  return (
    <>
      <Box>{children}</Box>

      {openImageModal && !isLoadingSnackMessage && (
        <PicturesCarouselModal
          showGallery={openImageModal}
          handleClose={handleCloseImageModal}
          initialSlide={currentImageIndex}
          pictures={picturesWithUniqueId}
          setPictureByListId={setCurrentPictureByListId}
          getPictureInformations={handlePictureInformations}
        />
      )}

      {isDownloading ? (
        <CustomDialogWarning
          isOpen={isDownloading}
          onClose={handleCloseDownload}
          onConfirmAction={handleConfirmDownload}
          singleButton={false}
          rootLang={lang}
          lang={{
            ...lang.containers.pictures.subCategories.pictures.downloadModal,
            description: formatString(
              lang.containers.pictures.subCategories.pictures.downloadModal
                .description,
              [totalPictures > 1000 ? 1000 : totalPictures]
            ),
          }}
        />
      ) : null}

      <SnackbarMessage
        isFetchActionOngoing={isLoadingSnackMessage}
        lang={lang}
      />

      {snackbar && snackbar !== null && (
        <CustomSnackbar
          message={snackbar.message}
          type={snackbar.type}
          onClose={() => setSnackbar(null)}
        />
      )}
    </>
  );
};

export default PrimaryTableLayout;
