import { useContext } from "react";

import { Box, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import LinkIcon from "@mui/icons-material/Link";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import StorefrontIcon from "@mui/icons-material/Storefront";
import _ from "lodash";

import MetaDataSection from "components/Layout/MetaDataSection";
import CustomSpinner from "components/Progress/CustomSpinner";
import { TSnackbarType } from "components/Snackbars/CustomSnackbar";
import IPictureInformation from "containers/pictures/model/IPictureInformation";
import { PictureContext } from "containers/pictures/PrimaryTable";
import useTranslations from "hooks/useTranslations";

import PictureName from "./PictureName";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflowY: "auto",
    width: "100%",
  },

  title: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  iconBox: {
    display: "grid",
    gridAutoFlow: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  detailsBox: {
    display: "grid",
    gridRowGap: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },

  spinnerContainer: {
    width: "100%",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type TDetails = IPictureInformation | {};
export interface IPictureDetailsProps {
  loading: boolean;
  details: TDetails;
}

function PictureDetails({ loading, details }: IPictureDetailsProps) {
  const classes = useStyles();
  const lang = useTranslations();
  const langKey =
    lang.containers.lists.subCategories.lists.createEditModal.inputAttribute
      .templates.customerAttributes;

  const modalLang =
    lang.containers.pictures.subCategories.pictures.createEditModal;

  const reportsLang = lang.containers.workflows.subCategories.activityReports;
  const { setSnackbar } = useContext(PictureContext);

  const handleCopy = (message: string, type: TSnackbarType) => {
    setSnackbar?.({ message, type });
  };

  if (loading) {
    return (
      <Box className={classes.spinnerContainer}>
        <CustomSpinner size={"50px"} />
      </Box>
    );
  }

  const filename = _.last(_.split(details["url"], "/"));

  return (
    <Fade in={!loading}>
      <Box className={classes.root} data-testid="picture-details">
        <PictureName name={filename} onCopy={handleCopy} />

        <Box className={classes.detailsBox}>
          {details["user_id"] && (
            <MetaDataSection
              icon={<PersonOutlineOutlinedIcon />}
              metadata={[
                {
                  key: "userName",
                  label: reportsLang.columns.userName,
                  value: details["userName"],
                },
                {
                  key: "user_id",
                  label: reportsLang.columns.userId,
                  value: details["user_id"],
                },
              ]}
            />
          )}

          {details["customerId"] && (
            <MetaDataSection
              icon={<StorefrontIcon />}
              metadata={[
                {
                  key: "customerId",
                  label: modalLang.pictureCustomerName.title,
                  value: details["customerId"],
                },
                {
                  key: "item_id",
                  label: modalLang.pictureCustomerId.title,
                  value: details["item_id"],
                },
              ]}
            />
          )}

          {details["_location"] && details["list_id"] === "customer" && (
            <MetaDataSection
              icon={<span className="material-symbols-outlined">id_card</span>}
              metadata={[
                {
                  key: "_location",
                  label: modalLang.pictureCustomerAddress.title,
                  value: details["_location"],
                },
              ]}
            />
          )}

          {details["timestamp"] && (
            <MetaDataSection
              icon={<CalendarMonth />}
              metadata={[
                {
                  key: "timestamp",
                  label: lang.components.filters.date,
                  value: details["timestamp"],
                },
              ]}
            />
          )}

          {details["type"] && details["list_id"] === "customer" && (
            <MetaDataSection
              icon={<RadioButtonChecked />}
              metadata={[
                {
                  key: "type",
                  label: langKey.type,
                  value: details["type"],
                },
              ]}
            />
          )}

          {details["activity_report_id"] && (
            <MetaDataSection
              icon={<InventoryOutlinedIcon />}
              metadata={[
                {
                  key: "activity_report_id",
                  label: reportsLang.assignToMe.columns.activityReportId,
                  value: details["activity_report_id"],
                },
              ]}
            />
          )}

          {details["workflow_report_id"] && (
            <MetaDataSection
              icon={<DescriptionOutlinedIcon />}
              metadata={[
                {
                  key: "workflow_report_id",
                  label: reportsLang.assignToMe.columns.workflowReportId,
                  value: details["workflow_report_id"],
                },
              ]}
            />
          )}

          {details["questionName"] && details["activity_id"] && (
            <MetaDataSection
              icon={<HelpOutlineIcon />}
              metadata={[
                {
                  key: "questionName",
                  label: modalLang.pictureQuestionName.title,
                  value: details["questionName"],
                },
                {
                  key: "tag",
                  label: modalLang.pictureQuestionTag.title,
                  value: details["tag"],
                },
              ]}
            />
          )}

          {details["url"] && (
            <MetaDataSection
              icon={<LinkIcon />}
              metadata={[
                {
                  key: "url",
                  label: modalLang.url.title,
                  value: details["url"],
                },
              ]}
            />
          )}
        </Box>
      </Box>
    </Fade>
  );
}

export default PictureDetails;
