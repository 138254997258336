import {
  IDestinationColumn,
  ITransformation as IBETransformation,
  TRANSFORMATION_DB_TYPE,
} from "fieldpro-tools/dist/src/types";

import { ITransformation } from "model/entities/Job";

const prepareTransformationForBackend = (
  originalTransformation: ITransformation
): IBETransformation => {
  const transformation: Partial<ITransformation> = JSON.parse(
    JSON.stringify(originalTransformation)
  );
  Object.keys(transformation).forEach((att) => {
    if (transformation[att] === "") delete transformation[att];
  });
  delete transformation.id;
  delete (transformation as any).job_type;
  transformation["destination_columns"] = (
    transformation.destination_columns as any
  )?.map((c: any) => ({
    column_name: c.column_name,
    column_type: c.column_type.toUpperCase(),
  }));
  if (!transformation.destination_columns)
    transformation.destination_columns = [];
  delete transformation["indexes"];
  if (transformation.integrations) {
    transformation.integrations.forEach((int) => delete int["index"]);
  }
  if (!transformation.db_type)
    transformation["db_type"] = TRANSFORMATION_DB_TYPE.POSTGRES;
  return transformation as IBETransformation;
};

const prepareTransformationsForFrontend = (
  originalTransformations: IBETransformation[]
): ITransformation[] => {
  return originalTransformations.map((t: any) => {
    t["destination_columns"] = t.destination_columns?.map(
      (c: IDestinationColumn, index: number) => ({
        ...c,
        column_type: c.column_type.toUpperCase(),
        index,
      })
    );
    delete t.last_update;
    return t;
  });
};

export { prepareTransformationForBackend, prepareTransformationsForFrontend };
