import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

export default (theme: any) => ({
  container: {
    display: "flex",
    //flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 50,
    alignSelf: "center",
  },
  root: {
    display: "flex",
    color: green[600],
    "&.checked": {
      color: green[500],
    },
    checked: {},
    formControl: {
      margin: theme.spacing(3),
    },
    group: {
      margin: `${theme.spacing(1)}px 0`,
    },
  },
  MarkerIcon: {
    color: theme.palette.primary.main,
    width: "35px",
    height: "35px",
    "margin-left": "10px",
    "margin-top": "-10px",
  },
  FlagIconStart: {
    color: green[600],
    width: "35px",
    height: "35px",
    "margin-left": "10px",
    "margin-top": "-10px",
  },
  FlagIconEnd: {
    color: red[900],
    width: "35px",
    height: "35px",
    "margin-left": "10px",
    "margin-top": "-10px",
  },
  MarkerIconRedColor: {
    color: red[600],
  },
  MarkerIconGreenColor: {
    color: green[600],
  },
  MarkerIconSelected: {
    color: theme.palette.secondary.main,
    width: "35px",
    height: "35px",
    "margin-left": "10px",
    "margin-top": "-10px",
  },
  MarkerIconGreyed: {
    color: "grey",
    width: "35px",
    height: "35px",
    "margin-left": "10px",
    "margin-top": "-10px",
  },
  Marker: {
    "margin-left": "-28px",
    "margin-top": "-20px",
  },
  MarkerNoEvents: {
    //pointerEvents: 'none'
  },
  Popup: {
    "margin-top": "-20px",
    "z-index": 1,
  },
  TableChart: {
    overflow: "auto",
    height: "300px",
  },
  TableChartFullWidth: {
    overflow: "auto",
    // height: "calc(100vh - 130px)",
  },
  UserBadge: {
    transform: "scale(1) translate(100%, -100%)",
    //"border-radius": "45%",
    //position: "absolute",
    width: "10px",
    height: "10px",
  },
  PositionBadge: {
    //"border-radius": "45%",
    width: "10px",
    height: "10px",
    border: "solid black 1px",
    "margin-top": "15px",
    "margin-left": "20px",
  },
});
