import React from "react";

import FormFieldBackground from "components/Forms/Form/FormField/FormFieldBackground";
import InputBoolean from "components/Input/InputBoolean";
import InputText from "components/Input/InputText/InputText";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";
import { IWebUser } from "model/entities/User";

import InputAccessRightProfiles from "./InputAccessRightProfiles";
import InputMobileUserRoles from "./InputMobileUserRoles";
import InputWebUserRoles from "./InputWebUserRoles";

export interface IUsersTabProps extends Partial<IClient> {
  viewMode?: TViewMode;
  onSetClientLive: any;
  selectedClientWebUsers: IWebUser[];
  onInputChange: any;
  onChangeProfiles: any;
  onChangeMobileRoles: any;
  onChangeWebRoles: any;
  onSelectChange: any;
  shouldMarkError: any;
}

export const UsersTab: React.FunctionComponent<IUsersTabProps> = ({
  viewMode = "CREATE",
  access_right_profiles,
  mobile_user_business_roles,
  web_user_business_roles,
  unique_log_in,
  phone_regex,
  daily_status_query,
  magic_number,
  selectedClientWebUsers,
  onInputChange,
  onChangeProfiles,
  onSelectChange,
  onChangeMobileRoles,
  onChangeWebRoles,
  shouldMarkError,
}) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  return (
    <>
      <FormFieldBackground viewMode={viewMode}>
        <InputAccessRightProfiles
          viewMode={viewMode}
          profiles={access_right_profiles}
          selectedClientWebUsers={selectedClientWebUsers}
          onChangeProfiles={onChangeProfiles}
        />
      </FormFieldBackground>

      <InputMobileUserRoles
        viewMode={viewMode}
        roles={mobile_user_business_roles ?? []}
        onChangeMobileRoles={onChangeMobileRoles}
      />

      <InputWebUserRoles
        viewMode={viewMode}
        roles={web_user_business_roles ?? []}
        onChangeWebRoles={onChangeWebRoles}
      />

      <InputBoolean
        viewMode={viewMode}
        defaultValue={unique_log_in}
        lang={label.inputUniqueLogin}
        name={"unique_log_in"}
        onChange={onInputChange}
      />

      <FormFieldBackground viewMode={viewMode}>
        <InputText
          viewMode={viewMode}
          defaultValue={phone_regex}
          name="phone_regex"
          lang={label.inputPhoneRegex}
          onChange={onInputChange}
          aria-label="PhoneRegexInputText"
          required={false}
        />
      </FormFieldBackground>

      <FormFieldBackground viewMode={viewMode}>
        <InputText
          viewMode={viewMode}
          defaultValue={daily_status_query}
          name="daily_status_query"
          lang={label.inputDailyStatusQuery}
          onChange={onInputChange}
          aria-label="DailyStatusInputText"
          required={false}
        />
      </FormFieldBackground>

      <InputText
        viewMode={viewMode}
        defaultValue={magic_number}
        name="magic_number"
        error={shouldMarkError("magic_number")}
        lang={label.inputMagicNumber}
        onChange={onSelectChange}
        required={false}
      />
    </>
  );
};

export default UsersTab;
