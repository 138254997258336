import { saveAs } from "file-saver";
import JSZip from "jszip";
// TODO create manually definition type file for jszip-utils
// @ts-ignore
import JSZipUtils from "jszip-utils";
import _ from "lodash";
import moment from "moment";

import { FILTER_TAG } from "components/Filter/TypeFilter";
import { getLocationValue } from "containers/lists/utils";
import IPictureInformation, {
  IPictureInformationForSubmission,
} from "containers/pictures/model/IPictureInformation";
import IPreparedPictureFilterParams from "containers/pictures/model/IPreparedPictureFilterParams";
import { IList } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import IPicture, {
  IPictureFromItems,
  IPictureFromSubmissions,
} from "model/entities/Picture";
import IUser from "model/entities/User";
import { IActivity, IWorkflow } from "model/entities/Workflow";
import { DATE_FORMAT_FULL_DATE } from "utils/constants";
import getFullName from "utils/formatting/getFullName";
import { isEmptyValue } from "utils/isEmptyValue";

import { getTagNameFromActivity } from "./components/getSortedPicturesList";

export const prepareParamsForFetchingPictures = (
  filterParams: any
): IPreparedPictureFilterParams => {
  const preparedFilterParams: IPreparedPictureFilterParams = filterParams[
    FILTER_TAG.DATE
  ]
    ? {
        start_date: filterParams[FILTER_TAG.DATE].startDate,
        end_date: filterParams[FILTER_TAG.DATE].endDate,
      }
    : {
        start_date: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30),
        end_date: new Date(),
      };
  preparedFilterParams.items_detected = filterParams[FILTER_TAG.ITEMS_DETECTED];
  preparedFilterParams.team_ids = filterParams[FILTER_TAG.TEAMS];
  preparedFilterParams.workflow_ids = filterParams[FILTER_TAG.WORKFLOWS];
  preparedFilterParams.activity_ids = filterParams[FILTER_TAG.ACTIVITIES];
  preparedFilterParams.user_ids = filterParams[FILTER_TAG.USERS];
  preparedFilterParams.list_ids = filterParams[FILTER_TAG.LISTS];
  preparedFilterParams.column_tag = filterParams[FILTER_TAG.ATTRIBUTE_TAG];
  preparedFilterParams.item_ids = _.map(filterParams["item_ids"], "key");
  preparedFilterParams.picture_id = filterParams["picture_id"];
  preparedFilterParams.picture_url = filterParams["picture_url"];
  preparedFilterParams.activity_report_id = filterParams["activity_report_id"];
  preparedFilterParams.workflow_report_id = filterParams["workflow_report_id"];
  preparedFilterParams.picture_tags = filterParams["picture_tags"];

  return preparedFilterParams;
};

export const findUserNameFromUsersList = (
  userId: string,
  allUsers: IUser[]
) => {
  const user = allUsers.find((u) => u.id === userId);
  return getFullName({
    first_name: user?.first_name,
    last_name: user?.last_name,
  });
};

export enum PictureActions {
  UploadFileForItem = "UPLOAD_FILE_FOR_ITEM",
  UploadFileForSubmission = "UPLOAD_FILE_FOR_SUBMISSION",
}

interface IProps {
  allUsers: IUser[];
  allLists: IList[];
  allActivities: IActivity[];
  allWorkflows: IWorkflow[];
  list?: IList;
}

type GetPictureInformationsFunc = (
  picture: IPicture,
  props: IProps
) => IPictureInformation;
export const getPictureInformations: GetPictureInformationsFunc = (
  picture,
  props
) => {
  picture = _.omit(picture, ["type"]) as IPicture;
  const { allLists, allActivities, allUsers, list } = props;

  const timestamp = moment(picture.timestamp).format(DATE_FORMAT_FULL_DATE);
  const { action, url } = picture;

  const pictureTyped = picture as IPictureFromItems;
  const pictureList =
    list ||
    _.find(allLists, (l) => l.id === (picture as IPictureFromItems).list_id);

  const item = _.find(pictureList?.items, {
    _id: pictureTyped.item_id,
  }) as IListItem;

  let result: IPictureInformationForSubmission = {
    ...picture,
    timestamp,
    action,
    url,
    questionName: getTagNameFromActivity(
      picture as IPictureFromSubmissions,
      allActivities
    ),
    userName: findUserNameFromUsersList(picture.user_id, allUsers),
    submissionId: (picture as IPictureFromSubmissions).submission_id,
    customerId: !isEmptyValue(pictureTyped.item_name)
      ? pictureTyped.item_name
      : _.get(item, "_displayed_name"),
    id: picture["id"],
  };

  const workflow = _.find(
    allActivities,
    (w) => w.id === (picture as IPictureFromSubmissions).workflow_id
  );
  if (workflow) result.workflowName = workflow.name;
  if (item) result["_location"] = getLocationValue(item, "_location");
  if (pictureList && item && !isEmptyValue(pictureList.important)) {
    _.map(pictureList.important, (tag: string) => {
      result[tag] = _.get(item, tag, "");
    });
  }
  if (pictureList) {
    result["list_id"] = pictureList.id;
    result["list_name"] = pictureList.name;
  }

  if (pictureList?.id === "customer") {
    result["type"] = _.get(item, "_type");
  }
  if (!_.isEmpty(result.questionName) && picture["activity_id"]) {
    const activity = _.find(
      allActivities,
      (a) => a.id === picture["activity_id"]
    );
    result["activity_name"] = activity?.name;
  }
  if (result["activity_id"] && result["tag"]) {
    const activity = _.find(
      allActivities,
      (a) => a.id === result["activity_id"]
    );
    const question = _.find(
      activity?.questionnaire?.questions,
      (q) => q.tag === result["tag"]
    );
    if (question?.question_text) {
      result["questionName"] = question?.question_text;
    }
  }

  if (picture.action === PictureActions.UploadFileForItem) {
    result = {
      ...result,
      ...picture,
      timestamp,
      action,
      url,
    };
    if (item) result["_location"] = getLocationValue(item, "_location");

    result.listName = pictureList?.name;
    result.itemName = (picture as IPictureFromItems).item_name;

    if (item && pictureList && !isEmptyValue(pictureList.important)) {
      _.map(pictureList.important, (tag: string) => {
        result[tag] = _.get(item, tag, "");
      });
    }
  }
  return result;
};

export function getImageData(url: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(url, function (err: any, data: any) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}
export const downloadPictures = (pictures: IPicture[]) => {
  return new Promise((resolve) => {
    const zip = new JSZip();
    zip.folder("pictures");
    // load each picture
    pictures.forEach((picture, i) => {
      zip.file(`pictures/picture_${i + 1}.png`, getImageData(picture.url), {
        binary: true,
      });
    });
    // generate et download the zip
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "pictures.zip");
      resolve("success");
    });
  });
};

export const downloadSinglePicture = (url: string) => {
  fetch(url, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      response
        .arrayBuffer()
        .then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "picture.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((e: any) => {
          alert(
            `Error when trying to run response.arrayBuffer(): ${e.message}`
          );
        });
    })
    .catch((_err) => {});
};

export enum TypeSortList {
  Date = "DATE",
  User = "USER",
  Tag = "TAG",
}

export enum TypeSortValue {
  Asc = "ASC",
  Desc = "DESC",
}

export interface IPictureWithIndex extends IPicture {
  uniqueId: number;
}
