import { CUSTOM_FIELD_TYPE } from "fieldpro-tools";
import _ from "lodash";

import {
  IActivity,
  ICondition,
  IOperation,
  IWorkflow,
} from "model/entities/Workflow";
import { resolveOperations } from "utils/expressions/operationSolver";

export interface IResolvedMetaExpressions {
  [metaExpression: string]: string;
}

// TODO: add TS function overloads like
// (ICondition[], IResolvedMetaExpressions) => ICondition[]
// (IOperation[], IResolvedMetaExpressions) => IOperation[]
/**
 * @param computations array of Conditions or Operations
 * @param resolvedMetaExpressions
 * @returns an array where MEs have been replaced by their values (usually resolved on the back-end)
 */
export const injectMetaExpressionsInComputation = (
  computations: ICondition[] | IOperation[],
  resolvedMetaExpressions: IResolvedMetaExpressions
) => {
  return computations.map((expression) => {
    const newEpression = _.cloneDeep(expression);

    if (newEpression.first.value) {
      newEpression.first.value = injectValueForMetaExpression(
        newEpression.first.value,
        resolvedMetaExpressions
      );
    }
    if (newEpression.second.value) {
      newEpression.second.value = injectValueForMetaExpression(
        newEpression.second.value,
        resolvedMetaExpressions
      );
    }
    return newEpression;
  });
};

export const injectValueForMetaExpression = (
  template: string,
  resolvedMetaExpressions: IResolvedMetaExpressions
) => {
  const regex = /\$\{([^}]*)\}/g;
  const regexResult = template.match(regex);
  let result = template;
  if (regexResult) {
    for (const rResult of regexResult) {
      result = template
        .split(rResult)
        .join(resolvedMetaExpressions[rResult.slice(2, rResult.length - 1)]);
    }
  }
  return result;
};

export const isRefreshMetaExpressionNeeded = (
  metaExpressions: string[],
  attrName: string
): boolean => {
  // no need to be super-strict with that. It is only for performance purposes
  return _.some(metaExpressions, (me) => me.includes(attrName));
};

export const getDefaultValuesOfQuestions = (
  workflow: IWorkflow,
  activity: IActivity,
  stepId: string,
  resolvedMetaExpressions: IResolvedMetaExpressions
): { [questionTag: string]: any } => {
  // resolve the default values from the chainings
  const chainings = (workflow.chainings ?? []).filter(
    (ch) => ch.destination_step === stepId
  );
  const result = {};
  for (const chaining of chainings) {
    for (const params of chaining.destination_params) {
      if (!result[params.key]) {
        result[params.key] = injectValueForMetaExpression(
          params.value,
          resolvedMetaExpressions
        );
      }
    }
  }
  // resolve the default values from the compute
  const questions = activity?.questionnaire?.questions;
  const activeComputeQuestions = _.filter(
    questions,
    (q) => !q.deprecated && q.type === CUSTOM_FIELD_TYPE.COMPUTE
  );

  for (const q of activeComputeQuestions) {
    const operationWithResolvedMEs = injectMetaExpressionsInComputation(
      q.operations ?? [],
      resolvedMetaExpressions
    ) as IOperation[];

    const res = resolveOperations(operationWithResolvedMEs);
    if (res) {
      result[q.tag] = res;
    }
  }

  return result;
};
