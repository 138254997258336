import {
  IHttpRequest as IBEHttpRequest,
  IIntegration as IBEIntegration,
  IJobScript as IBEJobScript,
  IJobScriptColumn as IBEJobScriptColumn,
  INotification as IBENotification,
  IStream as IBEStream,
  IStreamColumn as IBEStreamColumn,
  ISubQuery as IBESubQuery,
  ITransformation as IBETransformation,
  STEP_TYPE,
} from "fieldpro-tools/dist/src/types";

import { ITableRowContent } from "model/application/components";
export type { INotification } from "fieldpro-tools/dist/src/types";
export {
  ADD_DATA_STRATEGY,
  BEYONIC_CURRENCIES,
  BEYONIC_WEBHOOKS_EVENT_TYPE,
  INTEGRATION_TYPE,
  JOB_POSSIBLE_TO_CONFIGURE,
  NOTIFICATION_DATE_RANGE,
  NOTIFICATION_DB_TYPE,
  NOTIFICATION_TYPE,
  SCRIPT_LANGUAGE,
  STEP_TYPE,
  STREAM_JOB_PARAMETER,
  TRANSFORMATION_AGGREGATION_PERIOD,
} from "fieldpro-tools/dist/src/types";
export interface IIntegration extends Omit<IBEIntegration, "webhooks"> {
  webhooks: string[];
}
export interface ITransformationInTable extends ITableRowContent {
  title: string;
  primary_key: string;
  transformation_aggregation_period: string;
  indexes: string;
}
export interface ITransformation
  extends Omit<IBETransformation, "last_update"> {
  last_update?: string;
  frozen?: boolean;
  indexes: string;
}
export interface ISubQuery extends IBESubQuery {
  index?: number;
}
export interface IEmailGenerationInTable extends ITableRowContent {
  id: string;
  title: string;
  frequency: string;
  date_range: string;
  start_date: Date;
  start_time: Date;
}
export interface IStreamColumn extends IBEStreamColumn {
  index?: number;
}
export interface IJobScriptColumn extends IBEJobScriptColumn {
  index?: number;
}
export interface IJobScript extends IBEJobScript {
  indexes: string;
}
export interface IStream extends IBEStream {
  indexes: string;
}
export interface IHttpRequest extends IBEHttpRequest {}
export interface IStreamInTable extends ITableRowContent {
  title: string;
  destination_table: string;
  primary_key: string;
}
export interface ILabelAliasMapping {
  label: string;
  alias: string;
}
export interface IJobInTable extends ITableRowContent {
  id: string;
  type: string;
}
export interface IJob {
  type: STEP_TYPE;
  content:
    | IBENotification
    | IStream
    | ITransformation
    | IIntegration
    | IHttpRequest;
}
