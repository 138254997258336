import { useEffect, useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import {
  BasicArrowIcon,
  BasicCloseIcon,
  BasicDialog,
} from "components/Dialog/BasicDialog";
import { allListsSelector } from "containers/lists/redux/selectors";
import PictureDetectionView from "containers/pictures/components/modals/PictureDetectionView";
import PictureSideBar from "containers/pictures/components/modals/PictureSideBar";
import { IImageDetectionDataFE } from "containers/pictures/components/modals/types";
import {
  fetchPictureDetections,
  getIdsFromDetections,
} from "containers/pictures/components/modals/utils";

import styles from "./styles";
import { TGalleryPicture } from "./types";

const useStyles = makeStyles(styles);

interface IPictureGallerySlide {
  pictures: TGalleryPicture[];
  open: boolean;
  currentIndex: number;
  onArrowClick: (newIndex: number) => any;
  onModalClose: () => any;
}

function PictureGallerySlide({
  pictures,
  open,
  currentIndex = 0,
  onArrowClick,
  onModalClose,
}: IPictureGallerySlide) {
  const classes = useStyles();

  const nextPicture = () => {
    if (currentIndex === pictures.length - 1) {
      onArrowClick(0);
    } else {
      onArrowClick(currentIndex + 1);
    }
  };
  const previousPicture = () => {
    if (currentIndex === 0) {
      onArrowClick(pictures.length - 1);
    } else {
      onArrowClick(currentIndex - 1);
    }
  };

  const [displayDetections, setDisplayDetections] = useState(false);
  const [loadingDetections, setLoadingDetections] = useState(true);
  const [detections, setDetections] = useState<IImageDetectionDataFE[]>([]);

  const [activeBox, setActiveBox] = useState<string | undefined>(undefined);
  const [highlighedBoxes, sethighlighedBoxes] = useState<string[]>([]);

  const allLists = useSelector(allListsSelector);

  function handleChangeDisplayDetections(value: boolean) {
    setDisplayDetections(value);
  }

  useEffect(() => {
    if (pictures[currentIndex] !== undefined && pictures[currentIndex]) {
      (async () => {
        setLoadingDetections(true);
        const detectionData = await fetchPictureDetections({
          id: pictures?.at(currentIndex)?.id || "_",
        });
        setDetections(detectionData);
        sethighlighedBoxes(getIdsFromDetections(detectionData));
        setLoadingDetections(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, allLists]);

  return (
    <BasicDialog
      open={open}
      onClose={() => {}}
      fullWidth
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: "rgba(0,0,0,0.7)",
        },
      }}
    >
      <Box className={classes.root}>
        {/* Container for image and navigation controls */}
        <Box className={classes.leftContainer}>
          <Box className={classes.detectionViewContainer}>
            <BasicArrowIcon
              direction="left"
              onClick={previousPicture}
              filled
              style={{ height: "40px", width: "40px" }}
            />
            {/* Integration of PictureDetectionView */}
            <PictureDetectionView
              key={detections.length}
              activeDetectionBoxIds={_.compact([activeBox, ...highlighedBoxes])}
              displayDetections={displayDetections}
              detections={detections}
              picture={pictures[currentIndex] as any}
              imgProps={{
                height: "auto",
                style: {
                  objectFit: "contain",
                  maxWidth: "59vw",
                  maxHeight: "calc(100vh - 72px)",
                },
              }}
              onClick={(id) => {
                if (activeBox === id) {
                  setActiveBox(undefined);
                  sethighlighedBoxes(getIdsFromDetections(detections));
                } else {
                  setActiveBox(id);
                  sethighlighedBoxes([]);
                }
              }}
            />
            <BasicArrowIcon
              direction="right"
              onClick={nextPicture}
              filled
              style={{ height: "40px", width: "40px" }}
            />
          </Box>
        </Box>
        {/* Container for the ScanResultsTab */}
        <Box className={classes.rightContainer}>
          <Box className={classes.closeIconContainer}>
            <BasicCloseIcon
              style={{ height: "40px", width: "40px" }}
              filled={true}
              onClick={() => onModalClose()}
            />
          </Box>
          <PictureSideBar
            activeBoxIds={_.compact([activeBox])}
            key={currentIndex}
            isLoadingScanResults={loadingDetections}
            toggleDisplayDetections={handleChangeDisplayDetections}
            picture={pictures[currentIndex] as any}
            detections={detections}
          />
        </Box>
      </Box>
    </BasicDialog>
  );
}

export default PictureGallerySlide;
