import { useEffect, useState } from "react";

import { Box, Grid, makeStyles } from "@material-ui/core";
import _ from "lodash";

import PictureGalleryChips from "./PictureGalleryChips";
import PictureGalleryList from "./PictureGalleryList";
import PictureGallerySlide from "./PictureGallerySlide";
import { TGalleryPicture } from "./types";
export interface IPictureGalleryProps {
  pictures: TGalleryPicture[];
}
const styles = {
  imageListArea: {
    overflowY: "auto",
    maxHeight: "500px",
    padding: "0px",
    justifyContent: "center",
  },
  pictureGallery: {
    overflow: "hidden",
  },
};

const useStyles = makeStyles(styles as any);

function PictureGallery({ pictures, ...props }: IPictureGalleryProps) {
  const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);
  const [selectedPictureIndex, setSelectedPictureIndex] = useState<number>(0);
  const [openPictureModalState, setOpenPictureModalState] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setSelectedTag(undefined); // reset chips on activity report change
  }, [pictures]);

  if (_.isEmpty(pictures)) {
    return null;
  }

  return (
    <Box className={classes.pictureGallery} {...props}>
      <Grid
        container
        direction="column"
        style={{
          width: "100%",
          columnGap: "16px",
          rowGap: "16px",
          paddingBottom: "16px",
        }}
      >
        <Grid item>
          <PictureGalleryChips
            data={pictures}
            selectedTag={selectedTag}
            label={"tag"}
            onChipClick={function (label) {
              setSelectedTag(label);
            }}
          />
        </Grid>
        <Grid item className={classes.imageListArea}>
          <PictureGalleryList
            pictures={pictures}
            selectedTag={selectedTag}
            onImageClick={(imageTag, imageIndex) => {
              setSelectedPictureIndex(imageIndex);
              setOpenPictureModalState(true);
            }}
          />
          <PictureGallerySlide
            open={openPictureModalState}
            onArrowClick={function (newIndex) {
              setSelectedPictureIndex(newIndex);
            }}
            pictures={pictures}
            currentIndex={selectedPictureIndex}
            onModalClose={function () {
              setOpenPictureModalState(false);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PictureGallery;
