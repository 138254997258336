import _ from "lodash";

import {
  getCategoryTagKey,
  getMatrixFieldsSchema,
  getNameKey,
  getTagKey,
} from "components/Input/InputMatrix/utils/getQuestionColumns";
import { extractType } from "containers/workflows/subcategories/activities/utils/activityReports";
import prepareCustomFieldDataForBackend from "containers/workflows/subcategories/activities/utils/prepareCustomFieldDataForBackend";
import { IFormState } from "hooks/useFormState";
import { ICustomField } from "model/application/DynamicObjects";
import { TLang } from "model/application/Lang";
import { CUSTOM_FIELD_TYPE, IList, IListSchema } from "model/entities/List";
import { IQuestion } from "model/entities/Workflow";

import {
  getMatrixCategories,
  getMatrixColumns,
  getMatrixRows,
} from "./matrixFields";

export interface IFormatCustomFieldValues {
  customFieldValues: ICustomField[];
  fieldsSchema: IQuestion[] | IListSchema[];
  lists: IList[];
  lang: TLang;
}

export const formatCustomFieldValues = ({
  customFieldValues,
  fieldsSchema,
  lists,
  lang,
}: IFormatCustomFieldValues) => {
  const res = {};
  _.forEach(customFieldValues, (customField: ICustomField) => {
    const formattedValue = formatCustomField({
      customField,
      fieldsSchema,
      lists,
      lang,
    });
    res[customField.key] = formattedValue;
  });
  return res;
};

interface IFormatCustomField {
  customField: ICustomField;
  fieldsSchema: IQuestion[] | IListSchema[];
  lists: IList[];
  lang: TLang;
}

const formatCustomField = ({
  customField,
  fieldsSchema,
  lists,
  lang,
}: IFormatCustomField) => {
  const type = extractType(customField.key, fieldsSchema);
  switch (type) {
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE: {
      const possibleOptions = (
        _.find(
          fieldsSchema,
          (cf: IListSchema | IQuestion) => cf[getTagKey(cf)] === customField.key
        ) as IQuestion | IListSchema
      )?.options;

      const values = (possibleOptions || [])
        .filter((opt) => _.includes(customField?.value, opt.key))
        .map((opt) => ({ key: opt.key, label: opt.label }));

      return type === CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE ? values : values[0];
    }
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST:
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST: {
      const listItemIds = _.isArray(customField.value)
        ? customField.value
        : _.compact([customField.value]);

      const listId = getFieldByTag(fieldsSchema, customField.key)?.list_id;

      const list = _.find(lists, { id: listId });

      const values = _.compact(
        _.map(listItemIds, (_id) => {
          const item = _.find(list?.items, { _id });
          if (!item) {
            return null;
          }
          // TODO: fallback to _id for the label if the item is not found
          return { key: _id, label: item._name ?? _id };
        })
      );

      if (type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST) {
        return values[0];
      }
      return values;
    }
    case CUSTOM_FIELD_TYPE.MATRIX_ON_LIST: {
      const matrixField = getFieldByTag(fieldsSchema, customField.key) as
        | IListSchema
        | IQuestion;

      const list = _.find(lists, {
        id: matrixField?.list_id,
      });
      if (!matrixField || !list) {
        return null;
      }

      const categoryTag = matrixField[getCategoryTagKey(matrixField)];
      const categories = getMatrixCategories({ list, categoryTag }) || [];
      const columns = getMatrixColumns({ question: matrixField, lang });

      // recursive formatting of matrix fields
      const rows = getMatrixRows({
        dynamicObjects: customField.value,
        fieldsSchema: getMatrixFieldsSchema(matrixField),
        items: list.items,
        lists,
        lang,
      });

      return {
        title: matrixField[getNameKey(matrixField)],
        categories,
        columns,
        rows,
      };
    }
    default: {
      return customField.value;
    }
  }
};

type IGetFieldByTag = {
  (fieldsSchema: IQuestion[], tag: string): IQuestion | undefined;
  (fieldsSchema: IListSchema[], tag: string): IListSchema | undefined;
  (fieldsSchema: IQuestion[] | IListSchema[], tag: string):
    | IQuestion
    | IListSchema
    | undefined;
};

export const getFieldByTag: IGetFieldByTag = (fieldsSchema, tag) => {
  return _.find(
    fieldsSchema,
    // any to fix type issue: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/25758#issuecomment-580690247
    ((field: IQuestion | IListSchema) => field[getTagKey(field)] === tag) as any
  );
};

interface IFormatFormFieldsForBackend {
  formFields: IFormState<any>["attributes"];
  fieldsSchema: IQuestion[] | IListSchema[];
}

export const formatFormFieldsForBackend = ({
  formFields,
  fieldsSchema,
}: IFormatFormFieldsForBackend) => {
  return _.mapValues(formFields, (value, key) => {
    const fieldSchema = getFieldByTag(fieldsSchema, key);
    if (!fieldSchema) {
      return value;
    }
    return formatOnListFieldForBackend({
      value,
      fieldSchema,
    });
  });
};

interface IFormatOnListFieldsForBackend {
  value: any;
  fieldSchema: IQuestion | IListSchema;
}

// Should stay very close to prepareCustomFieldDataForBackend (except for Matrix, format differs on Web and Mobile)
const formatOnListFieldForBackend = ({
  value,
  fieldSchema,
}: IFormatOnListFieldsForBackend) => {
  return prepareCustomFieldDataForBackend({
    question: fieldSchema,
    answer: value,
    skipMatrix: true,
  });
};
