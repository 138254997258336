import { useState } from "react";

import { Chip, Grid } from "@material-ui/core";

import { TGalleryPicture } from "./types";

interface IPictureGalleryChipsProps {
  data: TGalleryPicture[];
  label: string;
  onChipClick: (label: string | undefined) => any;
  selectedTag: string | undefined;
}

/** will generate a chip list
 *
 * @param data the data object array to iterate
 * @param predicate a function that returns a boolean after an evalution with the object type
 * @param label a key in the object to use as a unique value
 * @param onChipClick a function that is returned with the value of the said key in the object
 * @returns
 */
function PictureGalleryChips({
  data,
  label,
  onChipClick,
  selectedTag,
}: IPictureGalleryChipsProps) {
  const labels: Array<any> = [];
  const [fallBack, setFallBack] = useState(true);

  const Chips = data.map((item) => {
    const itemLabelValue: any = item[label];
    if (!labels.includes(itemLabelValue)) {
      labels.push(itemLabelValue);
      const _predicate = selectedTag === item.tag;
      if (_predicate && fallBack) {
        setFallBack(false);
      }
      return (
        <Grid item key={item.tag}>
          <Chip
            color={_predicate ? "secondary" : "default"}
            label={itemLabelValue}
            onClick={() => {
              setFallBack(false);
              onChipClick(itemLabelValue);
            }}
          />
        </Grid>
      );
    }
  });

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <Chip
          color={fallBack ? "secondary" : "default"}
          label={"All"}
          onClick={() => {
            setFallBack(true);
            onChipClick(undefined);
          }}
        />
      </Grid>
      {Chips}
    </Grid>
  );
}

export default PictureGalleryChips;
