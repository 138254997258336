import * as colors from "assets/colors";
import { Black, TealLight2 } from "assets/colors";

const styles = (theme: any) => ({
  root: {
    height: "100%",
    color: Black,
  },
  container: {
    height: "calc(100% - 50px)",
    display: "flex",
    flexDirection: "column",
    color: Black,
  },
  progress: {
    height: "300px",
  },
  TablePlaceholder: {
    height: "2000px", // hack to take up all available height
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ActionRow: {
    marginRight: "20px",
    height: "42px",
    right: "0px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    transform: "scale(1.1)",
    background:
      "linear-gradient(90deg, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 100%)",
  },
  ActionButton: {
    fontSize: "16px",
    fontWeight: 500,
  },
  TableSortLabel: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  TableHeader: {
    fontWeight: "bold",
    position: "inherit",
    padding: "0px",
    background: theme.palette.primary.light,
    color: "white !important",
    borderBottom: "none",
  },
  TableHeaderCell: {
    fontWeight: "bold",
    position: "inherit",
    padding: "14px 16px",
    background: theme.palette.primary.light,
    color: "white !important",
    border: "none",
  },
  TableHeaderActionsCell: {
    width: 0,
    padding: 0,
    background: theme.palette.primary.light,
  },
  TableHeaderCellContent: {
    display: "inline-block",
    fontWeight: "bold",
    position: "inherit",
    padding: "0px",
    color: "white !important",
    lineHeight: "20px",
    width: "calc(100% - 26px)",
  },
  TableCell: {
    position: "inherit",
    fontWeight: "inherit",
    lineHeight: "20px",
    padding: "0px 16px",
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "300px", // ISO timestamps must fit
    "&:not(.checked)": {
      color: Black,
    },
    "&.checked": {
      color: theme.palette.primary.main,
    },
  },
  TableCellTooltip: {
    maxWidth: "unset",
  },
  TableCellSticky: {
    position: "sticky",
    left: "1px",
    padding: "13px 16px",
  },
  TextEllipsis: {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  TableCellActions: {
    width: 0,
    right: 0,
    padding: 0,
    position: "sticky",
    backgroundColor: "inherit",
    whiteSpace: "nowrap",
    "&:not(.checked)": {
      color: "inherit !important",
    },
    "&.checked": {
      color: theme.palette.primary.main,
    },
  },
  TableCellActionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "49px",
    gap: "10px",
    top: 0,
    backgroundColor: "inherit",
    position: "absolute",
    width: "fit-content",
    right: "0",
    paddingLeft: "8px",
    paddingRight: "16px",
  },
  TableHeaderBackground: {
    background: theme.palette.primary.main,
  },
  HeaderContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "50px",
    width: "100%",
  },
  TableContainer: {
    overflow: "auto",
    // borderRight: `1px solid #e0dfdf`,
    "&.isTableChart": {
      maxHeight: "calc(100vh - 416px)",
      borderBottom: `1px solid #e0dfdf`,
    },
  },
  ActionButtonsContainer: {
    // flexGrow: 1,
    flexShrink: 0,
  },

  PaginationContainer: {
    flexShrink: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  TableCellChip: {
    position: "inherit",
    color: "inherit !important",
    fontWeight: "inherit",
    padding: "0px",
    paddingLeft: "10px",
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "auto",
  },
  TableCellContent: {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  TablePagination: {
    background: "white",
  },
  TablePaginationRoot: {
    borderBottom: "none",
  },
  TablePaginationActions: {
    lineHeight: 1,
    "& button": {
      height: "50px",
    },
  },
  TablePaginationCaption: {
    fontWeight: 500,
    fontSize: "16px",
  },
  TablePaginationSelect: {
    fontWeight: 500,
    fontSize: "16px",
    "& ~ .MuiSelect-icon": {
      top: "unset",
    },
  },
  SelectMenu: {
    "& div[x-placement]": {
      zIndex: 1150, // 12OO is too high for the side menu, 1100 too low for the table rows' checkboxes
    },
  },
  TablePaginationSpacer: {
    flex: "none",
  },
  TablePaginationToolbar: {
    minHeight: "50px",
    height: "50px",
    overflowY: "hidden",
    fontWeight: 500,
    fontSize: "16px",
    padding: "12px 18px",
  },
  Row: {
    lineHeight: "20px",
    border: "solid 1px #e0dfdf",
    borderBottom: "none", // rgba(224, 224, 224, 1)",
    height: "50px",
    "&.clickable": {
      cursor: "pointer",
    },
    "&.checked, &.checked:hover": {
      border: `solid 1px ${theme.palette.primary.main}`,
      backgroundColor: colors.TealLight2,
      "& .cell-gradient": {
        background: `linear-gradient(to right, transparent, ${colors.TealLight2})`,
      },
    },
    "& .MuiTableCell-root": {
      border: "none",
    },
    "& .cell-gradient": {
      backgroundImage: "linear-gradient(to right, transparent, #f5f5f5)",
    },
  },
  FooterRow: {
    lineHeight: "20px",
    border: "solid 1px #e0dfdf",
    borderBottomRadius: "4px",
    height: "50px",
    background: `${theme.palette.primary.light} !important`,
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      background: `${theme.palette.primary.light} !important`,
    },
  },
  PictureIcon: {
    height: "44px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  StatusOngoingButton: {
    textTransform: "none",
    alignSelf: "center",
    backgroundColor: "rgba(254, 189, 85, 0.1)",
    height: "36px",
    padding: "8px 0px",
    borderRadius: "4px",
    elevation: "0",
    "&:hover": {
      backgroundColor: "rgba(254, 189, 85, 0.1)",
      borderColor: "rgba(254, 189, 85, 0.1)",
      boxShadow: "none",
    },
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "-0.25px",
    textAlign: "center",
    color: "rgba(254, 189, 85)",
  },
  StatusCancelledButton: {
    textTransform: "none",
    alignSelf: "center",
    backgroundColor: "rgba(150, 150, 150, 0.1)",
    height: "36px",
    padding: "8px 0px",
    borderRadius: "4px",
    elevation: "0",
    "&:hover": {
      backgroundColor: "rgba(150, 150, 150, 0.1)",
      borderColor: "rgba(150, 150, 150, 0.1)",
      boxShadow: "none",
    },
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "-0.25px",
    textAlign: "center",
    color: "#ccc",
  },
  StatusDoneButton: {
    textTransform: "none",
    alignSelf: "center",
    backgroundColor: "#e6eced",
    height: "36px",
    padding: "8px 0px",
    borderRadius: "4px",
    elevation: "0",
    "&:hover": {
      backgroundColor: "#e6eced",
      borderColor: "#e6eced",
      boxShadow: "none",
    },
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "-0.25px",
    textAlign: "center",
    color: "#124e5d",
  },
  StatusFailureButton: {
    textTransform: "none",
    alignSelf: "center",
    backgroundColor: "rgba(254, 189, 85, 0.1)",
    height: "36px",
    padding: "8px 0px",
    borderRadius: "4px",
    elevation: "0",
    "&:hover": {
      backgroundColor: "rgba(254, 189, 85, 0.1)",
      borderColor: "rgba(254, 189, 85, 0.1)",
      boxShadow: "none",
    },
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "-0.25px",
    textAlign: "center",
    color: "#ff5722",
  },
  StatusWarningButton: {
    textTransform: "none",
    alignSelf: "center",
    backgroundColor: "rgba(254, 189, 85, 0.1)",
    height: "36px",
    padding: "8px 0px",
    borderRadius: "4px",
    elevation: "0",
    "&:hover": {
      backgroundColor: "rgba(254, 189, 85, 0.1)",
      borderColor: "rgba(254, 189, 85, 0.1)",
      boxShadow: "none",
    },
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "-0.25px",
    textAlign: "center",
    color: "#ff5722",
  },

  StatusPublished: {
    textTransform: "none",
    alignSelf: "center",
    backgroundColor: "#E8F4F0",
    height: "36px",
    padding: "8px 0px",
    borderRadius: "4px",
    elevation: "0",
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "-0.25px",
    textAlign: "center",
    color: "#1C8E64",
  },
  StatusDraft: {
    textTransform: "none",
    alignSelf: "center",
    backgroundColor: "#E6E6E6",
    height: "36px",
    padding: "8px 0px",
    borderRadius: "4px",
    elevation: "0",
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "-0.25px",
    textAlign: "center",
    color: "#FEFEFE",
  },
  PipelineContainer: {},

  AboveTable: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px",
  },
  // clear filter button
  ClearFilterButton: {
    borderRadius: "4px",
    border: "0px solid !important",
    columnGap: 10,
    "&:hover": {
      opacity: ".5",
    },
    "box-shadow": "none",
  },

  FilterRows: {
    left: 1,
    top: "53.43px", // should be the same height as the table header
    height: "50px",
    zIndex: 10,
    background: TealLight2,
    position: "sticky",
  },
  FirstColumnOnFilterTable: {
    position: "sticky",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    left: 1,
  },
  FirstColumnsOnFilterRows: {
    display: "flex",
    alignItems: "center",
    columnGap: 10,
    height: "20px",
    fontSize: "16px",
    color: "#6c6c6c",
    marginLeft: "15px",
    position: "sticky !important",
    width: "100%",
  },
  FilterCount: {
    color: "black",
    fontWeight: "bold",
  },
  ClearButton: {
    backgroundColor: "#febd55",
    color: colors.OffWhite,
    padding: "6px 16px",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },
  FilterRowClose: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    border: "none",
    marginRight: "10px",
  },
  ResizeHandleContainer: {
    position: "absolute",
    top: 0,
    height: "100%",
    width: "20px",
    right: "-10px",
    cursor: "col-resize",
    zIndex: 10, // needs to be on top of next cell
    // backgroundColor: "rgba(250,0,0,0.3)", // to debug
    "&:hover $ResizeHandle": {
      width: "3px",
      background: "rgba(245,245,245, 0.3)",
    },
  },
  ResizeHandle: {
    height: "100%",
    margin: "auto",
    width: "1px",
    background: "rgba(0,0,0,0.1)",
  },
  selectAllMenu: {
    position: "absolute",
    zIndex: 1000,
    backgroundColor: "white",
    padding: 5,
    border: "1px",
    borderRadius: 3,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    top: 35,
  },
});

export default styles;
