import React from "react";

import FieldProLogger from "log/FieldProLogger";

import NewVersionAvailableScreen from "components/NewVersionAvailableScreen";

type ReactLazyParams = Parameters<typeof React.lazy>;
const logger = FieldProLogger({});

export const lazyRetry = function (
  componentImport: ReactLazyParams[0],
  avoidCrash: boolean = process.env.NODE_ENV === "production"
): ReturnType<ReactLazyParams[0]> {
  return new Promise((resolve, reject) => {
    componentImport()
      .then((component) => {
        // in some cases webpack returns undefined : https://stackoverflow.com/questions/35240716/
        if (component === undefined) {
          resolve({
            default: NewVersionAvailableScreen,
          });
        }
        resolve(component);
      })
      .catch((error: any) => {
        if (avoidCrash) {
          resolve({
            default: NewVersionAvailableScreen,
          });
        } else {
          reject(error);
        }
        logger.logError(error);
      });
  });
};

export const HomeContainer = React.lazy(() =>
  lazyRetry(
    () => import("containers/home/HomeContainer" /* webpackChunkName: "home" */)
  )
);

export const DashboardContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        "containers/dashboards/DashboardContainer" /* webpackChunkName: "dashboards" */
      )
  )
);

export const LoginContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        "containers/authentication/LoginContainer" /* webpackChunkName: "login" */
      )
  )
);

export const TrialContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        "containers/authentication/Trial/TrialContainer" /* webpackChunkName: "trial" */
      )
  )
);

export const UserMgtContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        "containers/users/UserMgtContainer" /* webpackChunkName: "users" */
      )
  )
);

export const ClientsContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        "containers/clients/ClientsContainer" /* webpackChunkName: "clients" */
      )
  )
);

export const TeamsContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import("containers/teams/TeamsContainer" /* webpackChunkName: "teams" */)
  )
);

export const WorkflowsContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        "containers/workflows/WorkflowsContainer" /* webpackChunkName: "workflows" */
      )
  )
);

export const ListsContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import("containers/lists/ListsContainer" /* webpackChunkName: "lists" */)
  )
);

export const OrdersContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        "containers/orders/OrdersContainer" /* webpackChunkName: "orders" */
      )
  )
);

export const PlacesContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        "containers/customers/CustomersContainer" /* webpackChunkName: "customers" */
      )
  )
);

export const PicturesContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        "containers/pictures/PicturesContainer" /* webpackChunkName: "pictures" */
      )
  )
);

export const DocumentsContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        "containers/documents/DocumentsContainer" /* webpackChunkName: "documents" */
      )
  )
);

export const EnvironmentContainer = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        "containers/environments/EnvironmentContainer" /* webpackChunkName: "environment" */
      )
  )
);
