import { CalendarEventStatus, TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";

import { TCalendarFilterObject } from "../types";
import { CALENDAR_FILTERS } from "./prepareFilters";

interface GetEventsToDisplayParams {
  allowDisplayingEvents: boolean;
  filterQuery: TCalendarFilterObject;
  events: TCalendarEvent[];
}

export function getEventsToDisplay({
  allowDisplayingEvents,
  filterQuery,
  events,
}: GetEventsToDisplayParams): TCalendarEvent[] {
  if (!allowDisplayingEvents) return [];
  if (!_.isEmpty(filterQuery[CALENDAR_FILTERS.STATUS])) {
    return _.filter(events, (event) =>
      _.includes(filterQuery[CALENDAR_FILTERS.STATUS], event.status)
    );
  }
  //Just to be safe, deleted events are not returned by the backend
  return events.filter((e) => e.status != CalendarEventStatus.DELETED);
}
