/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  DASHBOARD_TYPE,
  IActivityReport as IBEActivityReport,
  STEP_SCOPE,
} from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import CustomChips, {
  ICustomChipData,
} from "components/Chip/CustomChips/CustomChips";
import DialogPlaceHolder from "components/Dialog/DialogPlaceHolder";
import PictureGallery from "components/PictureGallery";
import { Tab, Tabs } from "components/Tab";
import { getSignedInUser } from "containers/authentication/redux/selector";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { dashboardsComposedSelector } from "containers/dashboards/redux/selectors";
import { getAllFolders } from "containers/folders/redux/selectors";
import { listsComposedSelector } from "containers/lists/redux/selectors";
import { allMobileUsersSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { computeMetaExpressionsAction } from "containers/workflows/redux/meta_expressions/actions";
import { allMetaExpressionsSolvedSelector } from "containers/workflows/redux/meta_expressions/selectors";
import useActions from "hooks/useActions";
import { IFormState } from "hooks/useFormState";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IList } from "model/entities/List";
import {
  IActivity,
  IActivityReportInTable,
  IQuestion,
  IWorkflow,
} from "model/entities/Workflow";
import {
  getDefaultValuesOfQuestions,
  isRefreshMetaExpressionNeeded,
} from "utils/metaExpressions/metaExpressionInjection";
import { clone } from "utils/utils";

import { getMetaExpressionsFromActivity } from "../../reports/utils";
import {
  groupQuestionsWithPictures,
  mapQuestionsToReportAnswers,
} from "../ActivityReport.utils";
import ActivityReportForm from "../ActivityReportForm";
import prepareReportStateForBackend from "../utils/prepareReportStateForBackend";
import ActivityReportInsights from "./ActivityReportInsights";

interface IActivityReportDialogInnerContent {
  workflow: IWorkflow;
  activity: IActivity;
  formState: IFormState<IActivityReportInTable>;
  viewMode: TViewMode;
  stepId?: string;
  nextStepScope?: STEP_SCOPE;
  formQuestions: IQuestion[];
}

const ActivityReportDialogInnerContent = ({
  workflow,
  activity,
  formState,
  viewMode,
  stepId,
  nextStepScope,
  formQuestions,
}: IActivityReportDialogInnerContent) => {
  const lang = useTranslations();
  const langKey =
    lang.containers.workflows.subCategories.activityReports.detailDialog;

  // MEs that can (and should be) solved in the BE
  // This is not the case for contextual MEs such as those scoped to matrix cell or item
  // (The values are updated by the FE before saving to BE)
  const mapMetaExpressionSolved = useSelector(allMetaExpressionsSolvedSelector);

  const lists: IList[] = useSelector(listsComposedSelector);

  const dashboards = useSelector(dashboardsComposedSelector);
  const activityReportDashboards = _.filter(dashboards, {
    type: DASHBOARD_TYPE.MOBILE_ON_ACTIVITY,
    //list_id: activity?.name,
  }).filter((d) => !d.is_statement);

  const metaExpressionsInActivity = getMetaExpressionsFromActivity(activity);
  const computeMetaExpressions = useActions(computeMetaExpressionsAction);

  const { attributes, handleInputChange } = formState;

  const pictures = groupQuestionsWithPictures(
    mapQuestionsToReportAnswers(activity, attributes)
  );

  const selectedClient = useSelector(getSelectedClient);

  const signedInUser = useSelector(getSignedInUser);
  const mobileUsers = useSelector(allMobileUsersSelector);
  const linkedMobileUser = mobileUsers.find(
    (mu) => mu.email && mu.email === signedInUser.id
  );

  // Debounced version of the onUpdateGeneralState function
  const debouncedOnUpdateGeneralState = _.debounce(
    (attrChanged, value) => {
      if (
        !isRefreshMetaExpressionNeeded(metaExpressionsInActivity, attrChanged)
      ) {
        return;
      }

      let metaExpressionsToCompute = metaExpressionsInActivity;
      /*
      const currQuestion = _.find(formQuestions, { tag: attrChanged });
      if (
        currQuestion &&
        currQuestion.type !== CUSTOM_FIELD_TYPE.MATRIX_ON_LIST
      ) {
      */
      metaExpressionsToCompute = metaExpressionsInActivity.filter(
        (me) =>
          !me.includes("__active_matrix_cell") &&
          !me.includes("__active_item_cell")
      );
      //}

      const activity_report = {
        ...prepareReportStateForBackend(
          {
            ...attributes,
            [attrChanged]: value,
          },
          activity
        ),
        _activity_id: activity.id,
      } as IBEActivityReport;

      computeMetaExpressions("", metaExpressionsToCompute, {
        activity_report,
        client_id: selectedClient?.id ?? "",
        ...(activity_report._workflow_report_id // Conditional property based on the presence of _workflow_report_id
          ? { workflow_report_id: activity_report._workflow_report_id }
          : { user_id: linkedMobileUser?.id ?? "" }),
      });
    },
    300 // Debounce delay in milliseconds
  );

  const onUpdateGeneralState = (attrChanged: any, value: any | undefined) => {
    debouncedOnUpdateGeneralState(attrChanged, value);
  };

  function refreshMetaExpressions() {
    const nonMatrixMetaExpressions = _.filter(
      metaExpressionsInActivity,
      (me) =>
        !me.includes("__active_matrix_cell") &&
        !me.includes("__active_item_cell")
    );

    const activity_report = {
      ...prepareReportStateForBackend(attributes, activity),
      _activity_id: activity.id,
    } as IBEActivityReport;

    computeMetaExpressions("", nonMatrixMetaExpressions, {
      activity_report,
      client_id: selectedClient?.id ?? "",
      ...(activity_report._workflow_report_id // for resolving user scope
        ? { workflow_report_id: activity_report._workflow_report_id }
        : { user_id: linkedMobileUser?.id ?? "" }),
    });
  }

  const activityWithUpdatedQuestions = {
    ...activity,
    questionnaire: {
      ...activity.questionnaire,
      questions: formQuestions,
    },
  };

  const folderChips: ICustomChipData[] = [];
  const allFolders = useSelector(getAllFolders);

  _.forEach(activityReportDashboards, function (dashboard) {
    if (dashboard.hasOwnProperty("folder") && dashboard.folder !== "default") {
      const folderName =
        _.find(allFolders, { id: dashboard.folder })?.name ??
        dashboard.folder ??
        "";

      const chip: ICustomChipData = {
        tag: dashboard.folder ?? "",
        label: folderName,
      };

      if (!_.find(folderChips, chip)) {
        folderChips.push(chip);
      }
    }
  });
  const initTag = folderChips
    ? folderChips.length > 0
      ? folderChips[0].tag
      : undefined
    : undefined;
  const [selectedTag, setSelectedTag] = useState<string | undefined>(initTag);

  function handleChipClick(currentTag: string | undefined) {
    setSelectedTag(currentTag);
  }

  function prefillAnswers() {
    const defaultAnswersByTag = stepId
      ? getDefaultValuesOfQuestions(
          workflow,
          clone(activity),
          stepId,
          mapMetaExpressionSolved
        )
      : {};

    for (const tag of _.keys(defaultAnswersByTag)) {
      if (attributes[tag] !== defaultAnswersByTag[tag]) {
        handleInputChange(defaultAnswersByTag[tag], tag);
      }
    }
  }

  function unsetQuestionsImpossibleToFill() {
    const impossibleAnswersToFill = _.map(
      activity.questionnaire.questions,
      "tag"
    ).filter((questionTag) => {
      if (!attributes[questionTag]) {
        return false;
      }
      const tagFoundInQuestions = _.find(
        formQuestions,
        (q) => q.tag === questionTag
      );
      return !tagFoundInQuestions;
    });
    if (impossibleAnswersToFill.length > 0) {
      // there are some questions that are not supposed to be filled. We should update the state of the hook
      handleInputChange(undefined, impossibleAnswersToFill[0]);
    }
  }

  //refresh the meta expressions once the view mode is set to EDIT
  useEffect(() => {
    if (viewMode === "EDIT") {
      refreshMetaExpressions();
    }
  }, [viewMode]);

  //edit the answers (only when new ME's need to be calculated)
  useEffect(() => {
    if (viewMode === "VIEW") {
      return;
    }
    prefillAnswers();
  }, [viewMode, mapMetaExpressionSolved]);

  //set the questions that are not in the form to undefined
  useEffect(() => {
    if (viewMode === "VIEW") {
      return;
    }
    unsetQuestionsImpossibleToFill();
  }, [viewMode, formQuestions]);

  if (viewMode === "VIEW") {
    return (
      <Tabs shiftScrollbar={24}>
        <Tab id="details" label={langKey.details}>
          <ActivityReportForm
            activity={activityWithUpdatedQuestions}
            report={attributes}
            formState={formState}
            lists={lists}
            viewMode={viewMode}
            nextStepScope={nextStepScope}
            onUpdateGeneralState={onUpdateGeneralState}
          />
        </Tab>

        <Tab id="pictures" label={langKey.pictures}>
          {pictures.length > 0 ? (
            <PictureGallery pictures={pictures} />
          ) : (
            <DialogPlaceHolder
              placeholderIcon={<DescriptionIcon />}
              placeholderText={langKey.emptyPictures}
            />
          )}
        </Tab>

        {!_.isEmpty(activityReportDashboards) && formState.attributes._id ? (
          <Tab
            id="insights"
            label={
              lang.containers.lists.subCategories.items.detailDialog.insights
            }
          >
            <Box paddingBottom={"8px"}>
              <CustomChips
                data={folderChips}
                selectedTag={selectedTag}
                onChipClick={handleChipClick}
                hideAll
              />
            </Box>

            {_.map(
              _.sortBy(
                _.filter(activityReportDashboards, function (dashboard) {
                  if (selectedTag) {
                    return dashboard.folder === selectedTag;
                  }
                  return true;
                }),
                "created_at"
              ),
              (dashboard) => (
                <ActivityReportInsights
                  dashboard={dashboard}
                  activityReportId={formState.attributes._id}
                  key={dashboard.id}
                />
              )
            )}
          </Tab>
        ) : null}
      </Tabs>
    );
  }

  return (
    <Box height="100%" overflow="auto">
      <ActivityReportForm
        activity={activityWithUpdatedQuestions}
        report={attributes}
        formState={formState}
        lists={lists}
        viewMode={viewMode}
        nextStepScope={nextStepScope}
        onUpdateGeneralState={onUpdateGeneralState}
      />
    </Box>
  );
};

export default ActivityReportDialogInnerContent;
