import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

import { White } from "assets/colors";

export default (theme: any) => ({
  MarkerIcon: {
    color: theme.palette.primary.main,
    width: "35px",
    height: "35px",
  },
  MarkerIconRedColor: {
    color: red[600],
  },
  MarkerIconGreenColor: {
    color: green[600],
  },
  MarkerIconSelected: {
    color: theme.palette.secondary.main,
    width: "35px",
    height: "35px",
  },
  Popup: {
    "margin-top": "-20px",
    "z-index": 1,
  },
  Marker: {
    "margin-left": "-28px",
    "margin-top": "-20px",
  },
  LegendContainer: {
    zIndex: 2,
    position: "absolute" as const,
    top: 0,
    right: 0,
    display: "flex",
  },
  PanelContainer: {
    zIndex: 3,
    display: "flex",
    backgroundColor: White,
    width: "500px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    position: "absolute" as const,
    right: 0,
    top: 0,
  },
  MapTooltip: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "18px",
    "& .mapboxgl-popup-content": {
      padding: "8px 16px",
    },
    // "& .mapboxgl-popup-tip": {
    //   display: "none",
    // },
  },
});
