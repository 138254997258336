import authInitState from "containers/authentication/redux/initialState";
import eventsInitState from "containers/calendar/redux/initialState";
import clientsInitState from "containers/clients/redux/initialState";
import customersInitState from "containers/customers/redux/initialState";
import dashboardInitState from "containers/dashboards/redux/initialState";
import documentsInitState from "containers/documents/redux/initialState";
import environmentInitState from "containers/environments/redux/initialState";
import foldersInitState from "containers/folders/redux/initialState";
import listsInitState from "containers/lists/redux/initialState";
import notificationsInitState from "containers/notifications/initialState";
import ordersState from "containers/orders/redux/initialState";
import pictureInitState from "containers/pictures/redux/initialState";
import teamsInitState from "containers/teams/redux/initialState";
import territoriesInitState from "containers/territories/redux/initialState";
import usersInitState from "containers/users/redux/reducer/initialState";
import workflowsInitState from "containers/workflows/redux/initialState";
import IMetaExpressionInitState from "containers/workflows/redux/meta_expressions/initialState";
import triggersState from "containers/workflows/redux/triggers/initialState";
import webhooksState from "containers/workflows/redux/webhooks/initialState";
import jobsInitState from "containers/workflows/subcategories/jobs/redux/initialState";
import ajaxInitState from "redux/reducers/ajaxInitialState";
import appInitState from "redux/reducers/appInitialState";

import IRootState from "./store/model";

const fullInitState: IRootState = {
  app: appInitState,
  triggers: triggersState,
  webhooks: webhooksState,
  orders: ordersState,
  ajax: ajaxInitState,
  pictures: pictureInitState,
  dashboards: dashboardInitState,
  jobs: jobsInitState,
  meta_expressions: IMetaExpressionInitState,
  environments: environmentInitState,
  teams: teamsInitState,
  auth: authInitState,
  documents: documentsInitState,
  lists: listsInitState,
  notify: notificationsInitState,
  customers: customersInitState,
  users: usersInitState,
  workflows: workflowsInitState,
  clients: clientsInitState,
  folders: foldersInitState,
  territories: territoriesInitState,
  calendar_events: eventsInitState,
};

export default fullInitState;
