import "@yaireo/tagify/dist/tagify.css";

import { GreyBorder } from "assets/colors";

import { defaultColor, secondaryColor } from "../../assets/colors";

const styles = (theme: any) => ({
  smallChip: {
    height: "26px",
  },
  inputRoot: {
    "&.EDIT": {
      marginTop: "-5px",
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "calc(50% - 15px)",
      position: "absolute",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    "& .MuiInputBase-input": {
      borderRadius: "5px",
      position: "relative",
      fontSize: "16px",
      lineHeight: "1.25",
      color: "#2c2c2c",
      fontFamily: "BasierCircle",

      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
    },
  },
  InputWrapper: {
    border: "#CCC solid 1px",
    "border-radius": "5px",
  },
  InputWrapperError: {
    border: "#F00 solid 1px",
    "border-radius": "5px",
  },
  InputOuterWrapper: {
    "padding-top": "5px",
    "padding-bottom": "5px",
  },
  FormControlLabel: {
    "margin-left": "auto",
    "margin-right": 0,
  },
  FormGroup: {
    width: "100%",
  },

  Typography: {
    display: "flex",
    "align-items": "center" /* align vertical */,
    "margin-left": 10,
    color: defaultColor,
  },
  WhiteTypography: {
    color: "white",
  },
  ErrorTypography: {
    display: "flex",
    "align-items": "center" /* align vertical */,
    "margin-left": 10,
    color: "#F00",
  },
  UndeterminedTypography: {
    display: "flex",
    "align-items": "center" /* align vertical */,
    "margin-left": 10,
    color: secondaryColor,
  },
  ExpandableChipsContainer: {
    padding: "10px",
    border: `1px solid ${GreyBorder}`,
    borderRadius: "5px",
  },
  ChipContainer: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    width: "100%",
    padding: theme.spacing(0.5),
  },
  ChipContainerEditMode: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
    marginLeft: -16,
    marginTop: -14,
  },
  chipSelected: {
    margin: theme.spacing(0.5),
    background: theme.palette.primary.light,
  },
  chipUnselected: {
    margin: theme.spacing(0.5),
  },
  undeterminedInput: {
    color: secondaryColor,
  },
  input: {
    color: `black !important`,
  },
  inputDarkMode: {
    color: "white !important",
    borderColor: "white !important",
  },
  ImageButton: {
    "margin-left": "auto",
    "margin-right": 0,
  },
  ImageCloseButton: {
    float: "right",
    width: "50px",
    height: "50px",
  },
  ImageContainer: {
    width: "100%",
    background: "#EEE",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center", // align image center horizontally
  },
  Image: {
    maxWidth: "100%",
  },
  FileButton: {
    "margin-left": "auto",
    "margin-right": 0,
  },
  RatingWrapper: {
    margin: "5px 15px",
  },
  FileButtonLabel: {
    display: "flex",
    padding: 0,
    margin: 0,
    cursor: "pointer",
  },
  FileCloseButton: {
    float: "right",
    width: "50px",
    height: "50px",
  },
  FileContainer: {
    width: "100%",
    background: "#EEE",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center", // align image center horizontally
  },
  FileContainerContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  File: {
    maxWidth: "100%",
  },
  SmallIconButton: {
    padding: "6px",
  },
  MultipleFieldsBox: {
    padding: "10px 30px 10px 30px",
  },
  textField: {
    background: "#EEE",
  },
  textFieldWarning: {
    "&  fieldset": {
      border: `2px solid #FFE1B1`,
    },
  },
  smallDisplay: {
    height: 30,
  },
  AddMetaButton: {
    "margin-left": "auto",
    "margin-right": 0,
    outline: "none",
  },
  chip: {
    color: "#FFF",
    marginTop: theme.spacing(0.5),
    background: theme.palette.primary.light,
  },
  listCheckbox: {
    height: 280,
    marginLeft: "2.5%",
    backgroundColor: "#fbfbfb",
    position: "center",
    width: "95%",
    display: "flex",
    flexDirection: "column",
  },
  selectPart: {
    wight: "65%",
  },
  checkboxPart: {
    "&&:hover": {
      color: "#0dcfda",
    },
    "&.Mui-checked": {
      color: "#124e5d",
    },
    marginLeft: "30px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "20px",
  },
  selectDeselectAllButton: {
    display: "flex",
    marginTop: "12px",
  },
  cancelSaveContainer: {
    display: "flex",
    marginTop: "20px",
  },
  selectAllButton: {
    textTransform: "unset !important",
    "&.MuiButton-text": {
      color: "#124e5d",
    },
  },
  deselectAllButton: {
    textTransform: "unset !important",
    "&.MuiButton-text": {
      color: "#6c6c6c",
      textAlign: "left",

      marginRight: "96px",
    },
  },
  saveButton: {
    height: "36px",
    width: "99px",
  },
  cancelButton: {
    height: "36px",
    marginRight: "40px",
    width: "114px",
  },
  chipViewMode: {
    display: "flex",
    width: "60%",
    flexDirection: "row",
    flexWrap: "wrap",
    overflow: "wrap",
    marginLeft: -4,
  },
  viewModeTitle: {
    width: "250px",
    overflow: "wrap",
  },
  createEditModeTitle: {
    width: "250px",
    overflow: "wrap",
    "& .MuiFormLabel-root-MuiInputLabel-root": {
      overflow: "wrap",
    },
  },
  childrenBox: {
    backgroundColor: "#fbfbfb",
    marginTop: 2,
    width: "95%",
    "& .MuiAutocomplete-listbox": {
      padding: 0,
    },
    maxHeight: "264px",
    overflowY: "auto",
    overflowX: "hidden",
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: "#fbfbfb ! important",
    },
    fontFamily: "BasierCircle",
    "& .MuiMenuItem-root": {
      pading: 0,
    },
  },
  paperPart: {
    border: "1px solid #42a5f5",
    marginTop: 16,
    height: "auto",
    fontFamily: "BasierCircle",
  },
  boxContainer: {
    display: "flex",
    justifyContent: "center",
  },
  searchPart: {
    width: "100%",
    "& input::placeholder": {
      fontSize: "16px",
    },
  },
  Input: {
    "& .MuiInputBase-input": {
      borderRadius: "5px",
      position: "relative",
      minHeight: "38px",
      padding: "0px 24px 0px 12px",
      fontSize: "16px",
      lineHeight: "1.25",
      color: "#2c2c2c",
      fontFamily: "BasierCircle",
      boxSizing: "content-box !important", // bug fix for InputGPS, see https://github.com/mapbox/mapbox.js/issues/1375. Without it, the adress input is 38px tall instead of 40,
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      "&:focus": {
        border: `1px solid ${theme.palette.primary.light}`,
      },
      "&:hover:not(:disabled)": {
        border: `1px solid ${theme.palette.primary.light}`,
      },
      "&:disabled": {
        cursor: "not-allowed",
      },
    },
    "& textarea.MuiInputBase-input": {
      padding: "10px 12px",
    },
  },
  searchInput: {
    "& .MuiInputBase-input": {
      borderRadius: "5px",
      border: `1px solid #b8b8b8`,
      fontFamily: "BasierCircle",
      "&:focus": {
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
  },
  createInput: {
    "& .MuiInputBase-input": {
      border: `1px solid #b8b8b8`,
      fontFamily: "BasierCircle",
    },
  },
  errorCreateInput: {
    "& .MuiInputBase-input": {
      border: "1px solid #ff5722",
      fontFamily: "BasierCircle",
    },
  },
  warningCreateInput: {
    "& .MuiInputBase-input": {
      fontFamily: "BasierCircle",
      border: "1px solid #fbc715",
    },
  },
  errorEditInput: {
    "& .MuiInputBase-input": {
      border: "1px solid #ff5722",
      fontFamily: "BasierCircle",
    },
  },
  warningEditInput: {
    "& .MuiInputBase-input": {
      border: "1px solid #fbc715",
      fontFamily: "BasierCircle",
    },
  },
  editInput: {
    "& .MuiInputBase-input": {
      fontFamily: "BasierCircle",
      border: `1px solid #b8b8b8`,
      "&:focus": {
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
  },
  viewInput: {
    "& .MuiInputBase-input": {
      border: `none`,
      color: "black",
    },
  },
  editDisplaying: {
    display: "flex",
    alignItems: "center",
    gridColumnGap: "50px",
    "& .MuiInputBase-input": {
      width: "120%",
      fontFamily: "BasierCircle",
    },
  },
  CustomInputTextBox: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  errorMessage: {
    fontSize: "14px",
    color: "#ff5722",
    "& > span": {
      verticalAlign: "middle",
      marginRight: "4px",
    },
  },
  warningMessage: {
    fontSize: "14px",
    color: "#fbc715",
    "& > span": {
      verticalAlign: "middle",
      marginRight: "4px",
    },
  },
  chipTextField: {
    "& .MuiChip-root": {
      borderRadius: "4px",

      backgroundColor: "#e7edef",
      margin: "6px 4px",
      fontFamily: "BasierCircle",
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-input": {
      maxHeight: 0,
      padding: 0,
    },
    "& .MuiOutlinedInput-adornedEnd, .MuiOutlinedInput-adornedStart": {
      padding: "0 4px",
    },
  },
  chipContainer: {
    display: "flex",
    flexFlow: "row wrap",
    flexGrow: 1,
    width: "85%",
  },
  chipTextFieldEmpty: {
    "& .MuiChip-root": {
      borderRadius: "4px",
      backgroundColor: "#e7edef",
      marginLeft: 4,
      marginRight: 4,
      marginTop: 8,
      fontFamily: "BasierCircle",
      fontSize: "16px",
    },
    "& .MuiInputBase-adornedStart": {
      display: "flex",
      flexFlow: "wrap",
    },
    "& .MuiInputBase-root": {
      height: 44, // prevent "collapse" when remving options
    },
    "& .MuiOutlinedInput-adornedEnd, .MuiOutlinedInput-adornedStart": {
      padding: "0 4px",
    },
  },
  PhoneInput: {
    "& .react-tel-input": {
      fontFamily: "BasierCircle",
      "& .form-control": {
        width: "100%",
        padding: "0px 24px 0px 48px",
        fontSize: "16px",
        minHeight: "38px",
      },
    },
    "& .form-control:focus, .form-control:focus + .flag-dropdown": {
      boxShadow: "none",
      border: `1px solid ${theme.palette.primary.light}`,
    },
    "& .react-tel-input .country-list": {
      borderRadius: "4px",
      overflowX: "hidden",
      width: "232px",
      height: "242px",
      marginTop: "8px",
      boxShadow:
        "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
    },
    "& .react-tel-input .country-list .country": {
      padding: "8px 12px",
    },
    "& .react-tel-input .country-list .search": {
      padding: "8px",
      "& .search-box": {
        width: "100%",
        margin: 0,
      },
    },
    "& .react-tel-input .country-list .search-box": {
      borderRadius: "4px",
    },
  },
});

export default styles;
