import { useContext, useEffect } from "react";

import { Box, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import { Tab, Tabs } from "components/Tab";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { fetchItemsForListAction } from "containers/lists/redux/actions";
import { allListsSelector } from "containers/lists/redux/selectors";
import { PictureContext } from "containers/pictures/PrimaryTable";
import { getPictureInformations } from "containers/pictures/utils";
import { allUsersComposedSelector } from "containers/users/redux/selectors";
import {
  getAllActivities,
  getAllWorkflows,
} from "containers/workflows/redux/selectors";
import { useActions } from "hooks";
import useTranslations from "hooks/useTranslations";
import IPicture from "model/entities/Picture";

import { IImageDetectionDataFE } from "../types";
import PictureDetails from "./PictureDetails";
import ScanResultsTab, { IScanResultsTabProps } from "./ScanResultsTab";

const useStyles = makeStyles(() => ({
  scrollBox: {
    overflowY: "auto",
  },
}));

export interface IPictureSideBarProps
  extends Pick<
    IScanResultsTabProps,
    "onClickScanResult" | "onHoverScanResult" | "activeBoxIds"
  > {
  toggleDisplayDetections?: (value: boolean) => void;
  isLoadingScanResults?: boolean;
  picture: IPicture;
  detections: IImageDetectionDataFE[];
}

function PictureSideBar({
  picture,
  detections,
  activeBoxIds,
  toggleDisplayDetections,
  isLoadingScanResults,
  onClickScanResult,
  onHoverScanResult,
}: IPictureSideBarProps) {
  const client = useSelector(getSelectedClient);
  const showScanResultsTab = !_.isEmpty(client.detectable_lists);
  const lang = useTranslations();
  const langKey =
    lang.containers.pictures.subCategories.pictures.createEditModal;
  const classes = useStyles();

  /**
   * GET PICTURE INFORMATION
   */

  const allActivities = useSelector(getAllActivities);
  const allLists = useSelector(allListsSelector);
  const allUsers = useSelector(allUsersComposedSelector);
  const allWorkflows = useSelector(getAllWorkflows);
  const fetchItemsForList = useActions(fetchItemsForListAction);
  const { pictureInformation, setPictureInformation, loading, setLoading } =
    useContext(PictureContext);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const list_id = picture["list_id"];
      if (list_id) await fetchItemsForList(list_id, { filters: {} });
      const information = getPictureInformations(picture, {
        allActivities,
        allLists,
        allUsers,
        allWorkflows,
      });
      setPictureInformation(information);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picture["id"]]);

  function enableDisplayDetections() {
    if (toggleDisplayDetections) {
      toggleDisplayDetections(true);
    }
  }
  function disableDisplayDetections() {
    if (toggleDisplayDetections) {
      toggleDisplayDetections(false);
    }
  }

  return (
    <Tabs animateTabSwitch>
      <Tab
        id="1"
        label={
          lang.containers.workflows.subCategories.activityReports.detailDialog
            .details
        }
      >
        <Box className={classes.scrollBox}>
          <PictureDetails details={pictureInformation} loading={loading} />
        </Box>
      </Tab>

      <Tab
        id="2"
        label={langKey.detectionsSideBar.options.scanResults}
        overflow="hidden"
        hidden={!showScanResultsTab}
      >
        <Box className={classes.scrollBox}>
          <ScanResultsTab
            activeBoxIds={activeBoxIds}
            isLoadingScanResults={isLoadingScanResults}
            detections={detections}
            onMount={enableDisplayDetections}
            onUnmount={disableDisplayDetections}
            onClickScanResult={onClickScanResult}
            onHoverScanResult={onHoverScanResult}
          />
        </Box>
      </Tab>
    </Tabs>
  );
}

export default PictureSideBar;
