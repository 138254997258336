import _ from "lodash";

import { IFormState } from "hooks/useFormState";

import { IUploadMatrixFilesResult } from "./uploadMatrixFiles";

interface IMapUploadedFilesToFormAnswers {
  formState: IFormState<any>;
  uploadedFiles: IUploadMatrixFilesResult | undefined | null;
}
export const mapUploadedFilesToFormAnswers = ({
  formState,
  uploadedFiles,
}: IMapUploadedFilesToFormAnswers) => {
  const _answers = _.cloneDeep(formState.attributes);
  if (!_.isEmpty(formState.filesToUpload)) {
    if (
      uploadedFiles &&
      uploadedFiles.hasOwnProperty("uploaded") &&
      uploadedFiles.uploaded === true &&
      uploadedFiles.hasOwnProperty("urls")
    ) {
      if (!_.isEmpty(uploadedFiles.urls)) {
        _.forEach(uploadedFiles.urls, (upload) => {
          const { file, upload_id } = upload;
          const attValue = file.split("/")[file.split("/").length - 1];
          _.forEach(_answers, (answer, question) => {
            if (answer == attValue) {
              _answers[question] = { upload_id, url: upload?.url };
              formState.handleInputChange(
                { upload_id, url: upload?.url },
                question
              );
            }
          });
        });
      }
    }
  }
  return _answers;
};
