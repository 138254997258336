import {
  AXIS_TYPE,
  DASHBOARD_TYPE,
  IDashboard as IBEDashboard,
  IKPI as IBEKPI,
  INotification as IBENotification,
  IStreamColumn as IBEStreamColumn,
  ISubQuery as IBESubQuery,
} from "fieldpro-tools/dist/src/types";

import { ITableRowContent } from "model/application/components";
import { IOption } from "model/application/components/index";
import { Flatten } from "utils/types";

export {
  ADD_DATA_STRATEGY,
  AGGREGATOR,
  AGGREGATOR_DIMENSION,
  AXIS_TYPE,
  DASHBOARD_SCOPE,
  DASHBOARD_TYPE,
  DB_TYPE,
  DEFAULT_DASHBOARD,
  KPI_TYPE,
  PIVOT_TYPE,
  QUERY_TYPE,
  TRANSFORMATION_AGGREGATION_PERIOD,
} from "fieldpro-tools/dist/src/types";

export enum DASHBOARD_STATUS_TYPE {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export interface ILabelAliasMapping {
  label: string;
  alias: string;
}

export interface ISubQuery extends IBESubQuery {
  index?: number;
}

export interface IStreamColumn extends IBEStreamColumn {
  index?: number;
}

export interface IDashboardInTable extends ITableRowContent {
  active: boolean;
  title: string;
  description: string;
  number_of_charts: number;
  type: string;
  source: "custom" | "template";
}

export interface IStreamInTable extends ITableRowContent {
  title: string;
  destination_table: string;
  primary_key: string;
}

export interface IDashboard
  extends Omit<
    IBEDashboard,
    | "kpis"
    | "name"
    | "active"
    | "type"
    | "host"
    | "port"
    | "db_name"
    | "user"
    | "password"
    | "list_id"
    | "activity_id"
    | "alias_mapping"
  > {
  teams?: string[];
  kpis: IKPI[];
  name: string;
  active: boolean;
  type: string;
  host: string;
  port: string;
  db_name: string;
  user: string;
  password: string;
  list_id: string;
  activity_id: string;
  alias_mapping?: ILabelAliasMapping[];
  query: any;
  sensible_data_password?: string;
  locked?: boolean;
}
export interface IEmailGeneration
  extends Omit<IBENotification, "web_users" | "mobile_user_roles"> {
  web_users: IOption[];
  mobile_user_roles: IOption[];
}
export interface IGpsRecord {
  acc: number;
  lat: number;
  lng: number;
  timestamp: string;
  user_id: string;
}

interface ITimelineActionsForGPSTrackingBase {
  customer_id: string;
  workflow_id: string;
  report_id: string;
  timestamp: string;
  user_id: string;
}

export type ITimelineActionsForGPSTracking =
  ITimelineActionsForGPSTrackingBase &
    Flatten<
      | {
          action_code: Exclude<
            TIMELINE_EVENT_TYPE,
            // There is a back and forth between these 2 convenctions. Keeping both here to avoid bugs
            | TIMELINE_EVENT_TYPE.CREATE_ACTIVITY_REPORT
            | TIMELINE_EVENT_TYPE.CREATE_SUBMISSION
          >;
        }
      | {
          action_code: TIMELINE_EVENT_TYPE.CREATE_ACTIVITY_REPORT;
          activity_id: string;
        }
      | {
          action_code: TIMELINE_EVENT_TYPE.CREATE_SUBMISSION;
          activity_id: string;
        }
    >;

export interface ICustomerDetailForGPSTracking {
  customer_id: string;
  active: boolean;
  name: string;
  lat?: number;
  lng?: number;
}

export interface IKPIData {
  id?: string;
  label: string;
  data: IGpsRecord[] | ITimelineActionsForGPSTracking[] | any[];
}

export enum TIMELINE_EVENT_TYPE {
  CHECK_IN = "CHECK_IN",
  CHECK_OUT = "CHECK_OUT",
  CREATE_SUBMISSION = "CREATE_SUBMISSION",
  CREATE_ACTIVITY_REPORT = "CREATE_ACTIVITY_REPORT",
}

export type TTimelineData = {
  date: Date;
  action: TIMELINE_EVENT_TYPE;
  user_name?: string;
  user_phone?: string;
  customer_name: string;
  customer_id: string;
  activity_report_id?: string;
  action_code?: TIMELINE_EVENT_TYPE;
  workflow_id?: string;
  user_id?: string;
  timestamp?: any;
  workflow_report_id?: string;
  activity_id?: string;
  visit_id?: string;
};

export interface IKPI<T = any>
  extends Omit<IBEKPI, "id" | "data" | "sub_queries"> {
  data?: T;
  sub_queries?: ISubQuery[];
  _error?: any;
}

export interface INivoConfiguration {
  colors: string[];
  curve: "monotoneX";
  enableArea: boolean;
  direction: "horizontal" | "vertical";
  axeXType: AXIS_TYPE;
  axeYType?: AXIS_TYPE;
  xFormat: string;
  fullWidth: boolean;
  forceSquare: boolean;
  labelTextColor: string;
  withHeaders: boolean;
  tickValues?: any[];
  enableYAxis?: boolean;
  enableXAxis?: boolean;
  minValue?: number;
}

export interface DashboardTable {
  id: string;
  data: string;
  type: DASHBOARD_TYPE;
  active: boolean;
}

export interface ITransformationInTable extends ITableRowContent {
  title: string;
  primary_key: string;
  transformation_aggregation_period: string;
  indexes: string;
}
export interface IEmailGenerationInTable extends ITableRowContent {
  id: string;
  title: string;
  type: string;
  frequency: string;
  date_range: string;
  start_date: Date;
  start_time: Date;
}

export interface IScale {
  minValue: number;
  maxValue: number;
}
