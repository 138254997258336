import React, { createContext, Dispatch, useEffect, useState } from "react";

import { Card, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import CustomButton from "components/Buttons/CustomButton";
import FilterContainer from "components/Filter/FilterContainer";
import { TSnackbarType } from "components/Snackbars/CustomSnackbar";
import CustomTableFooter from "components/Table/CustomTable/CustomTableFooter";
import { fetchItemsForListAction } from "containers/lists/redux/actions";
import { getSelectedList } from "containers/lists/redux/selectors";
import IPictureFilterParams from "containers/pictures/model/IPictureFilterParams";
import IPictureSortBy from "containers/pictures/model/IPictureSortBy";
import useActions from "hooks/useActions";
import { IFilter } from "model/application/Filter";
import TLang from "model/application/Lang";
import { ACTION_TYPE } from "model/application/UIActionTypes";
import { IClient } from "model/entities/Client";
import { IList } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import IPicture from "model/entities/Picture";
import { ITeamSelector } from "model/entities/Team";
import IUser from "model/entities/User";
import {
  CUSTOM_FIELD_TYPE,
  IActivity,
  IQuestion,
  IWorkflow,
} from "model/entities/Workflow";

import {
  SimplePicturesList,
  SortingPicturesListContainer,
} from "./components/PicturesList";
import SortPicturesForm from "./components/SortPicturesForm";
import IPictureInformation from "./model/IPictureInformation";
import { preparePicturesFilters } from "./prepareFilters";
import PrimaryTableLayout from "./PrimaryTableLayout";
import {
  getPictureInformations,
  IPictureWithIndex,
  TypeSortList,
} from "./utils";

interface IListParam {
  key: string;
}

const useStyles = makeStyles({
  container: { padding: "0px 20px" },
  actions: {
    padding: "20px 0 30px 0",
    display: "flex",
    justifyContent: "space-between",
  },
});

export const getItemsFromSelectedLists = (
  listIds: IListParam[] | undefined,
  allLists: IList[]
) => {
  let allItems: IListItem[] = [];
  if (listIds) {
    listIds.forEach((listId) => {
      const list = allLists.find((l) => l.id === listId.key);
      if (list && list.items) {
        allItems = allItems.concat(list.items);
      }
    });
  }

  return allItems;
};

export const getTagsFromSelectedActivities = (
  activitiesIds: IListParam[] | undefined,
  allActivities: IActivity[]
) => {
  const tags: IQuestion[] = [];
  if (activitiesIds) {
    const activities = allActivities.filter((w) =>
      activitiesIds.some((wi) => wi.key === w.id)
    );

    activities.forEach((activity) => {
      activity.questionnaire.questions
        .filter(
          (q) =>
            q.type === CUSTOM_FIELD_TYPE.PICTURE ||
            q.type === CUSTOM_FIELD_TYPE.SIGNATURE
        )
        .forEach((fq) => {
          tags.push(fq);
        });
    });
  }

  return tags;
};

export interface IPrimaryTableProps {
  filterParams: IPictureFilterParams;
  downloadPictures: () => void;
  handleChangeFilter: (filterParams: IPictureFilterParams) => void;
  client: IClient;
  allTeams: ITeamSelector[];
  allActivities: IActivity[];
  allUsers: IUser[];
  allWorkflows: IWorkflow[];
  allLists: IList[];
  pictures: IPicture[];
  sortBy: IPictureSortBy;
  totalPictures: number;
  limit: number;
  currentPage: number;
  onSortingBy: (typeSortList: TypeSortList) => void;
  onChangeRowsPerPage: (value: number) => void;
  onChangePage: (value: number) => void;
  lang: TLang;
}

export interface IPictureContext {
  pictureInformation: IPictureInformation | {};
  loading: boolean;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  setPictureInformation: (info: IPictureInformation) => void;
  setSnackbar: Dispatch<
    React.SetStateAction<{
      message: string;
      type: TSnackbarType;
    } | null>
  >;
}
export const PictureContext = createContext<IPictureContext>({
  pictureInformation: {},
  setPictureInformation: () => {},
  setSnackbar: () => {},
  loading: true,
  setLoading: () => {},
});

const PrimaryTable: React.FunctionComponent<IPrimaryTableProps> = ({
  filterParams,
  downloadPictures,
  handleChangeFilter,
  client,
  allTeams = [],
  allActivities = [],
  allWorkflows = [],
  allUsers = [],
  allLists = [],
  pictures = [],
  sortBy,
  totalPictures,
  limit,
  currentPage,
  onSortingBy,
  onChangeRowsPerPage,
  onChangePage,
  lang,
}) => {
  //Styles
  const classes = useStyles();

  //Hooks
  const [isImageGallery, setIsImageGallery] = useState(false);
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [pictureByListId, setPictureByListId] = useState<IPicture | undefined>(
    undefined
  );
  const customerList = allLists.find((list) => list.id === "customer");
  const [customerFilterOptions] = useState<Array<any> | undefined>(undefined);
  const customerListItemsAsOptions = _.map(
    _.slice(customerList?.items, 0, 10),
    (i) => ({
      key: i._id,
      label: i._name,
    })
  );
  const fetchItemsForList = useActions(fetchItemsForListAction);
  const selectedList = useSelector(getSelectedList);
  const [loadingPictureInfo, setLoadingPictureInfo] = useState(false);

  // Context hooks
  const [pictureInformation, setPictureInformation] = useState<
    IPictureInformation | {}
  >({});

  useEffect(() => {
    let isMounted = true; // check if component is mounted

    const fetchData = async () => {
      try {
        if (pictureByListId !== undefined) {
          const list_id = pictureByListId?.["list_id"];
          if (list_id && fetchItemsForList)
            await fetchItemsForList(list_id, { filters: {} });

          if (isMounted) {
            setPictureByListId(undefined);
            setLoadingPictureInfo(false);
          }
        }
      } catch (error) {
        if (isMounted) {
          setPictureByListId(undefined);
          setLoadingPictureInfo(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Unmounted the component
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureByListId]);

  //Handles
  const picturesWithUniqueId: IPictureWithIndex[] = pictures.map(
    (picture, i) => ({
      ...picture,
      uniqueId: i,
    })
  );

  const openImageGallery = (idImage: number) => {
    if (picturesWithUniqueId[idImage]["list_id"]) {
      setPictureByListId(picturesWithUniqueId[idImage]);
      setLoadingPictureInfo(true);
    }
    setIsImageGallery(true);
    setCurrentImage(idImage);
    setLoadingPictureInfo(false);
  };

  const closeImageGallery = () => {
    setIsImageGallery(false);
    setCurrentImage(0);
    setLoadingPictureInfo(false);
  };

  //Filters
  const filters: IFilter[] = preparePicturesFilters({
    client,
    teams: allTeams,
    activities: allActivities,
    workflows: allWorkflows,
    lists: allLists,
    users: allUsers,
    params: filterParams,
    lang,
    defaultCustomerOptions:
      customerFilterOptions == undefined
        ? customerListItemsAsOptions
        : customerFilterOptions,
  });

  useEffect(() => {
    (async () => {
      for await (const list of client?.detectable_lists || []) {
        await fetchItemsForList(list, {
          filters: {},
          with_count: true,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [snackbar, setSnackbar] = useState<{
    message: string;
    type: TSnackbarType;
  } | null>(null);

  const [loading, setLoading] = useState(true);

  return (
    <PictureContext.Provider
      value={{
        pictureInformation,
        setPictureInformation,
        setSnackbar,
        loading: loading,
        setLoading: setLoading,
      }}
    >
      <PrimaryTableLayout
        isLoadingSnackMessage={loadingPictureInfo}
        snackBarInfo={snackbar}
        setSnackbarInfo={setSnackbar}
        isDownloading={isDownloadModalOpen}
        totalPictures={totalPictures}
        handleCloseDownload={() => setIsDownloadModalOpen(false)}
        handleConfirmDownload={() => {
          downloadPictures();
          setIsDownloadModalOpen(false);
        }}
        openImageModal={isImageGallery}
        handleCloseImageModal={closeImageGallery}
        currentImageIndex={currentImage}
        picturesWithUniqueId={picturesWithUniqueId}
        setCurrentPictureByListId={setPictureByListId}
        handlePictureInformations={(picture) =>
          getPictureInformations(picture, {
            allActivities,
            allWorkflows,
            allUsers,
            allLists,
            list: selectedList,
          })
        }
      >
        <FilterContainer
          filters={filters}
          onChangeFilters={handleChangeFilter}
          fixedPrimaryFilters={true}
        />

        <Card>
          {picturesWithUniqueId.length > 0 && (
            <div className={classes.container}>
              <div className={classes.actions}>
                <SortPicturesForm sortBy={sortBy} onSortingBy={onSortingBy} />
                <div data-testid="download-pictures">
                  <CustomButton
                    type={ACTION_TYPE.DOWNLOAD}
                    onClick={() => {
                      // we will download all pictures of all pages
                      // if more than 100 pictures, show the modal
                      if (totalPictures > 100) {
                        setIsDownloadModalOpen(true);
                      } else {
                        downloadPictures();
                      }
                    }}
                  />
                </div>
              </div>

              {sortBy.name ? (
                <SortingPicturesListContainer
                  pictures={picturesWithUniqueId}
                  onClickPicture={openImageGallery}
                  sortBy={sortBy}
                  allUsers={allUsers}
                  allLists={allLists}
                  allActivities={allActivities}
                  allWorkflows={allWorkflows}
                />
              ) : (
                <SimplePicturesList
                  pictures={picturesWithUniqueId}
                  onClickPicture={openImageGallery}
                />
              )}

              <table>
                <CustomTableFooter
                  count={totalPictures}
                  rowsPerPage={limit}
                  currentPage={currentPage}
                  onChangeRowsPerPage={(e: any) =>
                    onChangeRowsPerPage(e.target.value)
                  }
                  onChangePage={(_e: any, value: any) => onChangePage(value)}
                />
              </table>
            </div>
          )}
        </Card>
      </PrimaryTableLayout>
    </PictureContext.Provider>
  );
};

export default PrimaryTable;
