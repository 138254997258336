import React from "react";

import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import IPictureSortBy from "containers/pictures/model/IPictureSortBy";
import useTranslations from "hooks/useTranslations";

import { TypeSortList, TypeSortValue } from "../utils";

const useStyles = makeStyles({
  button: { marginRight: "10px" },
});

interface IButtonSortProps {
  sortBy: IPictureSortBy;
  typeSortList: TypeSortList;
  name: string;
  onClick: (typeSortList: TypeSortList) => void;
}

export const ButtonSort: React.FunctionComponent<IButtonSortProps> = ({
  sortBy,
  typeSortList,
  name,
  onClick,
}) => {
  const classes = useStyles();
  const color = sortBy.name === typeSortList ? "secondary" : "primary";
  let Icon;
  if (sortBy.name === typeSortList) {
    Icon = sortBy.value === TypeSortValue.Desc ? ArrowDownward : ArrowUpward;
  }

  return (
    <Button
      variant="contained"
      color={color}
      onClick={() => onClick(typeSortList)}
      endIcon={Icon ? <Icon /> : undefined}
      classes={{ root: classes.button }}
    >
      {name}
    </Button>
  );
};

interface ISortPicturesFormProps {
  sortBy: IPictureSortBy;
  onSortingBy: (typeSortList: TypeSortList) => void;
}

const SortPicturesForm: React.FunctionComponent<ISortPicturesFormProps> = ({
  sortBy,
  onSortingBy,
}) => {
  const lang = useTranslations();
  const langKey =
    lang.containers.pictures.subCategories.pictures.createEditModal
      ?.pictureFilter.options;

  return (
    <div>
      <ButtonSort
        sortBy={sortBy}
        typeSortList={TypeSortList.Date}
        name={langKey?.byDate || ""}
        onClick={onSortingBy}
      />
      <ButtonSort
        sortBy={sortBy}
        typeSortList={TypeSortList.User}
        name={langKey?.byUser || ""}
        onClick={onSortingBy}
      />
      <ButtonSort
        sortBy={sortBy}
        typeSortList={TypeSortList.Tag}
        name={langKey?.byTag || ""}
        onClick={onSortingBy}
      />
    </div>
  );
};

export default SortPicturesForm;
