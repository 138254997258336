import { useState } from "react";

import "flag-icons/css/flag-icons.min.css";

import { FormControl, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import _ from "lodash";

import { Black, GreyLight, tealLight, White } from "assets/colors";
import DefaultChip from "components/Chip/DefaultChip";
import InputBaseLayout from "components/Input/InputBaseLayout";
import useTranslations from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { TInputAttributeLang } from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";

const styles = (theme: any) => ({
  formControl: {
    minWidth: 150,
    "& .MuiSelect-select": {
      color: "#2c2c2c",
      fontSize: "16px",
      fontFamily: "BasierCircle",
    },
    "& .MuiSelect-icon": {
      top: "unset",
    },
  },
  disabledFormControl: {
    minWidth: 150,
    "& .MuiSelect-select": {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "16px",
      fontFamily: "BasierCircle",
      cursor: "not-allowed",
    },
    "& .MuiSelect-icon": {
      top: "unset",
    },
  },
  undeterminedInput: {
    color: theme.palette.primary.main,
  },
  input: {
    color: theme.palette.default.main,
    marginLeft: -1,
  },
  select: {
    textOverflow: "clip",
    "&.VIEW": {
      borderColor: "transparent",
    },
  },
  flagDisplay: {
    width: "64px",
  },
  formControlFlag: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 90,
  },
  menuItem: {
    fontSize: "16px",
    fontFamily: "BasierCircle",
  },
});

const useStyles = makeStyles(styles as any);

export interface IInputSelect<T = string> {
  dataTestId?: string;
  options: IOption<T>[];
  name: string;
  viewMode?: TViewMode;
  onChange: (optSelected: T, variableName: string) => void;
  title?: string;
  value?: T;
  smallDisplay?: boolean;
  isUndetermined?: boolean;
  disabled?: boolean;
  required?: boolean;
  clearable?: boolean;
  disabledReason?: string;
  error?: any;
  noOptionsText?: string;
  lang?: Pick<TInputAttributeLang, "title" | "tooltip">;
  placeholder?: string;
  viewStacked?: boolean;
  highlightContent?: boolean;

  formatLabelDisplaying?: (props: any) => JSX.Element;
  ariaLabel?: string;
}
export const InputSelect = <T,>({
  value,
  title = "",
  name,
  onChange,
  error,
  viewMode = "CREATE",
  disabled = false,
  isUndetermined: initIsUndetermined,
  smallDisplay,
  required,
  lang,
  options,
  placeholder,
  noOptionsText,
  clearable = false,
  viewStacked = false,
  dataTestId,
  formatLabelDisplaying: optionComponent,
  highlightContent,
  disabledReason,

  ariaLabel,
}: IInputSelect<T>) => {
  const classes = useStyles();
  const rootLang = useTranslations();
  const [isUndetermined, setIsUndetermined] = useState(initIsUndetermined);

  const handleChange = (event: any) => {
    event.target.name = name;
    onChange(event.target.value, name);
    setIsUndetermined(false);
  };

  const compactOptions = _.compact(options);

  const getDisplayValue = (value: T | undefined) => {
    if (!value) {
      return value || "";
    }
    const option = _.find(options, { key: value }) as IOption<T> | undefined;
    if (option) {
      return option?.label;
    }
    return value;
  };

  const label = lang ? lang.title : title;

  if (viewMode === "VIEW") {
    const chipLabel = getDisplayValue(value) || placeholder;
    return (
      <InputBaseLayout
        dataTestId={dataTestId}
        label={label}
        tooltip={disabledReason ? undefined : lang?.tooltip}
        disabled={disabled}
        required={required}
        viewMode={viewMode}
        error={error}
        viewStacked={viewStacked}
      >
        {chipLabel && (
          <DefaultChip
            style={{
              color: highlightContent ? White : Black,
              width: "fit-content",
              height: "36px",
              background: highlightContent ? tealLight : GreyLight,
            }}
            label={chipLabel}
          />
        )}
      </InputBaseLayout>
    );
  }

  return (
    <InputBaseLayout
      disabled={disabled}
      dataTestId={dataTestId}
      tooltip={disabledReason ? undefined : lang?.tooltip}
      label={label}
      required={required}
      viewMode={viewMode}
      error={error}
      disabledReason={disabledReason}
    >
      <FormControl
        fullWidth={!smallDisplay}
        variant="outlined"
        className={disabled ? classes.disabledFormControl : classes.formControl}
        error={error ? true : false}
        disabled={disabled}
      >
        {!_.isEmpty(compactOptions) ? (
          <Select
            name={name}
            onChange={handleChange}
            inputProps={{
              className: isUndetermined
                ? classes.undeterminedInput
                : classes.input,
            }}
            classes={{
              select: classNames(classes.select, viewMode),
            }}
            value={value}
            defaultValue=""
            fullWidth
            margin="dense"
            renderValue={!value ? () => placeholder : undefined}
            displayEmpty
            disabled={disabled}
            aria-label={ariaLabel}
          >
            {clearable && <MenuItem value="">&nbsp;</MenuItem>}

            {_.map(compactOptions, (option: any) => {
              if (option) {
                const { key, label } = option;
                return (
                  <MenuItem
                    className={classes.menuItem}
                    key={key}
                    value={key as unknown as string}
                  >
                    {optionComponent ? optionComponent(option) : label}
                  </MenuItem>
                );
              }
            })}
          </Select>
        ) : (
          <div>{noOptionsText || rootLang.genericTerms.noOptionsAvailable}</div>
        )}
      </FormControl>
    </InputBaseLayout>
  );
};

export default InputSelect;
