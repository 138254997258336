const styles = (theme: {
  spacing(arg0: number): unknown;
  palette: { background: { paper: any } };
  shadows: any[];
}) => ({
  root: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  leftContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  detectionViewContainer: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    gridColumnGap: "8px",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
  },
  rightContainer: {
    width: "30%",
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    zIndex: 1300,
    display: "grid",
    gridTemplateRows: "72px auto",
  },
  closeButton: {
    position: "fixed" as any,
    top: theme.spacing(1) as any,
    right: theme.spacing(1) as any,
    zIndex: 1400,
  },
  closeIconContainer: {
    display: "flex",
    justifyContent: "right",
    alignContent: "center",
    padding: "24px",
    background: "white",
  },
});

export default styles;
