import _ from "lodash";
import { Dispatch } from "redux";

import { getLang } from "containers/authentication/redux/selector";
import { prepareListSchemaForFrontend } from "containers/lists/redux/utils";
import { IQuery } from "containers/lists/utils";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import { getSuccessNotificationMessage } from "lang/utils";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IListSchema } from "model/entities/List";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  IAdditionalQueryOption,
  IGenericQueryToSendToBackend,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import * as lang from "../../../lang";
import * as notificationLevels from "../../notifications/actionLevels";
import * as notificationTypes from "../../notifications/actionTypes";
import { ITerritory, ITerritoryBE } from "../model";
import {
  formatTerritoryForBE,
  formatTerritoryForFE,
} from "../utils/formatTerritory";
import {
  createTerritoriesBeginAction,
  createTerritoriesFailureAction,
  createTerritoriesSuccessAction,
  deleteTerritoriesBeginAction,
  deleteTerritoriesFailureAction,
  deleteTerritoriesSuccessAction,
  fetchTerritoriesFailureAction,
  fetchTerritoriesSchemaFailureAction,
  fetchTerritoriesSchemaSuccessAction,
  fetchTerritoriesSuccessAction,
  fetchTerritorriesBeginAction,
  fetchTerritorriesSchemaBeginAction,
  setFilterTerritoriesQuery,
  updateTerritoriesBeginAction,
  updateTerritoriesFailureAction,
  updateTerritoriesSchemaBeginAction,
  updateTerritoriesSchemaFailureAction,
  updateTerritoriesSchemaSuccessAction,
  updateTerritoriesSuccessAction,
} from "./actionCreators";
import {
  createTerritoriesApiCall,
  deleteTerritoriesApiCall,
  fetchTerritoriesApiCall,
  fetchTerritoriesSchemaApiCall,
  updateTerritoriesApiCall,
  updateTerritoriesSchemaApiCall,
} from "./api";

//interface
export interface ITerritoriesActions {
  fetchTerritoriesAction: typeof fetchTerritoriesAction;
  createTerritoriesAction: TCreateTerritoriesActionFunc;
  editTerritoriesAction: TEditTerritoriesActionFunc;
  deleteTerritoriesAction: TDeleteTerritoriesActionFunc;
}

//type
export type TFetchTerritoriesActionFunc = typeof fetchTerritoriesAction;
export type TFetchTerritoriesSchemaActionFunc =
  typeof fetchTerritoriesSchemaAction;

type TCreateTerritoriesActionFunc = (
  territories: ITerritory[]
) => IDispatchAndGetState<any>;

type TEditTerritoriesActionFunc = (items: any[]) => IDispatchAndGetState<void>;

type TEditTerritoriesSchemaActionFunc = (
  schema: IListSchema[]
) => IDispatchAndGetState<void>;

type TDeleteTerritoriesActionFunc = (
  ids: string[]
) => IDispatchAndGetState<void>;

export const fetchTerritoriesAction = (
  query: IGenericQueryToSendToBackend = { filters: {} },
  additionnalOpt: IAdditionalQueryOption = {},
  frontendFilters: IQuery = {} // only to pass it to the success dispatch function to update the filters on the frontend
) => {
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];

    if (additionnalOpt.skipBackendCall) {
      // If skipBackendCall is true, we do not make an API call
      dispatch(setFilterTerritoriesQuery(query));
      return Promise.resolve();
    }

    dispatch(ajaxRequestAction());
    dispatch(fetchTerritorriesBeginAction());

    return fetchTerritoriesApiCall({
      ...query,
      offset: query.offset ?? 0,
    })
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus<{
          territories: ITerritoryBE[];
          item_count: number;
        }>(serverResponse);

        const { territories, item_count } = data;

        const territoriesWithFeatures = _.compact(
          _.map(territories, formatTerritoryForFE)
        );

        dispatch(ajaxSuccessAction());
        dispatch(
          fetchTerritoriesSuccessAction(
            territoriesWithFeatures,
            item_count,
            frontendFilters
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchTerritoriesError",
          error,
          fetchTerritoriesFailureAction,
          currLang
        );
      });
  };
};

export const fetchTerritoriesSchemaAction = () => {
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(fetchTerritorriesSchemaBeginAction());

    return fetchTerritoriesSchemaApiCall()
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus<{
          schema: IListSchema[];
        }>(serverResponse);

        const { schema } = data;

        dispatch(ajaxSuccessAction());
        dispatch(
          fetchTerritoriesSchemaSuccessAction(
            prepareListSchemaForFrontend(schema as any[])
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchTerritoriesSchemaError",
          error,
          fetchTerritoriesSchemaFailureAction,
          currLang
        );
      });
  };
};

export const createTerritoriesAction: TCreateTerritoriesActionFunc = (
  territories: ITerritory[]
) => {
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(createTerritoriesBeginAction());

    const formattedTerritories = _.compact(
      _.map(territories, formatTerritoryForBE)
    );

    return createTerritoriesApiCall(formattedTerritories)
      .then((response) => {
        extractDataAndCheckErrorStatus(response);

        dispatch(ajaxSuccessAction());
        dispatch(createTerritoriesSuccessAction(territories));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,

            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.TERRITORY,
              territories.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateTerritoriesError",
          error,
          createTerritoriesFailureAction,
          currLang
        );
      });
  };
};

export const deleteTerritoriesAction: TDeleteTerritoriesActionFunc = (
  itemIds: string[]
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(deleteTerritoriesBeginAction());

    return deleteTerritoriesApiCall(itemIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteTerritoriesSuccessAction(itemIds));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.TERRITORY,
              itemIds.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteTerritoriesError",
          error,
          deleteTerritoriesFailureAction,
          currLang
        );
      });
  };
};

export const editTerritoriesAction: TEditTerritoriesActionFunc = (
  territoryItems: ITerritoryBE[]
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(updateTerritoriesBeginAction());

    // TODO: fix "any" types and eventual bugs
    return updateTerritoriesApiCall(territoryItems as any)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(updateTerritoriesSuccessAction(territoryItems));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.TERRITORY,
              territoryItems.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "EditTerritoriesError",
          error,
          updateTerritoriesFailureAction,
          currLang
        );
      });
  };
};

export const editTerritoriesSchemaAction: TEditTerritoriesSchemaActionFunc = (
  schema
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(updateTerritoriesSchemaBeginAction());

    return updateTerritoriesSchemaApiCall(schema)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(updateTerritoriesSchemaSuccessAction(schema));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.TERRITORY,
              schema.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "EditTerritoriesSchemaError",
          error,
          updateTerritoriesSchemaFailureAction,
          currLang
        );
      });
  };
};
