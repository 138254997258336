import _ from "lodash";

import {
  getMatrixFieldsSchema,
  getNameKey,
  getTagKey,
} from "components/Input/InputMatrix/utils/getQuestionColumns";
import { TABLE_COLUMN_TYPE, TColumnType } from "components/Table/model";
import getItemName from "containers/lists/utils/getItemName";
import { formatAugmentedCustomFieldValues } from "containers/workflows/subcategories/activities/utils/activityReports/formatAugmentedActivityReport";
import { IDynamicObjects } from "model/application/DynamicObjects";
import TLang from "model/application/Lang";
import { CUSTOM_FIELD_TYPE, IList, IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import { IQuestion } from "model/entities/Workflow";

import { getColumnTypeFromAttrType } from "../../containers/workflows/subcategories/activities/utils/activityReports";
import { formatCustomFieldValues } from "./customFieldValues";

interface IGetMatrixCategories {
  list?: IList;
  categoryTag?: string;
}

export const getMatrixCategories = ({
  list,
  categoryTag,
}: IGetMatrixCategories) => {
  const categoryAttribute = _.find(list?.schema, { column_tag: categoryTag });
  return categoryAttribute?.options;
};

interface IGetMatrixColumns {
  question?: IQuestion | IListSchema;
  lang: TLang;
}

export const getMatrixColumns = ({ question, lang }: IGetMatrixColumns) => {
  const nameColumn = {
    name: "_name",
    type: TABLE_COLUMN_TYPE.TEXT,
    label:
      lang.containers.workflows.subCategories.activityReports.columns.itemName,
  };

  if (!question) return null;

  const matrixSubCfs = getMatrixFieldsSchema(question) as
    | IListSchema[]
    | IQuestion[];

  const questionColumns: any[] = _.map(
    _.filter(
      matrixSubCfs,
      (q: IQuestion | IListSchema) => q.type !== CUSTOM_FIELD_TYPE.PLAIN_TEXT
    ),
    (cf: IQuestion | IListSchema) => {
      return getColumnFromQuestion(cf);
    }
  );

  return _.concat<TColumnType>([nameColumn], questionColumns);
};

const getColumnFromQuestion = (
  question: IQuestion | IListSchema
): TColumnType => {
  const tagKey = getTagKey(question);
  const fieldName = getNameKey(question);
  return {
    name: question[tagKey],
    label: question[fieldName],
    type: getColumnTypeFromAttrType(question.type),
  };
};

interface IGetMatrixRows {
  dynamicObjects: IDynamicObjects[];
  fieldsSchema: IQuestion[] | IListSchema[];
  items: IListItem[];
  lists: IList[];
  lang: TLang;
}

export const getMatrixRows = ({
  dynamicObjects,
  fieldsSchema,
  items,
  lists,
  lang,
}: IGetMatrixRows) => {
  const rowData = _.compact(
    _.map(dynamicObjects, ({ customFieldValues, metadata }) => {
      const formattedFields = formatCustomFieldValues({
        customFieldValues,
        fieldsSchema,
        lists,
        lang,
      });

      if (metadata?.["_object_id"]) {
        const referencedItem = _.find(items, { _id: metadata._object_id });
        return {
          _item_id: metadata._object_id,
          // TODO: hack to display at least something if item is not found
          _name: getItemName(referencedItem) ?? metadata._object_id,
          _category_id: metadata._category_id,
          ...formattedFields,
        };
      }
    })
  );
  return rowData;
};

// TODO: MERGE WITH getMatrixRows
// if we can do the same "augmented" refacto for ITEMS
interface IGetAugmentedMatrixRows {
  dynamicObjects: IDynamicObjects[];
  fieldsSchema: IQuestion[];
  lists: IList[];
  lang: TLang;
}

/**
 * IMPORTANT: DO NOT USE list.items /!\ ONLY list.schema allowed here
 */
export const getAugmentedMatrixRows = ({
  dynamicObjects,
  fieldsSchema,
  lists,
  lang,
}: IGetAugmentedMatrixRows) => {
  const rowData = _.compact(
    _.map(dynamicObjects, ({ customFieldValues, metadata }) => {
      const formattedFields = formatAugmentedCustomFieldValues(
        customFieldValues,
        fieldsSchema,
        lists,
        lang
      );

      if (metadata?.["_object_id"]) {
        return {
          _item_id: metadata._object_id,
          _name: metadata._displayed_name,
          _category_id: metadata._category_id,
          ...formattedFields,
        };
      }
    })
  );
  return rowData;
};
