import {
  BUSINESS_ERROR,
  CUSTOM_FIELD_TYPE,
  FETCH_ITEMS_LIMIT,
} from "fieldpro-tools";
import _ from "lodash";
import { Dispatch } from "redux";

import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import {
  LevelNotification,
  TypeNotification,
} from "model/application/Notification";
import { IList, IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import { appAddNotification } from "redux/actionCreators/appActionCreator";
import IRootState, { IDispatchAndGetState } from "redux/store/model";

import * as lang from "../../../lang";
import { getSuccessNotificationMessage } from "../../../lang/utils";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  IAdditionalQueryOption,
  IGenericQueryToSendToBackend,
  treatErrorNotification,
} from "../../../redux/actions/appActions";
import { getLang } from "../../authentication/redux/selector";
import { showNotificationActionCreator } from "../../notifications/actionCreator";
import * as notificationLevels from "../../notifications/actionLevels";
import * as notificationTypes from "../../notifications/actionTypes";
import { TItemMobileUserLink } from "../subcategories/items/utils/bulkModalUtils";
import {
  archiveItemsBeginAction,
  archiveItemsFailureAction,
  archiveItemsSuccessAction,
  archiveListBeginAction,
  archiveListFailureAction,
  archiveListSuccessAction,
  assignItemsBeginAction,
  assignItemsFailureAction,
  assignItemsSuccessAction,
  createItemsBeginAction,
  createItemsFailureAction,
  createItemsSuccessAction,
  createListBeginAction,
  createListFailureAction,
  createListSuccessAction,
  deleteItemsBeginAction,
  deleteItemsFailureAction,
  deleteItemsSuccessAction,
  deleteListBeginAction,
  deleteListFailureAction,
  deleteListSuccessAction,
  downloadListItemsBeginAction,
  downloadListItemsFailureAction,
  downloadListItemsSuccessAction,
  editAttributeTagBeginAction,
  editAttributeTagFailureAction,
  editAttributeTagSuccessAction,
  fetchCustomerReportsPicturesBeginAction,
  fetchCustomerReportsPicturesFailureAction,
  fetchCustomerReportsPicturesSuccessAction,
  fetchItemsForListBeginAction,
  fetchItemsForListFailureAction,
  fetchItemsForListSuccessAction,
  fetchListOptionsFailureAction,
  fetchListOptionsSuccessAction,
  fetchListsForClientBeginAction,
  fetchListsForClientFailureAction,
  fetchListsForClientSuccessAction,
  restoreItemsBeginAction,
  restoreItemsFailureAction,
  restoreItemsSuccessAction,
  restoreListBeginAction,
  restoreListFailureAction,
  restoreListSuccessAction,
  selectListActionCreator,
  unassignItemsBeginAction,
  unassignItemsFailureAction,
  unassignItemsSuccessAction,
  unassignItemsUsingLinksAction,
  updateItemsBeginAction,
  updateItemsFailureAction,
  updateItemsSuccessAction,
  updateListBeginAction,
  updateListFailureAction,
  updateListSuccessAction,
  uploadFileBeginAction,
  uploadFileFailureAction,
  uploadFileSuccessAction,
} from "./actionCreators";
import {
  archiveItemsApiCall,
  archiveListApiCall,
  assignItemsApiCall,
  createItemsApiCall,
  createListApiCall,
  deleteItemsApiCall,
  deleteListApiCall,
  downloadListItemsApiCall,
  downloadQRCodesApiCall,
  editAttributeTagApiCall,
  fetchCustomerReportsPicturesApiCall,
  fetchItemsForListApiCall,
  fetchListsByClientApiCall,
  getListOptionsApiCall,
  restoreItemsApiCall,
  restoreListApiCall,
  TFetchListsByClientResponse,
  unassignItemsApiCall,
  unassignMobileUsersApiCall,
  updateItemsApiCall,
  updateListApiCall,
  uploadFileApiCall,
} from "./api";
import { getListById } from "./selectors";
import { prepareListsForFrontend } from "./utils";
/**
 * Fetch Lists per client Action dispatches action creators to redux store and makes api calls to fetch all lists of the client
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IFetchListsForClientActionFunc = () => IDispatchAndGetState<void>;
export const fetchListsForClientAction: IFetchListsForClientActionFunc = () => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchListsForClientBeginAction());

    return fetchListsByClientApiCall()
      .then((serverResponse) => {
        const data =
          extractDataAndCheckErrorStatus<TFetchListsByClientResponse>(
            serverResponse
          );
        const { lists } = data;
        dispatch(ajaxSuccessAction());
        const formattedLists = prepareListsForFrontend(lists);
        dispatch(fetchListsForClientSuccessAction(formattedLists));

        formattedLists.forEach((list) => {
          if (list.big_list) {
            // add notification
            dispatch(
              appAddNotification({
                level: LevelNotification.Warning,
                type: TypeNotification.PartialFetchListItems,
                id: list.id,
                label: `The list ${list.name} is too large to be downloaded entirely. ${FETCH_ITEMS_LIMIT} items can be fetched at the same time.`,
              })
            );
          }
        });
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchListsForClientError",
          error,
          fetchListsForClientFailureAction,
          currLang
        );
      });
  };
};

/**
 * Create List Action dispatches action creators to redux store and makes api calls to create a list
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
type ICreateListActionFunc = (
  actionName: string,
  list: IList
) => IDispatchAndGetState<void>;
export const createListAction: ICreateListActionFunc = (
  actionName: string,
  list: IList
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());

    dispatch(createListBeginAction());

    interface IDataList {
      list_id: string;
    }

    return createListApiCall(actionName, list)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus<IDataList>(serverResponse);
        const { list_id } = data;

        // build the list object
        const updatedList = {
          ...list,
          id: list_id,
          active: true,
        };

        dispatch(ajaxSuccessAction());
        dispatch(createListSuccessAction(updatedList));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.LIST,
              list.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateListError",
          error,
          createListFailureAction,
          currLang
        );
      });
  };
};

/**
 * Update List Action dispatches action creators to redux store and makes api calls to delete a list by id
 * @param {Object} newList New properties of the list
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IEditListActionFunc = (
  actionName: string,
  list: IList
) => IDispatchAndGetState<void>;
export const editListAction: IEditListActionFunc = (
  actionName: string,
  newList: IList
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(updateListBeginAction());

    return updateListApiCall(actionName, newList.id, newList)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { new_list } = data || {};
        dispatch(ajaxSuccessAction());
        dispatch(
          updateListSuccessAction(prepareListsForFrontend([new_list])[0])
        );
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.LIST,
              new_list.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateListError",
          error,
          updateListFailureAction,
          currLang
        );
      });
  };
};

/**
 * Delete List Action dispatches action creators to redux store and makes api calls to delete a list by id
 * @param {String} listId List id to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IDeleteListActionFunc = (
  actionName: string,
  listId: string
) => IDispatchAndGetState<void>;
export const deleteListAction: IDeleteListActionFunc = (
  actionName: string,
  listId: string
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    // this will be used to update the store, removing this list from the client

    dispatch(ajaxRequestAction());
    dispatch(deleteListBeginAction());

    return deleteListApiCall(actionName, listId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteListSuccessAction(listId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.LIST
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteListError",
          error,
          deleteListFailureAction,
          currLang
        );
      });
  };
};

/**
 * Archive List Action dispatches action creators to redux store and makes api calls to archive a list by id
 * @param {String} listId List id to archive
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IArchiveListActionFunc = (
  actionName: string,
  listId: string
) => IDispatchAndGetState<void>;
export const archiveListAction: IArchiveListActionFunc = (
  actionName: string,
  listId: string
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(archiveListBeginAction());

    return archiveListApiCall(actionName, listId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(archiveListSuccessAction(listId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.LIST
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveListError",
          error,
          archiveListFailureAction,
          currLang
        );
      });
  };
};

/**
 * Restore List Action dispatches action creators to redux store and makes api calls to restore a list by id
 * @param {String} listId List id to restore
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IRestoreListActionFunc = (
  actionName: string,
  listId: string
) => IDispatchAndGetState<void>;
export const restoreListAction: IRestoreListActionFunc = (
  actionName: string,
  listId: string
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(restoreListBeginAction());

    return restoreListApiCall(actionName, listId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreListSuccessAction(listId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.LIST
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreListError",
          error,
          restoreListFailureAction,
          currLang
        );
      });
  };
};

export interface IFetchItemsResponseData {
  items?: IListItem[];
  item_count?: number;
}

export const fetchItemsForListAction = (
  listId: string,
  query: IGenericQueryToSendToBackend,
  additionalOpt: IAdditionalQueryOption = {},
  frontendFilters = {}, // only to pass it to the success dispatch function to update the filters on the frontend
  storeItemsCount: boolean = false // store the count only for the first fetch (without any filters)
) => {
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];

    const list = getListById(getState())[listId];
    if (!list) return;

    query.offset = query.offset ?? 0;
    query.limit = query.limit ?? FETCH_ITEMS_LIMIT;
    query.include_linked_options = query.include_linked_options ?? true;

    dispatch(ajaxRequestAction());

    if (!additionalOpt.skipStoreUpdate) {
      dispatch(fetchItemsForListBeginAction(query.detail));
    }

    if (
      additionalOpt.skipBackendCall ||
      (_.keys(query).length === 0 && !list?.partial_data)
    ) {
      const successAction = fetchItemsForListSuccessAction(
        listId,
        list.items,
        list.item_count,
        query.offset,
        !!additionalOpt.eraseExisting,
        list.schema,
        query.detail ? undefined : frontendFilters, // if fetch item detail, we don't reset the filters
        storeItemsCount,
        additionalOpt.storeAsTableItems,
        additionalOpt.clearExistingTableItems
      );

      // no need for calling the backend. Call directly the reducer
      dispatch(successAction);
      return;
    }
    return new Promise<IFetchItemsResponseData | undefined>((resolve) => {
      fetchItemsForListApiCall(listId, query)
        .then((response) => {
          const data =
            extractDataAndCheckErrorStatus<IFetchItemsResponseData>(response);

          const { items = [], item_count = 0 } = data || {};

          dispatch(ajaxSuccessAction());

          if (!additionalOpt.skipStoreUpdate) {
            const successAction = fetchItemsForListSuccessAction(
              listId,
              items,
              item_count,
              query.offset ?? 0,
              !!additionalOpt.eraseExisting,
              list.schema,
              query.detail ? undefined : frontendFilters, // if fetch item detail, we don't reset the filter,
              storeItemsCount,
              additionalOpt.storeAsTableItems,
              additionalOpt.clearExistingTableItems
            );

            dispatch(successAction);
          }

          resolve(data);
        })
        .catch((error) => {
          // Error is handled here...
          treatErrorNotification(
            dispatch,
            "FetchItemsForListError",
            error,
            fetchItemsForListFailureAction,
            currLang
          );

          // ...so do not reject here, as we don't want uncaught exception
          resolve(undefined);
        });
    });
  };
};

export const formatBackendQuery = ({
  backendQuery,

  list,
}: {
  backendQuery: any;
  list: IList;
}) => {
  return Object.keys(backendQuery).reduce((acc, curr) => {
    const att = list.schema.find((a) => a.column_tag === curr);
    if (
      ["_updated_at", "_created_at"].includes(curr) ||
      (att && att.type === CUSTOM_FIELD_TYPE.DATE_PICKER)
    ) {
      // expected format: { datetag: {start:"2020-...", end:"2020-..."} }
      acc[curr] = {
        start: backendQuery[curr].startDate,
        end: backendQuery[curr].endDate,
      };
    } else acc[curr] = backendQuery[curr];
    return acc;
  }, {});
};

const DOWNLOAD_ITEMS_LIMIT = 100000; // if we need infinite limit, put -1

type IDownloadItemsForListActionFunc = (
  listId: string,
  query: IGenericQueryToSendToBackend
) => any;

export const downloadItemsForListAction: IDownloadItemsForListActionFunc = (
  listId: string,
  query: IGenericQueryToSendToBackend
) => {
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(downloadListItemsBeginAction());

    query.limit = DOWNLOAD_ITEMS_LIMIT;

    return downloadListItemsApiCall(listId, query)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(downloadListItemsSuccessAction());
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.notifications.successNotifications.downloadList
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "downloadListItemsError",
          error,
          downloadListItemsFailureAction,
          currLang
        );
      });
  };
};

// Note: to avoid a lot of dupplication of code, since the download item and the download QR Codes are very similar,
// we are using some of the same functions.
export const downloadQRCodesForListAction: IDownloadItemsForListActionFunc = (
  listId: string,
  query: IGenericQueryToSendToBackend
) => {
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(downloadListItemsBeginAction());

    return downloadQRCodesApiCall(listId, query)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(downloadListItemsSuccessAction());
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.notifications.successNotifications.downloadList
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "downloadQRCodesError",
          error,
          downloadListItemsFailureAction,
          currLang
        );
      });
  };
};

/**
 * Create Item Action dispatches action creators to redux store and makes api calls to create an item
 * @param {String} listId List id
 * @param {Array} items An array of the attributes of the list with their values
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
type ICreateItemsActionFunc = (
  actionName: string,
  listId: string,
  items: IListItem[],
  listSchema: IListSchema[]
) => IDispatchAndGetState<any>;
export const createItemsAction: ICreateItemsActionFunc = (
  actionName: string,
  listId: string,
  items: IListItem[],
  listSchema: IListSchema[]
) => {
  items.forEach((item) => {
    if (item._owners && item._owners[0] === "") {
      delete item._owners;
    }
  });
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(createItemsBeginAction());

    return createItemsApiCall(actionName, listId, items, listSchema)
      .then((serverResponse) => {
        const updatedItems = extractDataAndCheckErrorStatus(serverResponse);

        const newItems = _.map(items, (item) => {
          const returnValue = _.find(updatedItems, { id: item._id }) ?? {};
          delete returnValue["id"];
          return {
            ...item,
            ...returnValue,
          };
        });
        dispatch(createItemsSuccessAction(listId, newItems, listSchema));
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateItemError",
          error,
          createItemsFailureAction,
          currLang
        );
      });
  };
};

/**
 * Fetch customer's reports pictures
 * @param {Array} customer_id string
 * @returns {Promise}
 */
export const fetchCustomerReportsPicturesAction = (
  customer_id: string,
  filters?: any
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchCustomerReportsPicturesBeginAction());

    return new Promise(function (resolve, reject) {
      fetchCustomerReportsPicturesApiCall(customer_id, filters)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchCustomerReportsPicturesSuccessAction(
              data.customer_reports_pictures
            )
          );
          resolve(data as any);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "fetchCustomerReportsPicturesError",
            error,
            fetchCustomerReportsPicturesFailureAction,
            currLang
          );
          reject(error);
        });
    });
  };
};

/**
 * Upload image
 * @param {Array} files file objects to upload
 * @param {Array} fileMetas array of meta info to give to the backend for each files
 * @returns {Promise}
 */
type IUploadFileActionFunc = (
  actionName: string,
  files: any,
  fileMetas: any
) => any;
export const uploadFileAction: IUploadFileActionFunc = (
  actionName: string,
  files: any,
  fileMetas: any
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(uploadFileBeginAction());

    return new Promise(function (resolve, reject) {
      uploadFileApiCall(actionName, files, fileMetas)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(uploadFileSuccessAction());
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.UPLOAD,
                SUB_CATEGORIES.ITEM
              )
            )
          );
          resolve(data as any);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UploadFileError",
            error,
            uploadFileFailureAction,
            currLang
          );
          reject("Something went wrong");
        });
    });
  };
};

/**
 * Delete Items Action dispatches action creators to redux store and makes api calls to delete items by id
 * @param {String} listId List id where to delete the items
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IDeleteItemsActionFunc = (
  actionName: string,
  itemIds: string[],
  listId: string
) => IDispatchAndGetState<void>;
export const deleteItemsAction: IDeleteItemsActionFunc = (
  actionName: string,
  itemIds: string[],
  listId: string
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(deleteItemsBeginAction());

    return deleteItemsApiCall(actionName, listId, itemIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteItemsSuccessAction(listId, itemIds));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.ITEM,
              itemIds.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteItemsError",
          error,
          deleteItemsFailureAction,
          currLang
        );
      });
  };
};

/**
 * Archive Items Action dispatches action creators to redux store and makes api calls to archive items by ids
 * @param {String} itemIds Item ids to archive
 * @param {String} listId List id where to archive the items
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IArchiveItemsActionFunc = (
  actionName: string,
  itemIds: string[],
  listId: string
) => IDispatchAndGetState<void>;
export const archiveItemsAction: IArchiveItemsActionFunc = (
  actionName: string,
  itemIds: string[],
  listId: string
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(archiveItemsBeginAction());

    return archiveItemsApiCall(actionName, listId, itemIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(archiveItemsSuccessAction(listId, itemIds));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.ITEM,
              itemIds.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveItemsError",
          error,
          archiveItemsFailureAction,
          currLang
        );
      });
  };
};

/**
 * Restore Items Action dispatches action creators to redux store and makes api calls to restore items by ids
 * @param {String} itemIds Item ids to restore
 * @param {String} listId List id where to restore the items
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IRestoreItemsActionFunc = (
  actionName: string,
  itemIds: string[],
  listId: string
) => IDispatchAndGetState<void>;
export const restoreItemsAction: IRestoreItemsActionFunc = (
  actionName: string,
  itemIds: string[],
  listId: string
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(restoreItemsBeginAction());

    return restoreItemsApiCall(actionName, listId, itemIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreItemsSuccessAction(listId, itemIds));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.ITEM,
              itemIds.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreItemsError",
          error,
          restoreItemsFailureAction,
          currLang
        );
      });
  };
};

/**
 * Edit list attribute column tag
 * @param old_tag The old tag for the list attribute to changed
 * @param new_tag The new tag for the list attribute to use
 * @param matrix_tag The tag of the matrix attribute
 */

export interface IChangeTagParams {
  old_tag: string;
  new_tag: string;
  matrix_tag?: string;
  force?: boolean;
}
type IChangeAttributeTagFunc = (
  listId: string,
  params: IChangeTagParams
) => IDispatchAndGetState<any>;
export const editListAttributeTagAction: IChangeAttributeTagFunc = (
  listId,
  params
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(editAttributeTagBeginAction());

    return new Promise((resolve, reject) => {
      editAttributeTagApiCall(listId, params)
        .then((serverResponse) => {
          if (
            serverResponse["status"] === 200 &&
            serverResponse.data["code_response"] ===
              BUSINESS_ERROR.VALIDATION_ERROR
          ) {
            resolve(serverResponse.data);
          } else {
            extractDataAndCheckErrorStatus(serverResponse);
            dispatch(ajaxSuccessAction());
            dispatch(editAttributeTagSuccessAction(params, listId));
            dispatch(
              showNotificationActionCreator(
                notificationTypes.NOTIFICATION_SUCCESS,
                notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
                getSuccessNotificationMessage(
                  currLang,
                  LANG_ACTIONS.CHANGE_TAG,
                  SUB_CATEGORIES.LIST,
                  undefined,
                  true
                )
              )
            );
            resolve({ changed: true });
          }
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "EditAttributeTagError",
            error,
            editAttributeTagFailureAction,
            currLang
          );
          reject(error);
        });
    });
  };
};

/**
 * Edit Item Action dispatches action creators to redux store and makes api calls to edit an item by id
 * @param {Array} items Items to edit (with their id and all the new properties to change)
 * @param {String} listId List id
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IEditItemsActionFunc = (
  actionName: string,
  items: any,
  listId: string,
  listSchema: IListSchema[],
  attributeKeysToConsider: string[] | undefined
) => IDispatchAndGetState<void>;
export const editItemsAction: IEditItemsActionFunc = (
  actionName: string,
  items: any,
  listId: string,
  listSchema: IListSchema[],
  attributeKeysToConsider: string[] | undefined
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    items = items.map((i: any) => {
      const cleanedItem = {};
      for (const attr in i) {
        if (
          attr !== "_client_id" &&
          attr !== "_updated_at" &&
          attr !== "_list_id" &&
          attr !== "_backend_version" &&
          attr !== "_active" &&
          attr !== "_created_at" &&
          attr !== "_created_by" &&
          attr !== "_created_source" &&
          attr !== "_updated_by" &&
          attr !== "_updated_source"
        ) {
          cleanedItem[attr] = i[attr];
        }
      }
      return cleanedItem;
    });

    dispatch(ajaxRequestAction());
    dispatch(updateItemsBeginAction());

    return updateItemsApiCall(
      actionName,
      items,
      listId,
      listSchema,
      attributeKeysToConsider
    )
      .then((serverResponse) => {
        const reponseItems = extractDataAndCheckErrorStatus(serverResponse);
        /**
         * web format
         */
        const newItems = _.map(items as IListItem[], (item) => {
          const responseItem = _.find(reponseItems, { id: item._id });

          const displayed_name = responseItem._displayed_name || "";
          const displayed_business_id =
            responseItem._displayed_business_id || "";
          const updated_at = responseItem.updated_at || "";

          if (responseItem?.hasOwnProperty("id")) {
            delete responseItem["id"];
          }
          if (responseItem?.hasOwnProperty("updated_at"))
            delete responseItem["updated_at"];
          if (responseItem?.hasOwnProperty("_displayed_name"))
            delete responseItem["_displayed_name"];
          if (responseItem?.hasOwnProperty("_displayed_business_id"))
            delete responseItem["_displayed_business_id"];

          return {
            ...item,
            ...responseItem,
            _updated_at: updated_at,
            _displayed_name: displayed_name,
            _displayed_business_id: displayed_business_id,
          };
        });

        newItems.forEach((item) => {
          Object.keys(item).forEach((key) => {
            if (item[key] === "__CLEAR") {
              item[key] = "";
            } else if (typeof item[key] === "object") {
              Object.keys(item[key]).forEach((k) => {
                if (item[key][k] === "__CLEAR") {
                  item[key][k] = null;
                }
              });
            }
          });
        });

        dispatch(ajaxSuccessAction());
        dispatch(updateItemsSuccessAction(newItems, listId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.ITEM,
              items.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "EditItemError",
          error,
          updateItemsFailureAction,
          currLang
        );
      });
  };
};

/**
 * Assign Items Action dispatches action creators to redux store and makes api calls to restore items by ids
 * @param {Array} links Detail of the links to add between items and teams
 * @param {String} listId List id concerned
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IAssignItemsActionFunc = (
  actionName: string,
  links: any,
  listId: string
) => IDispatchAndGetState<void>;
export const assignItemsAction: IAssignItemsActionFunc = (
  actionName: string,
  links: any,
  listId: string
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(assignItemsBeginAction());

    return assignItemsApiCall(actionName, listId, links)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(assignItemsSuccessAction(links));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.containers.lists.subCategories.items.customNotifications
              .assignItem
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "AssignItemsError",
          error,
          assignItemsFailureAction,
          currLang
        );
      });
  };
};

/**
 * Unassign Items Action dispatches action creators to redux store and makes api calls to restore items by ids
 * @param {Array} links Detail of the links to add between items and teams/mobile users
 * @param {String} listId List id concerned
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
type IUnassignItemsActionFunc = (
  actionName: string,
  links: string[],
  listId: string
) => IDispatchAndGetState<void>;
export const unassignItemsAction: IUnassignItemsActionFunc = (
  actionName: string,
  links: string[],
  listId: string
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(unassignItemsBeginAction());

    return unassignItemsApiCall(actionName, listId, links)
      .then((serverResponse: any) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(unassignItemsSuccessAction(links));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.containers.lists.subCategories.items.customNotifications
              .unassignItem
          )
        );
      })
      .catch((error: any) => {
        treatErrorNotification(
          dispatch,
          "UnassignItemsError",
          error,
          unassignItemsFailureAction,
          currLang
        );
      });
  };
};

type IUnassignMobileUsersActionFunc = (
  actionName: string,
  links: TItemMobileUserLink[],
  listId: string
) => IDispatchAndGetState<void>;

export const unassignMobileUsersFromListAction: IUnassignMobileUsersActionFunc =
  (actionName: string, links: TItemMobileUserLink[], listId: string) => {
    return (dispatch, getState) => {
      const currLang = lang[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(unassignItemsBeginAction());

      return new Promise((resolve) => {
        unassignMobileUsersApiCall(actionName, listId, links)
          .then((serverResponse: any) => {
            const data = extractDataAndCheckErrorStatus(serverResponse);
            dispatch(ajaxSuccessAction());
            dispatch(unassignItemsUsingLinksAction(links, listId));
            dispatch(
              showNotificationActionCreator(
                notificationTypes.NOTIFICATION_SUCCESS,
                notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
                currLang.containers.lists.subCategories.items
                  .customNotifications.unassignItem
              )
            );

            const { notFoundMobileUsers } = (data ?? {}) as any;
            if (notFoundMobileUsers?.length > 0) resolve(notFoundMobileUsers);
            else resolve();
          })
          .catch((error: any) => {
            treatErrorNotification(
              dispatch,
              "UnassignItemsError",
              error,
              unassignItemsFailureAction,
              currLang
            );
            resolve();
          });
      });
    };
  };

/**
 * Fetch list options
 * @param {String} listId string
 * @param {String} searchValue string
 * @returns {Promise}
 */
export const fetchListOptionsAction = (
  listId: string,
  searchValue?: any,
  limit?: number,
  offset?: number
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    return new Promise(function (resolve, reject) {
      getListOptionsApiCall(listId, searchValue, limit, offset)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(fetchListOptionsSuccessAction(listId, data.options));
          resolve(data.options as any);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "fetchListOptionsFailureAction",
            error,
            fetchListOptionsFailureAction,
            currLang
          );
          reject(error);
        });
    });
  };
};
type ISelectListActionFunc = (id: string) => any;
export const selectListAction: ISelectListActionFunc = (id: string) => {
  return (dispatch: Dispatch, getState: any) => {
    // get the list from Redux store if it exists
    const list = getListById(getState())[id];
    dispatch(selectListActionCreator(list));
  };
};
