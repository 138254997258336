import React from "react";

import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import IPictureSortBy from "containers/pictures/model/IPictureSortBy";
import { IPictureWithIndex } from "containers/pictures/utils";
import { IList } from "model/entities/List";
import IUser from "model/entities/User";
import { IActivity, IWorkflow } from "model/entities/Workflow";

import getSortedPicturesList, {
  ISortingPicture,
} from "./getSortedPicturesList";

const useStyles = makeStyles({
  image: { cursor: "pointer", maxWidth: "100%" },
  titleSorting: { marginBottom: "10px" },
});

interface IPictureItemProps {
  url: string;
  onClick: () => void;
}

const PictureItem: React.FunctionComponent<IPictureItemProps> = ({
  url,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={3} onClick={onClick}>
      <img src={url} className={classes.image} alt={"pict"} />
    </Grid>
  );
};

interface ISortingPicturesListContainer {
  pictures: IPictureWithIndex[];
  onClickPicture: (idPicture: number) => void;
  sortBy: IPictureSortBy;
  allUsers: IUser[];
  allLists: IList[];
  allActivities: IActivity[];
  allWorkflows: IWorkflow[];
}

export const SortingPicturesListContainer: React.FunctionComponent<
  ISortingPicturesListContainer
> = ({
  pictures,
  onClickPicture,
  sortBy,
  allUsers,
  allLists,
  allActivities,
}) => {
  const sortedPictures = getSortedPicturesList(pictures, sortBy, {
    allUsers,
    allLists,
    allActivities,
  });

  return (
    <SortingPicturesList
      pictures={sortedPictures}
      onClickPicture={onClickPicture}
    />
  );
};

interface ISortingPicturesListProps {
  pictures: ISortingPicture;
  onClickPicture: (idPicture: number) => void;
}

export const SortingPicturesList: React.FunctionComponent<
  ISortingPicturesListProps
> = ({ pictures, onClickPicture }) => {
  const classes = useStyles();
  return (
    <>
      {Object.keys(pictures).map((titleCategory, index) => (
        <React.Fragment key={index}>
          <h6 className={classes.titleSorting}>{titleCategory}</h6>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {pictures[titleCategory].map((picture) => (
              <PictureItem
                key={picture.url}
                url={picture.url}
                onClick={() => onClickPicture(picture.uniqueId)}
              />
            ))}
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};

interface ISimplePicturesListProps {
  pictures: IPictureWithIndex[];
  onClickPicture: (idPicture: number) => void;
}

export const SimplePicturesList: React.FunctionComponent<
  ISimplePicturesListProps
> = ({ pictures, onClickPicture }) => (
  <Grid
    container
    spacing={3}
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
    {pictures.map((picture, index) => (
      <PictureItem
        key={index}
        url={picture.url}
        onClick={() => onClickPicture(picture.uniqueId)}
      />
    ))}
  </Grid>
);
