import { Background } from "assets/colors";
import { closedDrawerWidth, drawerWidth } from "components/Sidebar/styles";

export default (theme: any) => ({
  "@global": {
    body: {
      // Disable the "dragging-down" effect in browser. Keep x-axis for prev/next gesture on trackpad.
      // Alternate solution would be to position the Navbar with position: fixed (but be careful of dynamic sizing with ClientStatusBanner)
      overscrollBehaviorY: "none",
    },
    a: {
      "&:hover": {
        textDecoration: "none",
      },
    },
    ".speedometer": {
      display: "block",
      margin: "0 auto",
      "& g:nth-child(3)": {
        "& text": {
          transform: "translate(0px, -40px)",
        },
      },
      "& g:nth-child(4)": {
        display: "none",
      },
    },
    DisplaySidebarAndNavbar: {
      display: "flex",
    },
    ".ps__rail-y": {
      // Prefect scrollbar. Should be above everything
      zIndex: 1000,
    },
    ".ps__rail-x": {
      // Prefect scrollbar. Should be above everything
      zIndex: 1000,
    },
    ".MuiTableSortLabel-icon": {
      color: "white !important",
    },
    ".MuiButtonBase-root": {
      // zIndex: "1000", // This will force us to put zIndexes everywhere if this style is global...
    },
    ".MuiFab-primary": {
      color: theme.palette.primary.main,
      background: "transparent",
      boxShadow: "none",
      "&:hover": {
        background: "#DDD",
      },
    },
  },
  MainContainer: {
    background: Background,
    height: "calc(100vh - 64px)", // remove the size of the navbar
    width: "calc(100vw - 60px)", // remove the size of the sidebar
    padding: "20px",
    paddingLeft: "20px",
    margin: "auto",
    float: "right",
    overflowX: "hidden",
    overflowY: "auto",

    "&.withSidebar": {
      paddingLeft: `calc(${drawerWidth} - ${closedDrawerWidth} + 20px)`,
      paddingRight: "20px",
      paddingTop: "20px",
      paddingBottom: "20px",
    },

    "&.takingStorageIntoAccount": {
      height: "calc(100vh - 124px)", // remove the size of the navbar + storage banner
    },
  },
});
