import {
  CalendarEventStatus,
  IMobileUser,
  TCalendarEvent,
} from "fieldpro-tools";
import _ from "lodash";

import DefaultChip from "components/Chip/DefaultChip";
import getItemName from "containers/lists/utils/getItemName";
import TLang from "model/application/Lang";
import { IList } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import getFullName from "utils/formatting/getFullName";

import EventStatusMenuButton from "../components/CalendarEventsListView/EventStatusMenuButton";
import { TEventsTableColumnData } from "./CreateEventsTableColumns";

interface IGetEventsTableRows {
  events: TCalendarEvent[];
  customersList: IList<IListItem> | undefined;
  mobileUsers: IMobileUser[];
  lang: TLang;
  onChangeStatus?: (
    events: TCalendarEvent[],
    status: CalendarEventStatus
  ) => Promise<void>;
}
export function getEventsTableRows({
  customersList,
  events,
  mobileUsers,
  onChangeStatus,
}: IGetEventsTableRows): Array<TEventsTableColumnData> {
  return _.map(events, (event) => {
    const customer = _.find(customersList?.items, {
      _id: event.customer_id,
    });
    const mobileUser = _.find(mobileUsers, { id: event.assigned_to });

    const data: TEventsTableColumnData = {
      ...event,
      customer_name: (
        <DefaultChip label={getItemName(customer) || "Not Found"} />
      ),
      field_user_name: _.get(mobileUser, "name", getFullName(mobileUser)),
      phone_number: mobileUser?.phone,
      status: (
        <EventStatusMenuButton
          event={event}
          key={event.id}
          onChangeStatus={onChangeStatus}
        />
      ),
      statusText: event.status || CalendarEventStatus.PLANNED,
    };

    return data;
  });
}
