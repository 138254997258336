import { useMemo } from "react";

import _ from "lodash";
import countryList from "react-select-country-list";

import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputSelect from "components/Input/InputSelect";
import { useTranslations } from "hooks";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { CLIENT_REGIONS, CLIENT_SECTORS, IClient } from "model/entities/Client";
import { getOptionsInputSelect } from "utils/getOptionsInputSelect";

interface IClientLocation extends Partial<IClient> {
  viewMode?: TViewMode;
  onSelectChange: (value: string, name: string) => void;
  onMultipleSelectChange: (value: any, name: string) => void;
}
const ClientLocation = ({
  viewMode,
  region,
  country,
  sector,
  onSelectChange,
  onMultipleSelectChange,
}: IClientLocation) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  const countryOptions = useMemo(() => countryList().getData(), []);

  return (
    <>
      <InputMultipleSelect
        viewMode={viewMode}
        name={"country"}
        multipleSelection={false}
        onChange={onMultipleSelectChange}
        options={_.map(countryOptions, (u) => ({
          key: u.value,
          label: u.label,
        }))}
        defaultValue={countryOptions
          .filter((u) => u.value === country)
          .map((u) => ({
            key: u.value,
            label: u.label,
          }))}
        lang={lang}
        langlabel={label.inputClientCountryManagement}
      />

      <InputSelect
        viewMode={viewMode}
        name="region"
        title={label.inputClientRegionManagement.title}
        onChange={onSelectChange}
        value={region}
        options={getOptionsInputSelect({
          listKeys: _.keys(CLIENT_REGIONS),
          lang: label.inputClientRegionManagement.options,
        })}
        clearable
      />

      <InputSelect
        viewMode={viewMode}
        name="sector"
        title={label.inputClientSectorManagement.title}
        onChange={onSelectChange}
        value={sector}
        options={getOptionsInputSelect({
          listKeys: _.keys(CLIENT_SECTORS),
          lang: label.inputClientSectorManagement.options,
        })}
        clearable
      />
    </>
  );
};

export default ClientLocation;
