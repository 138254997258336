import { Grid } from "@material-ui/core";

import PictureGalleryPicture from "./PictureGalleryPicture";
import { TGalleryPicture } from "./types";

interface IPictureGalleryList {
  pictures: TGalleryPicture[];
  onImageClick: (tag: string, index: number) => any;
  selectedTag?: string;
}

function PictureGalleryList({
  pictures,
  selectedTag,
  onImageClick,
}: IPictureGalleryList) {
  const picturesList = pictures.map((picture, index) => {
    return selectedTag === picture.tag || selectedTag === undefined ? (
      <Grid
        item
        xs={6}
        key={index}
        onClick={() => {
          onImageClick(picture.tag, index);
        }}
      >
        <PictureGalleryPicture
          width="320px"
          tag={picture.tag}
          url={picture.url}
        />
      </Grid>
    ) : null;
  });

  return (
    <Grid
      style={{
        rowGap: "20px",
        justifyItems: "stretch",
        minHeight: "250px",
      }}
      container
    >
      {picturesList}
    </Grid>
  );
}
export default PictureGalleryList;
