import { CalendarEventStatus } from "fieldpro-tools";
import _ from "lodash";

import { Black, White } from "assets/colors";
import { TDateFilterType } from "components/Filter/FilterModals/components/DateFilter";
import FILTER_TYPE, { FILTER_TAG } from "components/Filter/TypeFilter";
import { IOption } from "model/application/components";
import { IFilter, IFilterStyles } from "model/application/Filter";
import TLang, { TLangDefaultScreen } from "model/application/Lang";
import { ITeamSelector } from "model/entities/Team";
import { IMobileUser } from "model/entities/User";
import getFullName from "utils/formatting/getFullName";

enum CALENDAR_FILTERS_ENUM {
  STATUS = "STATUS",
}

export const CALENDAR_FILTERS = {
  ...CALENDAR_FILTERS_ENUM,
  ...FILTER_TAG,
};

export type CALENDAR_FILTERS = typeof CALENDAR_FILTERS_ENUM;

interface IPrepareFilters {
  lang: TLang;
  mobileUsers: IMobileUser[];
  teams: ITeamSelector[];
  filterQuery: Record<string, any>;
  displayFieldUserFilter?: boolean;
}
/* -------------------------------------------------------------------------- */
/*                               prepareFilters                               */
/* -------------------------------------------------------------------------- */
export function prepareFilters({
  mobileUsers,
  teams,
  lang,
  filterQuery,
  displayFieldUserFilter,
}: IPrepareFilters): IFilter<any>[] {
  const allFilters: IFilter<any>[] = [];
  const langKey = lang.containers.calendar.subCategories.calendar;

  const teamsFilter = buildTeamsFilter(lang, filterQuery, teams);

  const teamsFilterValue = filterQuery[CALENDAR_FILTERS.TEAMS];

  const mobileUsersAsOptions = _(mobileUsers)
    .filter((user) => {
      if (!_.isEmpty(teamsFilterValue)) {
        return (
          _(teamsFilterValue)
            .map((teamId: string) => _.find(teams, { id: teamId }))
            .compact()
            .flatMap((team) =>
              _.map((team as unknown as ITeamSelector).mobile_users, "key")
            )
            .includes(user.id) ||
          _.includes(filterQuery[CALENDAR_FILTERS.USERS], user.id)
        );
      }
      return true;
    })
    .map((user) => ({
      key: user.id,
      label: _.get(user, "name", getFullName(user)),
    }))
    .value();

  const fieldUserFilter = buildFieldUserFilter(
    langKey,
    filterQuery,
    mobileUsersAsOptions
  );

  const statusFilter = buildStatusFilter(lang, filterQuery);

  if (displayFieldUserFilter) {
    allFilters.push(teamsFilter, fieldUserFilter);
  }

  allFilters.push(statusFilter);

  return allFilters;
}

/* -------------------------------------------------------------------------- */
/*                          prepareFiltersForListView                         */
/* -------------------------------------------------------------------------- */
interface IPrepareFiltersForListView {
  lang: TLang;
  filterQuery: Record<string, any>;
}
export function prepareFiltersForListView({
  lang,
  filterQuery,
}: IPrepareFiltersForListView): IFilter<any>[] {
  const allFilters: IFilter<any>[] = [];

  const statusFilter = buildStatusFilter(
    lang,
    filterQuery,
    {
      bgColor: undefined,
      dropdownStyles: undefined,
      textColor: undefined,
    },
    lang.containers.dashboards.subCategories.dashboards.columns.status
  );
  const dateFilter = buildDateFilter(lang, filterQuery, {
    showArrow: true,
  });

  allFilters.push(statusFilter);
  allFilters.push(dateFilter);
  return allFilters;
}

/* -------------------------------------------------------------------------- */
/*                               FILTER BUILDERS                              */
/* -------------------------------------------------------------------------- */

interface IGetEventStatusOptions {
  lang: TLang;
}

export function getEventStatusOptions({
  lang,
}: IGetEventStatusOptions): IOption<CalendarEventStatus>[] {
  const langKey =
    lang.containers.calendar.subCategories.calendar.createEditModal.inputStatus
      .options;

  return _.map(
    [
      CalendarEventStatus.PENDING,
      CalendarEventStatus.PLANNED,
      CalendarEventStatus.COMPLETED,
      CalendarEventStatus.DECLINED,
      CalendarEventStatus.OUTDATED,
    ],
    (key) => {
      return {
        key,
        label: langKey[key],
      };
    }
  );
}

export function buildFieldUserFilter(
  langKey: TLangDefaultScreen["createMenu"],
  filterQuery: Record<string, any>,
  mobileUsersAsOptions: IOption[]
): IFilter {
  return {
    label: langKey.createEditModal.inputFieldUser.title,
    tag: CALENDAR_FILTERS.USERS,
    type: FILTER_TYPE.MULTIPLE_CHOICE,
    value: filterQuery[CALENDAR_FILTERS.USERS] || [],
    options: mobileUsersAsOptions,
    isSecondaryFilter: false,
    styles: {
      showArrow: true,
    },
  };
}

export function buildStatusFilter(
  lang: TLang,
  filterQuery: Record<string, any>,
  styles?: IFilterStyles,
  label?: string
): IFilter<CalendarEventStatus> {
  return {
    label:
      label ??
      lang.containers.calendar.subCategories.calendar.createEditModal
        .inputStatus.title,
    tag: CALENDAR_FILTERS.STATUS,
    type: FILTER_TYPE.MULTIPLE_CHOICE,
    value: filterQuery[CALENDAR_FILTERS.STATUS] ?? [],
    isSecondaryFilter: false,
    options: getEventStatusOptions({ lang }),
    styles: {
      showArrow: true,
      bgColor: White,
      textColor: Black,
      dropdownStyles: {
        "&:hover": {
          backgroundColor: White,
        },
        "&:focus": {
          backgroundColor: White,
        },
      },
      ...(styles || {}),
    },
  };
}
export function buildDateFilter(
  lang: TLang,
  filterQuery: Record<string, any>,
  styles?: IFilterStyles
): TDateFilterType {
  return {
    label: lang.components.filters.date,
    tag: CALENDAR_FILTERS.DATE,
    type: FILTER_TYPE.DATE,
    value: filterQuery[CALENDAR_FILTERS.DATE] || {},
    isSecondaryFilter: false,
    styles,
    additionalParams: {
      displayClearOption: true,
    },
  };
}

export function buildTeamsFilter(
  lang: TLang,
  filterQuery: any,
  allTeams: ITeamSelector[]
) {
  return {
    label: lang.components.filters.teams,
    tag: CALENDAR_FILTERS.TEAMS,
    type: FILTER_TYPE.MULTIPLE_CHOICE,
    value: filterQuery[CALENDAR_FILTERS.TEAMS] || [],
    options: allTeams
      .filter((t) => t.active)
      .map((team) => ({
        key: team.id,
        label: team.name,
      })),
    isSecondaryFilter: false,
    styles: {
      showArrow: true,
    },
  };
}
