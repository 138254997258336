import { IItemInTable, IListItem } from "model/entities/ListItem";

/**
 * Official name key is now _name ? But in some places we find _displayed_name
 */
const getItemName = (item?: IListItem | IItemInTable) => {
  return item?._name || item?._displayed_name || "";
};

export default getItemName;
