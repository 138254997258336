import { IJobScript } from "model/entities/Job";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import * as types from "./actionTypes";

// SCRIPTS

export interface ICreateScriptSuccessAction extends IAction {
  script: IJobScript;
}
export interface IUpdateScriptSuccessAction extends IAction {
  script: IJobScript;
}
export interface IRunScriptJobSuccessAction extends IAction {
  success: boolean;
}
export interface IDeleteScriptSuccessAction extends IAction {
  id: string;
}
export interface IFetchScriptsSuccessAction extends IAction {
  scripts: IJobScript[];
  availableActions: any[]; // FIXME replace "any" with a more precise type
}
export interface IUseScriptSuccessAction extends IAction {
  id: string;
}

/**
 * Create Script begin action creator
 * This creates an action informing redux store that the creation process for a script has just began
 * @returns {Object}
 */
export function createScriptBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_SCRIPT_BEGIN,
  };
}

/**
 * Failure Action for creating a script instructing that the creation process for a Script has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function createScriptFailureActionCreator(error: any): IActionError {
  return {
    type: types.CREATE_SCRIPT_FAILURE,
    error,
  };
}

/**
 * Success action for creating a script
 * @param {Object} script script object,
 * @returns {Object}
 */
export const createScriptSuccessActionCreator = (
  script: IJobScript
): ICreateScriptSuccessAction => {
  return {
    type: types.CREATE_SCRIPT_SUCCESS,
    script,
  };
};

/**
 * Edit script begin action creator
 * This creates an action informing redux store that the creation process for a script has just began
 * @returns {Object}
 */
export function updateScriptBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_SCRIPT_BEGIN,
  };
}

/**
 * Failure Action for editing a script instructing that the edition process for a script has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateScriptFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_SCRIPT_FAILURE,
    error,
  };
}

/**
 * Success action for editing a script
 * @param {Object} script script object,
 * @returns {Object}
 */
export function updateScriptSuccessActionCreator(
  script: IJobScript
): IUpdateScriptSuccessAction {
  return {
    type: types.UPDATE_SCRIPT_SUCCESS,
    script,
  };
}

/**
 * Delete script begin action creator
 * This creates an action informing redux store that the creation process for a script has just began
 * @returns {Object}
 */
export function deleteScriptBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_SCRIPT_BEGIN,
  };
}

/**
 * Failure Action for creating a script instructing that the creation process for a script has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteScriptFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_SCRIPT_FAILURE,
    error,
  };
}

/**
 * Success action for creating a script
 * @param {Object} scriptId script object,
 * @returns {Object}
 */
export function deleteScriptSuccessActionCreator(
  scriptId: string
): IDeleteScriptSuccessAction {
  return {
    type: types.DELETE_SCRIPT_SUCCESS,
    id: scriptId,
  };
}

/**
 * Use script begin action creator
 * This creates an action informing redux store that the usage process for a script has just began
 * @returns {Object}
 */
export function runScriptBeginActionCreator(): IBeginAction {
  return {
    type: types.USE_SCRIPT_BEGIN,
  };
}

/**
 * Failure Action for creating a script instructing that the usage process for a script has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function runScriptFailureActionCreator(error: any): IActionError {
  return {
    type: types.USE_SCRIPT_FAILURE,
    error,
  };
}

/**
 * Success action for usage a script
 * @param {Object} scriptId script object,
 * @returns {Object}
 */
export function runScriptSuccessActionCreator(
  scriptId: string
): IUseScriptSuccessAction {
  return {
    type: types.USE_SCRIPT_SUCCESS,
    id: scriptId,
  };
}

/**
 * begin action when there is a request to fetch all scripts
 * @returns {Object}
 * */
export const fetchAllScriptsBeginActionCreator = (): IBeginAction => ({
  type: types.FETCH_ALL_SCRIPTS_BEGIN,
});

/**
 * Failure action for fetching all scripts. This action is dispatched when there is an error fetching all
 * scripts
 * @param {Object} error
 * @returns {Object}
 * */
export const fetchAllScriptsFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.FETCH_ALL_SCRIPTS_FAILURE,
  error,
});

/**
 * Fetch all Scripts action creator
 * @param {Array} scripts an array of script objects
 * @returns {Object}
 * */
export const fetchAllScriptsSuccessActionCreator = (
  scripts: IJobScript[],
  availableActions: any[]
): IFetchScriptsSuccessAction => ({
  type: types.FETCH_ALL_SCRIPTS_SUCCESS,
  scripts,
  availableActions,
});

/**
 * Run script job begin action creator
 * This creates an action informing redux store that the running process for a script job has just began
 * @returns {Object}
 */
export function runScriptJobBeginActionCreator(): IBeginAction {
  return {
    type: types.RUN_SCRIPT_JOB_BEGIN,
  };
}

/**
 * Failure Action for running a script job instructing that the running process for a script job has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function runScriptJobFailureActionCreator(error: any): IActionError {
  return {
    type: types.RUN_SCRIPT_JOB_FAILURE,
    error,
  };
}

/**
 * Success action for running a script job
 * @param {Object} script Notification object,
 * @returns {Object}
 */
export const runScriptJobSuccessActionCreator = (
  success: boolean
): IRunScriptJobSuccessAction => {
  return {
    type: types.RUN_SCRIPT_JOB_SUCCESS,
    success,
  };
};
