import {
  IHierarchyDependency,
  IMetaHierarchyDependency,
  NUMBER_FILTER,
} from "fieldpro-tools";
import _ from "lodash";

import MiniChip from "components/Chip/MiniChip";
import { IQuery } from "containers/lists/utils";
import { IOption } from "model/application/components";
import { IFilter } from "model/application/Filter";
import TLang from "model/application/Lang";
import { IDashboard, KPI_TYPE, PIVOT_TYPE } from "model/entities/Dashboard";
import { ITeamSelector } from "model/entities/Team";
import { transposeLocalTimeToUTCTime } from "utils/utils";

import { getDateFilterLabel } from "./FilterModals/components/DateFilter";
import {
  getHierarchyFromTeams,
  getLabelPerTeamFromTeams,
  getLabelsFromTeams,
  getMobileUsersFromTeamsIds,
  getTeamsFromHierarchy,
  getTeamsFromLabel,
  getTeamsFromLabelPerTeam,
  getTeamsFromLabels,
} from "./FilterModals/components/utils";
import FILTER_TYPE, { FILTER_TAG } from "./TypeFilter";

/**
 * This function converts from the frontend filters to an object that makes more sense for the backend.
 * Suitable to build queries for dashboards, workflow reports and a few other objects.
 * Still not enough for all related to queries for items or act reports: use formatFilterForBackend in that case.
 * @param filters
 * @returns
 */
export const getBackendQuery = (filters?: IQuery): IQuery => {
  const query = { ...filters };

  return _.keys(query).reduce((acc, curr) => {
    switch (curr) {
      case FILTER_TAG.HIERARCHY:
      case FILTER_TAG.LABELS:
      case FILTER_TAG.MORE_FILTER: {
        // don't send back "LABELS" filter to the backend: only the "teams" has to be send
        // don't send back "HIERARCHY" filter to the backend: only the "teams" has to be send
        // don't send back "MORE_FILTER" filter to the backend. Keep it only for the raw query
        break;
      }
      case FILTER_TAG.ACTION_CODE: {
        acc["action_code"] = query[curr];
        break;
      }
      case FILTER_TAG.TEAMS: {
        acc["teams"] = query[curr];
        break;
      }
      case FILTER_TAG.WORKFLOWS: {
        acc["workflow_ids"] = query[curr];
        break;
      }
      case FILTER_TAG.ACTIVITIES: {
        acc["activity_ids"] = query[curr];
        break;
      }
      case FILTER_TAG.DATE: {
        acc["start_date"] = transposeLocalTimeToUTCTime(query[curr].startDate);
        acc["end_date"] = transposeLocalTimeToUTCTime(query[curr].endDate);
        break;
      }
      case FILTER_TAG.ARCHIVED: {
        const value = _.map(query[curr], (bool) => !bool);
        if (_.isEmpty(value)) {
          break;
        }
        acc["_active"] = value;
        break;
      }
      default: {
        if (curr.startsWith(FILTER_TAG.LABELS)) {
          // don't send back "LABELS" filter to the backend: only the "teams" has to be send
        } else {
          acc[curr] = query[curr];
        }
      }
    }
    return acc;
  }, {});
};

export const getQuery = (
  filters: IFilter[],
  dashboard?: IDashboard
): IQuery => {
  const matrixPivotUsed =
    dashboard &&
    dashboard.kpis.find(
      (kpi) =>
        kpi.type === KPI_TYPE.MATRIX &&
        ([PIVOT_TYPE.TEAM, PIVOT_TYPE.MOBILE_USER].includes(
          kpi.row_pivot_type as PIVOT_TYPE
        ) ||
          [PIVOT_TYPE.TEAM, PIVOT_TYPE.MOBILE_USER].includes(
            kpi.column_pivot_type as PIVOT_TYPE
          ))
    )
      ? true
      : false;

  return filters.reduce((acc: any, curr: IFilter) => {
    if (!_.isEmpty(curr.value)) {
      if (curr.type === FILTER_TYPE.MULTIPLE_CHOICE) {
        // if no option marked for a multiple choice filter, we consider that the filter is not active...
        if (curr.value.length !== 0) {
          acc[curr.tag] = curr.value;
        } else if (matrixPivotUsed) {
          // except if we are in a matrix with a pivot on users/teams. In that case, we need to set all the options as filter.
          acc[curr.tag] = curr.options?.map((o) => o.key);
        }
      } else {
        acc[curr.tag] = curr.value;
      }
    } else if (matrixPivotUsed) {
      // except if we are in a matrix with a pivot on users/teams. In that case, we need to set all the options as filter.
      acc[curr.tag] = curr.options?.map((o) => o.key);
    }
    return acc;
  }, {});
};

export const setDefaultMinDate = (numberOfGoBack: number): number => {
  const goBackDays = numberOfGoBack === undefined ? 180 : numberOfGoBack;
  return new Date().setDate(new Date().getDate() - goBackDays);
};

interface IGetNewFiltersArgs {
  tag: string;
  value: string[];
  filters: IFilter[];
  allTeamsObject?: ITeamSelector[];
  hierarchy?: IHierarchyDependency[];
  metaHierarchy?: IMetaHierarchyDependency;
  isTeamScope: boolean;
}

// ODOT: Split into small functions with less args
export const getNewFilters = ({
  filters,
  tag,
  value,
  allTeamsObject,
  hierarchy,
  metaHierarchy,
  isTeamScope,
}: IGetNewFiltersArgs) => {
  return filters.map((f) => {
    //link hierarchy with teams filter
    if (f.tag === FILTER_TAG.TEAMS && allTeamsObject && metaHierarchy) {
      // if the current filter is a TEAM filter, check if the changed filter is a HIERARCHY
      if (tag === FILTER_TAG.LABELS) {
        // change the teams filter to match with the labels filter
        f.value = getTeamsFromLabels(value, allTeamsObject, metaHierarchy);
      } else if (tag.includes(`${FILTER_TAG.LABELS}`)) {
        f.value = getTeamsFromLabelPerTeam(
          value,
          allTeamsObject,
          metaHierarchy,
          tag
        );
      } else if (tag === FILTER_TAG.HIERARCHY) {
        // change the teams filter to match with the hierarchy filter
        f.value = getTeamsFromHierarchy(value, allTeamsObject, metaHierarchy);
      }
    } else if (f.tag === FILTER_TAG.LABELS && allTeamsObject && metaHierarchy) {
      // if the current filter is a LABELS filter, check if the changed filter is a TEAMS
      if (tag === FILTER_TAG.TEAMS) {
        // change the labels filter to match with the team filter
        f.value = getLabelsFromTeams(value, allTeamsObject, metaHierarchy);
      }
    } else if (
      f.tag.includes(`${FILTER_TAG.LABELS}_`) &&
      allTeamsObject &&
      metaHierarchy
    ) {
      // if the current filter is a LABELS filter, check if the changed filter is a TEAMS
      if (tag === FILTER_TAG.TEAMS) {
        // change the labels filter to match with each team label filter
        f.value = value
          ? getLabelPerTeamFromTeams(
              value,
              allTeamsObject,
              metaHierarchy,
              f.tag
            )
          : [];
      }
    } else if (
      f.tag === FILTER_TAG.HIERARCHY &&
      allTeamsObject &&
      metaHierarchy
    ) {
      // if the current filter is a HIERARCHY filter, check if the changed filter is a TEAMS
      if (tag === FILTER_TAG.TEAMS) {
        // change the hierarchy filter to match with the team filter
        f.value = getHierarchyFromTeams(value, allTeamsObject, metaHierarchy);
      }
    } else if (f.tag === FILTER_TAG.OWNERS && allTeamsObject && metaHierarchy) {
      // if the current filter is a OWNERS filter, check if the changed filter is a HIERARCHY, LABELS or TEAMS
      if (tag.startsWith(FILTER_TAG.LABELS)) {
        // change the teams filter to match with the labels filter
        const teamsIds = getTeamsFromLabel(
          value,
          allTeamsObject,
          metaHierarchy
        );
        f.value = isTeamScope
          ? teamsIds
          : getMobileUsersFromTeamsIds(teamsIds, allTeamsObject);
      } else if (tag === FILTER_TAG.HIERARCHY) {
        // filter wrong values (levels still in value but with no children selected)

        const filterFunc = (v: string) =>
          !_.some(hierarchy, (h) => h.level_father == v) ||
          _.some(value, (k) =>
            _.some(hierarchy, (h) => h.level_name == k && h.level_father == v)
          );

        value = _.filter(value, filterFunc);

        // change the teams filter to match with the hierarchy filter
        const teamsIds = getTeamsFromHierarchy(
          value,
          allTeamsObject,
          metaHierarchy
        );

        const hierarchyFilter = isTeamScope
          ? teamsIds
          : getMobileUsersFromTeamsIds(teamsIds, allTeamsObject);

        f.value = hierarchyFilter;
      }

      // if the current filter is a HIERARCHY filter, check if the changed filter is a TEAMS
      if (tag === FILTER_TAG.TEAMS) {
        // change the hierarchy filter to match with the team filter
        f.value = getMobileUsersFromTeamsIds(value, allTeamsObject);
      }
    }

    if (f.tag === tag) {
      f.value = value;
    }

    // REMOVE CHIP WHEN VALUE IS "DELETED" / SET TO NULL
    if (_.isNull(value) && f.tag === FILTER_TAG.MORE_FILTER) {
      f.value = _.without(f.value, tag);
    }

    return f;
  });
};

const getOptionByKey = (key: string, options?: IOption[]) => {
  return _.find(options, { key });
};

export const getSafeOption = (
  answer: IOption<string> | string | undefined,
  options: IOption<string>[]
) => {
  if (!answer) {
    return undefined;
  }

  if (_.isString(answer)) {
    return _.find(options, { key: answer }) as IOption<string> | undefined;
  }

  return answer;
};

export const getFilterValueOnaBoolean = (value: any) => {
  const hasManyElement = _.isArray(value) && _.size(value) > 1;
  if (hasManyElement) {
    return "True/False";
  }
  return _.includes(["true", true], value[0]) ? "True" : "False";
};

export const getFormattedValue = (filter: IFilter, lang: TLang) => {
  if (
    typeof filter.value === "string" &&
    filter.type != FILTER_TYPE.MULTIPLE_CHOICE_ON_LIST
  ) {
    return filter.value;
  }

  if (
    [
      FILTER_TYPE.MULTIPLE_CHOICE,
      FILTER_TYPE.MULTIPLE_CHOICE_ON_LIST,
      FILTER_TYPE.SINGLE_CHOICE,
    ].includes(filter.type)
  ) {
    const valueLabels = _.map(filter.value, (value) => {
      const key = _.get(value, "key", value);
      const options =
        filter.type === FILTER_TYPE.MULTIPLE_CHOICE_ON_LIST
          ? filter.value
          : filter.options;
      return getOptionByKey(key, options)?.label;
    });
    const [firstValue, ...nextValues] = valueLabels;
    if (!firstValue) {
      return null;
    }
    return (
      <span>
        {firstValue}{" "}
        {!_.isEmpty(nextValues) ? (
          <MiniChip
            label={`+${nextValues.length}`}
            style={{ verticalAlign: "text-bottom" }}
          />
        ) : (
          ""
        )}
      </span>
    );
  }
  if (filter.type === FILTER_TYPE.MULTIPLE_CHOICE_ON_LIST) {
    const valueLabels = (filter.options ?? [])
      .filter((o) => filter.value.includes(o.key))
      .map((o) => o.label);
    const [firstValue, ...nextValues] = valueLabels;
    if (!firstValue) {
      return null;
    }
    return (
      <span>
        {firstValue}{" "}
        {!_.isEmpty(nextValues) ? (
          <MiniChip
            label={`+${nextValues.length}`}
            style={{ verticalAlign: "text-bottom" }}
          />
        ) : (
          ""
        )}
      </span>
    );
  }

  if (filter.type === FILTER_TYPE.DATE && !_.isEmpty(filter.value)) {
    const { dateType /*, startDate, endDate */ } = filter.value || {};
    if (lang && dateType && getDateFilterLabel(dateType, lang)) {
      return getDateFilterLabel(dateType, lang);
    }
    return <MiniChip label="1" style={{ verticalAlign: "text-bottom" }} />;
  }

  if (filter.type === FILTER_TYPE.BOOLEAN && !_.isEmpty(filter.value)) {
    return getFilterValueOnaBoolean(filter.value);
  }

  if (filter.type === FILTER_TYPE.NUMBER && filter.value) {
    const { number_filter_type, search_value } = filter.value || {};
    const FILTER_TYPE_TO_LABEL = {
      [NUMBER_FILTER.GREATER_THAN]: ">",
      [NUMBER_FILTER.GREATER_THAN_OR_EQUAL]: "≥",
      [NUMBER_FILTER.LESS_THAN]: "<",
      [NUMBER_FILTER.LESS_THAN_OR_EQUAL]: "≤",
      [NUMBER_FILTER.IS]: "",
      [NUMBER_FILTER.IS_NOT]: "not ",
    };
    const operator = FILTER_TYPE_TO_LABEL[number_filter_type] || "";
    return `${operator}${search_value || ""}`.trim();
  }

  if (filter.type === FILTER_TYPE.HIERARCHY) {
    // NOTE: should be useless now that filter.value is cleaned at root (See FP-4741)
    const value = _.without(_.compact(filter.value), "null");

    if (!_.isEmpty(value)) {
      return <MiniChip label="1" style={{ verticalAlign: "text-bottom" }} />;
    }
  }

  return null;
};

export const mustSkipBackendCall = (oldQuery: any, newQuery: any) => {
  const oldQueryConverted = getBackendQuery(oldQuery);
  const currentQueryConverted = getBackendQuery(newQuery);
  const isEqual = _.isEqual(oldQueryConverted, currentQueryConverted);
  return isEqual;
};
