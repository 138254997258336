import { Box } from "@material-ui/core";
import { META_EXPRESSION_TARGET_TYPE } from "fieldpro-tools";
import { IMetaExpression } from "fieldpro-tools/src/types";

import CustomDialogCreateEdit from "components/Dialog/CustomDialogCreateEdit";
import useTranslations from "hooks/useTranslations";
import { getErrorMessagesForMetaExpression } from "utils/metaExpressions";

import { IInputMetaExpressionProps } from "./InputMetaExpression";
import MetaExpressionWizard, {
  CALLED_FROM,
  IMetaExpressionWizardAdditionnalProps,
} from "./MetaExpressionWizard";

export interface ICreateEditModalExpression
  extends Pick<
    IInputMetaExpressionProps,
    | "numberIndex"
    | "calledFrom"
    | "currentWorkflow"
    | "currentActivity"
    | "currentList"
    | "currentTrigger"
    | "matrixTag"
    | "expressionType"
    | "bindedTransfo"
    | "type"
  > {
  selectedMetaExpression: IMetaExpression;
  isMetaBuilderOpen: boolean;
  modalType: "create" | "edit";
  handleConfirmEditMetaExpression: ((attributes: any) => void) | undefined;
  handleConfirmCreateMetaExpression: ((attributes: any) => void) | undefined;
  onClose: () => void;
}
function CreateEditModalMetaExpression({
  isMetaBuilderOpen,
  handleConfirmCreateMetaExpression,
  handleConfirmEditMetaExpression,
  onClose,
  modalType,
  selectedMetaExpression,
  bindedTransfo,
  calledFrom,
  currentActivity,
  currentList,
  currentWorkflow,
  currentTrigger,
  expressionType,
  matrixTag,
  numberIndex,
  type,
}: ICreateEditModalExpression) {
  const lang = useTranslations();
  if (!isMetaBuilderOpen) return null;
  return (
    <Box data-testid="create-edit-meta-expression-dialog">
      <CustomDialogCreateEdit
        key={`${
          isMetaBuilderOpen && modalType === "create"
            ? Date.now()
            : JSON.stringify(selectedMetaExpression)
        }`}
        isCreateOpen={isMetaBuilderOpen && modalType === "create"}
        isEditOpen={isMetaBuilderOpen && modalType === "edit"}
        CreateEditModal={MetaExpressionWizard}
        createModalTitle={
          lang.containers.workflows.subCategories.metaExpressions
            .createEditModal.createTitle
        }
        editModalTitle={
          lang.containers.workflows.subCategories.metaExpressions
            .createEditModal.editTitle
        }
        validate={getErrorMessagesForMetaExpression}
        newElement={{
          title: "",
          target_type: META_EXPRESSION_TARGET_TYPE.VARIABLE,
        }}
        attributes={selectedMetaExpression}
        additionnalProps={
          {
            defaultScope: calledFrom,
            currentActivity,
            currentList,
            currentWorkflow,
            currentTrigger,
            matrixTag,
            calledFrom: matrixTag ? CALLED_FROM.MATRIX_CELL : calledFrom,
            expressionType,
            type,
            bindedInteg: bindedTransfo,
          } as IMetaExpressionWizardAdditionnalProps
        }
        onClose={onClose}
        onCreateElement={handleConfirmCreateMetaExpression}
        onEditElement={handleConfirmEditMetaExpression}
        confirmOnLeaving={true}
        lang={lang}
        closeOnSave
        numberIndex={numberIndex}
      />
    </Box>
  );
}

export default CreateEditModalMetaExpression;
